import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import CheckoutForm, { stripePromise } from "./stripePayment";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "../../../components/GradientButton/GradientButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import AddAddress from "../../../components/ModalComponents/AddAddress/AddAddress";
import { userActions } from "../../../redux/reducers/user";

const PaymentElement = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const { userData } = useSelector((state) => state.user);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [addressModal, setAddressModal] = useState(false);

	useEffect(() => {
		userData?.addresses?.forEach((address) => {
			if (address?.isDefault) setSelectedAddress(address);
		});
	}, [userData]);

	const updateUserAddress = (addresses) => {
		dispatch(userActions.updateAddresses(addresses));
	};

	const options = {
		clientSecret: state?.paymentIntent?.paymentIntent,
	};

	return (
		<div className="flex bg-white justify-between w-full px-[12.25%] py-[240px]">
			<div className="w-1/2 flex gap-4 flex-col ">
				<div className="flex gap-3 flex-wrap">
					{userData?.addresses?.map((address) => (
						<div
							className={`flex cursor-pointer flex-col min-w-[357px] gap-4 p-6 border-[1px] ${
								selectedAddress?._id === address?._id
									? "border-[#E6355C]"
									: "border-[#E4E4E4]"
							} w-fit rounded-lg`}
							onClick={() => setSelectedAddress(address)}
						>
							<div className="flex justify-between">
								<div className="flex flex-col ">
									<h3 className="text-lg text-black font-semibold">
										{address?.fullName}
									</h3>
									<p className="font-normal text-base text-[#858585]">
										{address?.address},<br />
										{address?.city}, {address?.state}
										<br />
										{address?.country}, {address?.zipCode}
									</p>
								</div>
								<input
									type="radio"
									value={address?._id}
									checked={selectedAddress?._id === address?._id}
									name="address-selection"
									className="accent-[#E6355C]"
								/>
							</div>
							<p className="font-semibold text-base text-[#858585]">
								+{address?.mobileNumber}
							</p>
						</div>
					))}
				</div>
				<GradientButton
					title="Add New Address"
					onClick={() => setAddressModal(true)}
				/>
			</div>
			<div className="w-1/2">
				<Elements stripe={stripePromise} options={options}>
					<CheckoutForm
						orderData={state?.orderData}
						selectedAddress={selectedAddress}
						paymentIntent={state?.paymentIntent?.paymentIntent}
					/>
				</Elements>
			</div>
			<CustomModal
				title="Add Your Address"
				caption="Here you can"
				open={addressModal}
				onClose={() => setAddressModal(false)}
			>
				<AddAddress updateAddress={updateUserAddress} />
			</CustomModal>
		</div>
	);
};

export default PaymentElement;

import React, { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import zipcode from "zipcodes";
import GradientButton from "../../GradientButton/GradientButton";
import { addAddress, editAddress } from "../../../api/api_calls/user";
import CustomPhoneInput from "../../CustomPhoneInput/CustomPhoneInput";
import { useSelector } from "react-redux";
import { addressSchema } from "../../../validations/modal";

const AddAddress = ({ data, updateAddress, onClose }) => {
  const { loading } = useSelector((state) => state.loader);

  const [formData, setFormData] = useState({
    fullName: data?.fullName || "",
    mobileNumber: data?.mobileNumber || "",
    address: data?.address || "",
    country: data?.country || "US",
    city: data?.city || "",
    state: data?.state || "",
    zipCode: data?.zipCode || "",
    isDefault: data?.isDefault || false,
  });
  const [formError, setFormError] = useState({});

  const isEdit = Boolean(data);

  const handleFieldChange = (e) => {
    setFormData((curr) => ({ ...curr, [e.target.name]: e.target.value }));
  };

  const handleZipCodeChange = (e) => {
    const info = zipcode.lookup(e.target.value);

    setFormData((curr) => ({
      ...curr,
      zipCode: e.target.value,
      city: info?.city || "",
      state: info?.state || "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const addressData = {
      fullName: formData?.fullName,
      mobileNumber: "+" + formData?.mobileNumber,
      address: formData?.address,
      country: formData?.country,
      city: formData?.city,
      state: formData?.state,
      zipCode: formData?.zipCode,
      isDefault: formData?.isDefault,
      addressId: data?._id,
    };

    const { error } = addressSchema.validate(addressData);

    if (error) {
      if (
        error?.details?.[0]?.context?.key === "city" ||
        error?.details?.[0]?.context?.key === "state"
      ) {
        setFormError({ zipCode: error?.message });
        return;
      }
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    }

    if (isEdit) {
      editAddress(addressData).then((res) => {
        updateAddress(res?.data?.data?.addresses);
        onClose();
      });
      return;
    }

    delete addressData?.addressId;

    addAddress(addressData)
      .then((res) => {
        updateAddress(res?.data?.data?.addresses);
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      className="bg-white max-h-[80vh] overflow-auto py-[24px] px-[16px] flex flex-col gap-[16px]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Full Name</label>
        <CustomInput
          name="fullName"
          placeholder="Enter Your Name"
          value={formData?.fullName}
          onChange={handleFieldChange}
          error={formError?.fullName}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">
          Mobile Number
        </label>
        <CustomPhoneInput
          value={formData?.mobileNumber}
          onChange={(e) =>
            setFormData((curr) => ({ ...curr, mobileNumber: e }))
          }
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Address</label>
        <CustomInput
          name="address"
          placeholder="Enter Address"
          value={formData?.address}
          onChange={handleFieldChange}
          error={formError?.address}
        />
      </div>

      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Zipcode</label>
        <CustomInput
          name="zipCode"
          placeholder="Enter Your Zipcode"
          value={formData?.zipCode}
          onChange={handleZipCodeChange}
          error={formError?.zipCode}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">City</label>
        <CustomInput
          name="city"
          placeholder="City"
          value={formData?.city}
          disabled={true}
          // onChange={handleFieldChange}
          error={formError?.city}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">State</label>
        <CustomInput
          name="state"
          placeholder="State"
          disabled={true}
          value={formData?.state}
          // onChange={handleFieldChange}
          error={formError?.state}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Country</label>
        <CustomInput
          name="country"
          disabled={true}
          // placeholder="Enter State"
          value={formData?.country}
          // onChange={handleFieldChange}
          error={formError?.state}
        />
      </div>
      <div className="flex accent-[#E6355C] gap-2">
        <input
          type="checkbox"
          checked={formData?.isDefault}
          onChange={(e) =>
            setFormData((curr) => ({ ...curr, isDefault: e.target.checked }))
          }
        />
        <p className="font-medium text-black text-base">Make this default</p>
      </div>
      <GradientButton
        loading={loading}
        title={isEdit ? "Save" : "Add"}
        type="submit"
      />
    </form>
  );
};

export default AddAddress;

import React from "react";
import classes from "./Banner.module.css";
import leftBanner from "../../../assets/Banner_image_left.png";
import rightBanner from "../../../assets/Banner_image_right.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const bannerContent = {
	shop: {
		title: "Core Design",
		body: "The star of our CORE collection is Aeterna. Inspired by the Black Goddess, Aeterna represents the first woman to bring light from the Cosmos to Earth in human form.",
	},
	collaborators: {
		title: "Collaborators",
		body: "We are inspired by our community in the Bronx and around the world. Get to know our collaborators, explore their work, and connect with them below.",
	},
	sustainability: {
		title: "Sustainability & Regeneration",
	},
	aboutUs: {
		title: "About Us",
	},
	blogs: {
		title: "Blogs",
	},
};

const Banner = () => {
	const location = useLocation();
	const { resolutionType } = useSelector((state) => state.resolution);

	const content = location.pathname.includes("/shop")
		? "shop"
		: location.pathname.includes("/collaborators")
		? "collaborators"
		: location.pathname.includes("/sustainability")
		? "sustainability"
		: location.pathname.includes("/aboutUs")
		? "aboutUs"
		: location.pathname.includes("/blogs")
		? "blogs"
		: "";

	return content ? (
		<div
			className={`bg-[#E6355C] flex justify-between text-center items-center ${
				resolutionType.type === "mobile"
					? `${classes.mobileBack}`
					: `${classes.back}`
			}`}
		>
			{/* <img src={leftBanner} /> */}
			<div className="w-full flex-col flex justify-center items-center py-14 px-4">
				<h2 className="font-bold text-3xl text-white">
					{bannerContent[content].title}
				</h2>
				<p className="mt-[16px] font-normal text-xl text-white">
					{bannerContent[content]?.body}
				</p>
			</div>
			{/* <img src={rightBanner} /> */}
		</div>
	) : (
		<></>
	);
};

export default Banner;

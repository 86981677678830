import React, { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { searchProducts } from "../../../../api/api_calls/shop";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SearchedProductsSkeleton } from "../../../../skeletons/skeleton";

const SearchDrawer = ({ onClose }) => {
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.loader);
	const { resolutionType } = useSelector((state) => state.resolution);
	const [search, setSearch] = useState("");
	const [searchedProducts, setSearchedProducts] = useState(null);

	const fetchSearchedProducts = () => {
		setSearchedProducts(null);
		searchProducts(search)
			.then((res) => setSearchedProducts(res?.data?.data))
			.catch((error) => setSearchedProducts(null));
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (search) fetchSearchedProducts();
			else setSearchedProducts(null);
		}, [500]);

		return () => clearTimeout(timeoutId);
	}, [search]);
	return (
		<div className="flex flex-col py-8 w-[300px] overflow-hidden">
			<CustomInput
				placeholder="Search Product"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				style={{ margin: "0px 16px", width: "auto" }}
			/>
			<div className="flex flex-col gap-4 mt-8 overflow-auto">
				{typeof searchedProducts === "object" &&
				searchedProducts?.length === 0 ? (
					<h3 className="m-auto">No product found</h3>
				) : loading ? (
					<div className="px-4 py-2">
						<SearchedProductsSkeleton />
					</div>
				) : (
					searchedProducts?.map((product) => (
						<div
							className="flex px-4 py-2 cursor-pointer hover:text-white items-center gap-4 text-[#060709] hover:bg-[#E6355C]"
							onClick={() => {
								navigate("/product-details", {
									state: { productId: product?._id },
								});
								onClose();
							}}
						>
							<img
								src={product?.productImages?.[0]}
								alt="product image"
								className="w-[40px] rounded-lg"
							/>
							<label
								title={product?.productName}
								className="font-semibold cursor-pointer text-lg elipsis max-w-[210px] "
							>
								{product?.productName}
							</label>
						</div>
					))
				)}
			</div>
		</div>
	);
};

export default SearchDrawer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import AddAddress from "../../../../components/ModalComponents/AddAddress/AddAddress";
import ConfirmDeleteModal from "../../../../components/ModalComponents/ConfirmDeleteModal/ConfirmDeleteModal";
import { deleteAddress } from "../../../../api/api_calls/user";
import { userActions } from "../../../../redux/reducers/user";

const Addresses = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  const [addressModal, setAddressModal] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [addressDeleteModal, setAddressDeleteModal] = useState(false);
  const [defaultWarning, setDefaultWarning] = useState(false);

  const handleClose = () => {
    setAddressModal(false);
    setAddressData(null);
  };

  const updateUserAddress = (addresses) => {
    dispatch(userActions.updateAddresses(addresses));
  };

  const handleAddressDeleteClose = () => {
    setAddressDeleteModal(false);
    setAddressData(null);
  };

  const handleAddressDelete = () => {
    deleteAddress({ addressId: addressData?._id })
      .then((res) => updateUserAddress(res?.data?.data?.addresses))
      .catch((err) => console.log(err));
  };

  const handleDeleteButton = (address) => {
    if (address?.isDefault) {
      setDefaultWarning(true);
      return;
    }
    setAddressData(address);
    setAddressDeleteModal(true);
  };

  useEffect(() => {
    if (defaultWarning) setTimeout(() => setDefaultWarning(false), 5000);
  }, [defaultWarning]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex overflow-auto sleek-scrollbar gap-[10px]">
        {userData?.addresses?.map((address) => (
          <div className="flex flex-col min-w-[357px] gap-4 p-6 border-[1px] border-[#E4E4E4] w-fit rounded-lg">
            <div className="flex justify-between">
              <div className="flex flex-col ">
                <h3 className="text-lg text-black font-semibold">
                  {address?.fullName}
                </h3>
                <p className="font-normal text-base text-[#858585]">
                  {address?.address},<br />
                  {address?.city}, {address?.state}
                  <br />
                  {address?.country}, {address?.zipCode}
                </p>
              </div>
              {address?.isDefault && (
                <div className="p-2 rounded-[90px] text-base font-medium text-[#E6355C] bg-[#FFF1F4] h-fit">
                  Default
                </div>
              )}
            </div>
            <p className="font-semibold text-base text-[#858585]">
              +{address?.mobileNumber}
            </p>
            <h3 className="text-lg font-semibold text-[#E6355C]">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setAddressData(address);
                  setAddressModal(true);
                }}
              >
                Edit
              </span>{" "}
              |{" "}
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleDeleteButton(address);
                }}
              >
                Delete
              </span>
            </h3>
          </div>
        ))}
      </div>
      {defaultWarning && (
        <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-2 text-[24px] px-4 rounded-lg w-fit">
          <InfoIcon fontSize="inherit" color="inherit" />
          <h3 className="font-medium text-lg">
            Make another address default before deleting this one!
          </h3>
        </div>
      )}
      <GradientButton
        iconFirst={true}
        title="Add Address"
        icon={
          <div
            className="bg-white flex justify-center items-center rounded-[100%] h-6 w-6 text-[#E93359]"
            // style={{ color: "transparent" }}
          >
            <AddIcon color="inherit" fontSize="small" />
          </div>
        }
        onClick={() => setAddressModal(true)}
      />
      <CustomModal
        title="Add Your Address"
        caption="Here you can"
        open={addressModal}
        onClose={handleClose}
      >
        <AddAddress data={addressData} updateAddress={updateUserAddress} />
      </CustomModal>
      <ConfirmDeleteModal
        open={addressDeleteModal}
        onClose={handleAddressDeleteClose}
        handleYes={handleAddressDelete}
      />
    </div>
  );
};

export default Addresses;

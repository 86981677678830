import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="bg-white px-[12.25%] py-[120px] text-center flex flex-col gap-8">
      <h1 className="font-bold text-[32px]">Shipping Policy</h1>
      <div className="flex flex-col">
        <h2 className="font-semibold text-2xl">SHIPPING POLICY</h2>
        <p className="font-normal text-[18px]">
          We know how important it is for your items to arrive when you expect
          them, so please review the shipping information below to help choose
          the best shipping selection for you. If you have any questions or
          concerns regarding delivery or need help with your shipping, please
          feel free to contact us via phone or email from 8am to 5pm est, Monday
          - Friday.
        </p>
      </div>
      <div className="flex flex-col">
        <h2 className="font-semibold text-2xl">SHIPPING COST</h2>
        <p className="mt-2 font-medium text-xl">Domestic Shipping</p>
        <div className="flex justify-between w-full mt-2 font-normal text-[18px ]">
          <div className="flex flex-col gap-2 text-left ">
            <p>Standard Shipping</p>
            <p>Express Shipping</p>
            <p>Overnight Shipping</p>
          </div>
          <div className="flex flex-col gap-2 text-left ">
            <p>5-7 Business Days For Delivery</p>
            <p>1-3 Business Days For Delivery</p>
            <p>1 Business Day</p>
          </div>
          <div className="flex flex-col gap-2 text-left ">
            <p>$xxx</p>
            <p>$xxx</p>
            <p>$xxx</p>
          </div>
        </div>
        <p className="mt-8 font-medium text-[18px]">
          Most orders will ship out within a day of ordering (we do not ship on
          Saturdays, Sundays, or holidays). However, some orders take 24-48
          hours to process.
        </p>
        <p className="mt-8 font-medium [18px]">
          Please note: Express and Overnight shipping still need 24-48 hours to
          process.
        </p>
        <p className="mt-2 font-medium [18px]">
          <span className="font-semibold">
            Aeternal Lovers is not responsible for any lost or stolen packages.
            <br />
            <br />
            Please note that orders that are returned back to sender due to
            customer or address error will be refunded once received back.
          </span>
        </p>
      </div>
      <div className="flex flex-col">
        <h2 className="font-semibold text-2xl">SHIPPING STATUS</h2>
        <p className="font-normal text-[18px]">
          Please login to your account to check the status of your shipment.
        </p>
      </div>
    </div>
  );
};

export default ShippingPolicy;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: JSON.parse(localStorage.getItem("AL_USER")) || {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeUser(state, action) {
      state.userData = action.payload;

      return state;
    },
    removeUser(state) {
      state.userData = {};

      return state;
    },
    updateAddresses(state, action) {
      state.userData.addresses = action.payload;

      return state;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;

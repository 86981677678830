import Joi from "joi";

export const loginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .messages({
      "string.empty":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    })
    .label("Password"),
});

export const registerSchema = Joi.object({
  firstName: Joi.string().required().max(20).label("First Name"),
  lastName: Joi.string().required().max(20).label("Last Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
  confirmPassword: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Confirm Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
});

export const forgotPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
});

export const resetPasswordSchema = Joi.object({
  newPassword: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
  otp: Joi.string().required(),
  id: Joi.string().required(),
});

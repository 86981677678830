import React from "react";
import PhoneInput from "react-phone-input-2";
// import "./CustomPhoneInput.css";

const CustomPhoneInput = ({ value, onChange, disabled, error }) => {
	return (
		<>
			<PhoneInput
				containerClass="w-full"
				country="us"
				name="phone"
				onlyCountries={["us"]}
				countryCodeEditable={false}
				value={JSON.stringify(value)}
				onChange={onChange}
				disabled={disabled}
				inputClass={`!w-[100%] !h-[58px] ${
					error ? "!border-red-400" : "!border-[#E4E4E4]"
				}`}
			/>
			{error && (
				<p className="text-red-400 mt-2 font-normal text-sm">{error}</p>
			)}
		</>
	);
};

export default CustomPhoneInput;

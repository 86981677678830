import { React, useState } from "react";
import GradientButton from "../components/GradientButton/GradientButton";
import Radio from "../assets/344819669fa95f5ac1965267ceeea45d.png";
import { register } from "../api/api_calls/auth";
import { registerSchema } from "../validations/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput/CustomInput";
import { handleApiError } from "../utils/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../redux/reducers/cart";
import { userActions } from "../redux/reducers/user";

const Register = () => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.loader);
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formError, setFormError] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { firstName, lastName, email, password, confirmPassword };

    const { error } = registerSchema.validate(formData);

    if (error) {
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    }

    if (password !== confirmPassword) {
      setFormError({
        password: "Passwords do not match!",
        confirmPassword: "Passwords do not match!",
      });
      return;
    }

    register(formData)
      .then((res) => {
        toast.success(res?.data?.message, { theme: "dark" });
        localStorage.setItem(
					"AL_USER",
					JSON.stringify({ accessToken: res?.data?.data?.accessToken })
				);
				dispatch(userActions.storeUser(res?.data?.data));
				dispatch(fetchCart());
        navigate("/");
      })
      .catch((error) => {
        handleApiError(error, setFormError);
      });
  };

  return (
    <div className="w-full h-full bg-[#FFF1F4] flex items-center justify-center">
      <div className="flex flex-col md:flex-row md:px-[15%] px-[5%] py-[5%] w-full">
        <img className="md:w-1/2 w-full object-cover" src={Radio} />
        <form
          className="md:w-1/2 w-full px-8 md:py-16 py-8 flex-col bg-[#FFFFFF]"
          onSubmit={handleSubmit}
        >
          <div className="font-bold md:text-3xl text-lg mb-1">
            Welcome to Aeternal Lovers
          </div>
          <div className="font-normal md:text-base text-sm mb-5">
            To register a new account please enter your credentials below
          </div>
          <div className="font-medium text-lg mb-2">First Name *</div>
          {/* <input
            className="w-full md:text-base text-xs font-normal rounded-lg md:py-4 md:px-2 md:mb-5 py-2 px-1 mb-3 border border-zinc-200"
            type="text"
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          /> */}
          <CustomInput
            type="text"
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={formError?.firstName}
          />
          <div className="font-medium mt-3 md:mt-5 text-lg mb-2">
            Last Name *
          </div>
          {/* <input
            className="w-full md:text-base text-xs font-normal rounded-lg md:py-4 md:px-2 md:mb-5 py-2 px-1 mb-3 border border-zinc-200"
            type="text"
            placeholder="Enter Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          /> */}
          <CustomInput
            type="text"
            placeholder="Enter Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={formError?.lastName}
          />
          <div className="font-medium text-lg mt-3 md:mt-5 mb-2">
            Email Address *
          </div>
          {/* <input
            className="w-full md:text-base text-xs font-normal rounded-lg md:py-4 md:px-2 md:mb-5 py-2 px-1 mb-3 border border-zinc-200"
            type="text"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
          <CustomInput
            type="text"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={formError?.email}
          />
          <div className="font-medium text-lg mt-3 md:mt-5 mb-2">
            Password *
          </div>
          {/* <input
            className="w-full md:text-base text-xs font-normal rounded-lg md:py-4 md:px-2 md:mb-5 py-2 px-1 mb-3 border border-zinc-200"
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <CustomInput
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={formError?.password}
          />
          <div className="font-medium text-lg mt-3 md:mt-5 mb-2">
            Confirm Password *
          </div>
          {/* <input
            className="w-full md:text-base text-xs font-normal rounded-lg md:py-4 md:px-2 md:mb-5 py-2 px-1 mb-3 border border-zinc-200"
            type="password"
            placeholder="Enter Password Again"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          /> */}
          <CustomInput
            type="password"
            placeholder="Enter Password Again"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={formError?.confirmPassword}
          />
          <GradientButton
            type="submit"
            className="w-full mt-3 md:mt-5"
            title={"Register"}
            loading={loading}
            style={{
              width: "100%",
              padding: "0.65rem 0rem",
              marginBottom: "2rem",
            }}
            // style={{ fontSize: "0.75rem", padding: "0.1rem 1rem" }}
          />
          <div className="w-full items-center text-center">
            Already have an account?{" "}
            <a
              style={{ cursor: "pointer", color: "#6964B4" }}
              onClick={() => navigate("/login")}
            >
              Login
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createOrder } from "../../../api/api_calls/shop";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchCart } from "../../../redux/reducers/cart";

const PaymentSuccess = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const data = JSON.parse(params.get("orderDetails"));
  const paymentId = params.get("paymentId");

  useEffect(() => {
    if (data) {
      const formData = {
        orderItems: data?.orderItems,
        orderDeliveryAddress: data?.orderDeliveryAddress,
        tax: data?.orderAmountTax,
        shippingCharges: data?.shippingCharges,
        promoCode: data?.promoCode,
        orderStatus: 1,
        paymentId,
        paymentAmount: data?.orderAmount,
        discount: data?.discount
        
      };

      createOrder(formData)
        .then((res) => {
          params.delete("paymentId");
          params.delete("orderDetails");
          dispatch(fetchCart());
        })
        .catch((err) => {
          toast.info(err?.message, { toastId: "create-order-api" });
        });
    }
  }, []);

  return (
    <div class="flex bg-white h-[60vh] justify-center">
      <div class="flex flex-col m-auto">
        <div class="message-box _success">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <h2> Your payment was successful </h2>
          <p>
            {" "}
            Thank you for your payment. we will <br />
            be in contact with more details shortly{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

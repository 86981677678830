import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";
import { API } from "../api_setting/apiConstants";

export const getCartItems = () => {
  return wrappedGet(API.GET_CART_ITEMS, "GET");
};

export const get_all_arts_type = () => {
  return wrappedGet(API.GET_ALL_ARTS_TYPE, "GET");
};

export const getAllProducts = (artType, sorting) => {
  return wrappedGet(
    `${API.GET_ALL_PRODUCTS}?artType=${artType}&sort=${sorting}`,
    "GET"
  );
};

export const getProductDetails = (productId) => {
  return wrappedGet(`${API.GET_PRODUCT_DETAILS}?productId=${productId}`, "GET");
};

export const writeReview = (formData) => {
  return wrappedFetch(API.WRITE_REVIEW, "POST", formData);
};

export const addToCart = (formData) => {
  return wrappedFetch(API.ADD_TO_CART, "POST", formData);
};

export const removeFromCart = (formData) => {
  return wrappedFetch(API.REMOVE_FROM_CART, "POST", formData);
};

export const updateCartItemQuantity = (formData) => {
  return wrappedFetch(API.UPDATE_CART_ITEM_QUANTITY, "POST", formData);
};

export const createPaymentIntent = (formData) => {
  return wrappedFetch(API.GET_PAYMENT_INTENT, "POST", formData);
};

export const createOrder = (formData) => {
  return wrappedFetch(API.CREATE_ORDER, "POST", formData);
};


export const searchProducts = (productName) => {
	return wrappedGet(`${API.SEARCH_PRODUCTS}?productName=${productName}`, "GET")
}

export const getCustomProducts = () => {
  return wrappedGet(API.GET_ALL_CUSTOM_PRODUCTS, "GET")
}

export const checkPromoCode = (code) => {
  return wrappedGet(`${API.CHECK_PROMO_CODE}?code=${code}`, "GET")
}
import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./reducers/cart";
import userReducer from "./reducers/user";
import loaderReducer from "./reducers/loader";
import musicUploadReducer from "./reducers/musicUpload";
import resolutionReducer from "./reducers/resolution";
import { apiSlice } from "./apiSlice";

const store = configureStore({
	reducer: {
		cart: cartReducer,
		user: userReducer,
		loader: loaderReducer,
		musicUpload: musicUploadReducer,
		resolution: resolutionReducer,
	},
});

export default store;

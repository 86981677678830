import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	resolutionType: { type: "laptop" },
};

const resolutionSlice = createSlice({
	name: "resolution",
	initialState,
	reducers: {
		updateResolution(state, action) {
			// console.log("ACTION PAYLOAD: ", action.payload);
			state.resolutionType = action.payload;
			return state;
		},
	},
});

export const resolutionActions = resolutionSlice.actions;

export default resolutionSlice.reducer;

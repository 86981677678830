import { useState } from "react";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import WebAppSidebar from "../../WebAppSidebar/WebAppSidebar";

export const Burger = (props) => {
	const navigate = useNavigate();
	const [shop, setShop] = useState(false);

	return (
		<div className="flex flex-col gap-y-4 p-6 w-screen">
			<WebAppSidebar />
			<button
				className="py-[8px] border-solid border-[1px] border-[#E6355C] px-[24px] rounded-[160px] text-[#E6355C] text-base font-semibold self-center"
				onClick={() => navigate("/")}
			>
				Back to Website
			</button>
		</div>
	);
};

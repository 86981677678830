import { API } from "../api_setting/apiConstants";
import { wrappedFetch } from "../api_setting/urlFuncs";

export const login = (formData) => {
  return wrappedFetch(API.LOGIN, "POST", formData);
};

export const register = (formData) => {
  return wrappedFetch(API.REGISTER, "POST", formData);
};

export const forgotPassword = (formData) => {
  return wrappedFetch(API.FORGOT_PASSWORD, "POST", formData);
};

export const resetPassword = (formData) => {
  return wrappedFetch(API.RESET_PASSWORD, "POST", formData);
};

export const setPassword = (formData) => {
  return wrappedFetch(API.SET_PASSWORD, "POST", formData);
};

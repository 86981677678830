import {
	React,
	useContext,
	useState,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { PlayerContext } from "../Sound";
import { musicByAll, searchMusic } from "../../../../api/api_calls/music";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const MusicAll = () => {
	const { resolutionType } = useSelector((state) => state.resolution);
	const { open, musicEngine } = useContext(PlayerContext);
	const navigate = useNavigate();

	const [list, setList] = useState(null);
	const [listLoad, setListLoad] = useState(false);
	const [moreLoad, setMoreLoad] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [search, setSearch] = useState("");
	const [debounce, setDebounce] = useState(false);
	const [flag, setFlag] = useState(false);

	const fetchMore = () => {
		if (currPage + 1 > totalPages || debounce) {
			return;
		}

		setDebounce(true);

		const newPage = currPage + 1;

		musicByAll({ page: newPage, limit: 18 })
			.then((res) => {
				const tempArr = res?.data?.data?.map((item, index) => ({
					id: item?._id,
					thumbnail: item?.isYoutube
						? item?.thumbnailImages["youtubeThumbnail"]
						: item?.thumbnailImages["musicThumbnail"],
					title: item?.title,
					artist: item?.artist?.firstName + " " + item?.artist?.lastName,
				}));
				setList([...list, ...tempArr]);
				setCurrPage(res?.data?.currentPage);

				if (res?.data?.currentPage === res?.data?.totalPages)
					setMoreLoad(false);
			})
			.finally(() => setDebounce(false));

		// console.log("REACHED LAST ITEM");
	};

	const observer = useRef();

	const lastElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					fetchMore();
				}
			});
			if (node) observer.current.observe(node);
		},
		[fetchMore]
	);

	useEffect(() => {
		setListLoad(true);
		musicByAll({ page: currPage, limit: 18 })
			.then((res) => {
				const tempArr = res?.data?.data?.map((item, index) => ({
					id: item?._id,
					thumbnail: item?.isYoutube
						? item?.thumbnailImages["youtubeThumbnail"]
						: item?.thumbnailImages["musicThumbnail"],
					title: item?.title,
					artist: item?.artist?.firstName + " " + item?.artist?.lastName,
				}));
				setList(tempArr);
				setTotalCount(res?.data?.totalCount);
				setTotalPages(res?.data?.totalPages);
				if (res?.data?.totalPages > 1) setMoreLoad(true);
			})
			.finally(() => {
				setListLoad(false);
			});
	}, []);

	return (
		<div
			className="grow flex flex-col px-8 py-4 gap-y-2"
			style={{
				height: [
					open
						? resolutionType.type === "mobile"
							? "calc(100% - 10.75rem)"
							: "calc(100% - 4.75rem)"
						: "100%",
				],
			}}
		>
			<div className="flex flex-col gap-y-4">
				<button
					className="w-[6rem] h-[2.5rem] flex justify-center items-center gap-x-2 bg-[#FFF1F4] text-[#E6355C] rounded-lg"
					onClick={() => navigate("/app/sound")}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
							stroke="#E6355C"
							stroke-width="1.5"
							stroke-miterlimit="10"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M20.4999 12H3.66992"
							stroke="#E6355C"
							stroke-width="1.5"
							stroke-miterlimit="10"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<span className="text-xl font-bold">Back</span>
				</button>
				<span className="block text-xl font-bold ">All Music</span>
				<div className="h-[3.5rem] w-full bg-[#F1F1F1] rounded-[6.25rem] flex gap-x-2 items-center px-4">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.52 11.01C19.52 15.7099 15.7099 19.52 11.01 19.52C6.31006 19.52 2.5 15.7099 2.5 11.01C2.5 6.31006 6.31006 2.5 11.01 2.5C15.7099 2.5 19.52 6.31006 19.52 11.01Z"
							stroke="#858585"
						/>
						<path
							d="M21.9901 18.95C21.6601 18.34 20.9601 18 20.0201 18C19.3101 18 18.7001 18.29 18.3401 18.79C17.9801 19.29 17.9001 19.96 18.1201 20.63C18.5501 21.93 19.3001 22.22 19.7101 22.27C19.7701 22.28 19.8301 22.28 19.9001 22.28C20.3401 22.28 21.0201 22.09 21.6801 21.1C22.2101 20.33 22.3101 19.56 21.9901 18.95Z"
							fill="#858585"
						/>
					</svg>
					<input
						className="w-full grow-1 bg-transparent outline-none border-none focus:border-b-2 focus:border-solid"
						placeholder="Search Music"
						onChange={(e) => {
							setSearch(e.target.value);
							// if (e.target.value === "") {
							// 	setListLoad(true);
							// 	musicByAll({ page: currPage, limit: 100 })
							// 		.then((res) => {
							// 			const tempArr = res?.data?.data?.map((item, index) => ({
							// 				id: item?._id,
							// 				thumbnail: item?.isYoutube
							// 					? item?.thumbnailImages["youtubeThumbnail"]
							// 					: item?.thumbnailImages["musicThumbnail"],
							// 				title: item?.title,
							// 				artist:
							// 					item?.artist?.firstName + " " + item?.artist?.lastName,
							// 			}));
							// 			setList(tempArr);
							// 		})
							// 		.finally(() => {
							// 			setListLoad(false);
							// 		});
							// }
						}}
						onKeyUp={(e) => {
							if (e.key === "Enter") {
								setListLoad(true);
								if (search === "") {
									setFlag(false); // now music engine will be triggered based on all music
									musicByAll({ page: currPage, limit: 18 })
										.then((res) => {
											const tempArr = res?.data?.data?.map((item, index) => ({
												id: item?._id,
												thumbnail: item?.isYoutube
													? item?.thumbnailImages["youtubeThumbnail"]
													: item?.thumbnailImages["musicThumbnail"],
												title: item?.title,
												artist:
													item?.artist?.firstName +
													" " +
													item?.artist?.lastName,
											}));
											setList(tempArr);
											setTotalCount(res?.data?.totalCount);
											setTotalPages(res?.data?.totalPages);
											if (res?.data?.totalPages > 1) setMoreLoad(true);
										})
										.finally(() => {
											setListLoad(false);
										});
								} else {
									setMoreLoad(false);
									setFlag(true); // now music engine will be triggered based on search result
									searchMusic({ musicName: search })
										.then((res) => {
											// console.log({ res });
											const tempArr = res?.data?.data?.map((item, index) => ({
												id: item?._id,
												thumbnail: item?.isYoutube
													? item?.thumbnailImages["youtubeThumbnail"]
													: item?.thumbnailImages["musicThumbnail"],
												title: item?.title,
												artist:
													item?.artist?.firstName +
													" " +
													item?.artist?.lastName,
											}));
											setList(tempArr);
										})
										.finally(() => {
											setListLoad(false);
										});
								}
							}
						}}
					/>
				</div>
			</div>
			{listLoad ? (
				<div className="w-full grow flex justify-center items-center">
					<CircularProgress width={50} height={50} />
				</div>
			) : (
				<>
					{resolutionType.type === "mobile" ? (
						<>
							{list?.length === 0 ? (
								<div className="grow w-full flex justify-center items-center">
									{flag
										? "No Songs Found"
										: "No Music... Please upload to start enjoying Aeternal Lovers!"}
								</div>
							) : (
								<div className="w-full overflow-y-auto">
									<div className="w-full flex flex-col justify-center gap-y-4">
										{list?.map((item, index) => {
											// const isLastElement = index === list.length - 1;
											return (
												<div
													className="w-full h-[45vh] p-8 flex flex-col justify-between"
													key={index}
													onClick={() => musicEngine("all", index)}
													// ref={isLastElement ? lastElementRef : null}
												>
													<img
														src={item.thumbnail}
														alt={item.title}
														style={{
															flexShrink: "0",
															width: "100%",
															height: "80%",
															borderRadius: "8px",
															objectFit: "cover",
														}}
													/>
													<span className="block text-lg font-medium text-[#E6355C]">
														{item.title}
													</span>
													by {item.artist}
												</div>
											);
										})}
									</div>
								</div>
							)}
						</>
					) : (
						<>
							{list?.length === 0 ? (
								<div className="grow flex justify-center items-center">
									{flag
										? "No songs found"
										: "No Music... Please upload to start enjoying Aeternal Lovers!"}
								</div>
							) : (
								<div className="w-full flex flex-col grow overflow-y-auto">
									<div className="w-full grid grid-cols-6 gap-8">
										{list?.map((item, index) => {
											// const isLastElement = index === list.length - 1;
											return (
												<div
													className="h-[323px] p-4 rounded-lg hover:bg-[#FFF1F4] flex flex-col gap-y-2 justify-center"
													key={index}
													onClick={() =>
														musicEngine(
															flag ? "search" : "all",
															flag ? search : null,
															index
														)
													}
													// ref={isLastElement ? lastElementRef : null}
												>
													<img
														src={item.thumbnail}
														alt={item.title}
														style={{
															flexShrink: "0",
															width: "100%",
															height: "224px",
															borderRadius: "8px",
															objectFit: "cover",
														}}
													/>
													<div className="flex flex-col">
														<span className="block text-lg font-medium text-[#E6355C]">
															{item.title}
														</span>
														by {item.artist}
													</div>
												</div>
											);
										})}
									</div>
									{moreLoad && (
										<div
											ref={lastElementRef}
											className="w-full flex justify-center items-center h-[100px]"
										>
											<CircularProgress />
										</div>
									)}
								</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default MusicAll;

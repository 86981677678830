import React, { useEffect, useState } from "react";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import { addToCart } from "../../../../api/api_calls/shop";
import { useDispatch, useSelector } from "react-redux";
import { cartActions, fetchCart } from "../../../../redux/reducers/cart";
import InfoIcon from "@mui/icons-material/Info";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import SizeChart from "../../../../components/ModalComponents/SizeChart/SizeChart";
import { useNavigate } from "react-router-dom";

const AddToCartDrawer = ({ product, close }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.user);

  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState("");
  const [sizeModal, setSizeModal] = useState(false);
  const [sizeError, setSizeError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!userData?._id) {
      navigate("/login");
    }

    const formData = new FormData();

    formData.append("productId", product?._id);
    formData.append("productName", product?.productName);
    formData.append("price", product?.basePrice);
    formData.append("qty", quantity);
    formData.append("size", size);

    if (!size) {
      setSizeError(true);
      return;
    }

    addToCart(formData)
      .then((res) => {
        dispatch(fetchCart());
        navigate("/cart");
        close();
      })
      .catch((err) => console.log(err));
  };

  const handleAddToCart = () => {

    if (!userData?._id) {
      navigate("/login");
    }

    const formData = new FormData();

    formData.append("productId", product?._id);
    formData.append("productName", product?.productName);
    formData.append("price", product?.basePrice);
    formData.append("qty", quantity);
    formData.append("size", size);

    if (!size) {
      setSizeError(true);
      return;
    }

    addToCart(formData)
      .then((res) => {
        dispatch(fetchCart());
        close();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (sizeError) setTimeout(() => setSizeError(false), 5000);
  }, [sizeError]);

  return (
    <form className="flex flex-col p-[20px]" onSubmit={handleSubmit}>
      <div className="flex gap-[10px] border-b-[1px] border-[#E4E4E4]">
        <img
          src={product?.productImages[0]}
          className="h-[109px] w-[109px] object-cover rounded-[8px]"
        />
        <div className="flex flex-col gap-[16px]  pb-[20px]">
          <h2 className="text-[#060709] font-semibold text-[30px]">
            {product.productName}
          </h2>
          <h2 className="text-[#E6355C] font-bold text-[30px]">
            ${product.basePrice}
          </h2>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
        <div className="flex items-center gap-4">
          <h2 className="text-[#060709] font-medium text-[22px]">Size</h2>
          {sizeError && (
            <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
              <InfoIcon fontSize="inherit" color="inherit" />
              <h3 className="font-medium text-sm">
                Please select any of the avaliable sizes first!
              </h3>
            </div>
          )}
        </div>
        <div className="flex gap-[24px] items-end">
          {["S", "M", "L", "XL"].map((item) => (
            <div
              className={`${
                size === item ? "bg-[#060709] text-white" : ""
              } h-[50px] w-[50px] cursor-pointer text-[#858585] hover:bg-[#060709] hover:text-white rounded-[8px] border-[1px] border-[#858585] flex justify-center items-center`}
              onClick={() => setSize(item)}
            >
              {item}
            </div>
          ))}
          <p
            className="flex items-center font-medium text-lg text-[#6964B4] gap-[8px] cursor-pointer"
            onClick={() => setSizeModal(true)}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.4702 19.5V5.5C21.4702 3.5 20.4702 2.5 18.4702 2.5H14.4702C12.4702 2.5 11.4702 3.5 11.4702 5.5V19.5C11.4702 21.5 12.4702 22.5 14.4702 22.5H18.4702C20.4702 22.5 21.4702 21.5 21.4702 19.5Z"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.4702 6.5H16.4702"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.4702 18.5H15.4702"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.4702 14.45L16.4702 14.5"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.4702 10.5H14.4702"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M5.48978 2.5C3.85978 2.5 2.52979 3.83 2.52979 5.45V18.41C2.52979 18.86 2.71979 19.54 2.94979 19.93L3.76979 21.29C4.70979 22.86 6.25979 22.86 7.19979 21.29L8.01979 19.93C8.24979 19.54 8.43979 18.86 8.43979 18.41V5.45C8.43979 3.83 7.10978 2.5 5.48978 2.5Z"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M8.43979 7.5H2.52979"
                stroke="#6964B4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            Size Guide
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
        <h2 className="text-[#060709] font-medium text-[22px]">Quantity</h2>
        <div className="flex items-center py-[4px] border-[1px] w-fit rounded-[8px] border-[#060709] px-[16px] gap-[24px]">
          <button
            type="button"
            className={`${
              quantity === 1 ? "text-[#E4E4E4]" : "text-[#060709]"
            } cursor-pointer text-lg font-medium`}
            onClick={() => {
              if (quantity > 1) setQuantity(quantity - 1);
            }}
          >
            -
          </button>
          <h3 className="text-[#060709] text-lg font-medium">{quantity}</h3>
          <button
            type="button"
            className="text-[#060709] cursor-pointer text-lg font-medium"
            onClick={() => setQuantity(quantity + 1)}
          >
            +
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
        <GradientButton
          title="Add to Cart"
          type="button"
          onClick={handleAddToCart}
          style={{ width: "100%" }}
        />
        <GradientButton
          title="Proceed to Checkout"
          type="submit"
          style={{ width: "100%" }}
        />
      </div>
      <CustomModal
        title="Size Chart"
        caption=""
        open={sizeModal}
        onClose={() => setSizeModal(false)}
      >
        <SizeChart image={product?.sizeChart} />
      </CustomModal>
    </form>
  );
};

export default AddToCartDrawer;

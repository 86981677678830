import React from "react";
import { Outlet } from "react-router-dom";
import WebAppNavbar from "../WebAppNavbar/WebAppNavbar";
import WebAppSidebar from "../WebAppSidebar/WebAppSidebar";
import { useSelector } from "react-redux";

const WebAppHome = () => {
	const { resolutionType } = useSelector((state) => state.resolution);
	return (
		<div className="flex flex-col">
			<WebAppNavbar />
			<div className="flex" style={{ height: "calc(100vh - 99px)" }}>
				{resolutionType.type !== "mobile" && <WebAppSidebar />}
				<div
					className="h-full"
					style={{
						...(resolutionType.type === "mobile"
							? { width: "100%" }
							: {
									width: "calc(100% - 6.375rem)",
							  }),
					}}
				>
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default WebAppHome;

import React from "react";
import GradientButton from "../../../components/GradientButton/GradientButton";

import aboutus from "../../../assets/aboutus.png";
import aboutus2 from "../../../assets/aboutus2.png";
import aboutus3 from "../../../assets/aboutus3.png";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AboutUs = () => {
	const navigate = useNavigate();
	const { resolutionType } = useSelector((state) => state.resolution);
	return (
		<div className="w-full flex flex-col">
			<div
				className={`w-full flex flex-col justify-between items-center bg-white ${
					resolutionType.type === "mobile" ? "p-8" : "py-16 px-48"
				}`}
			>
				<img
					src={aboutus}
					className={`w-full object-cover mb-8 ${
						resolutionType.type === "mobile"
							? "h-[250px] object-left-top"
							: "h-[500px] object-top"
					}`}
				/>
				<div
					className={`w-full grow flex flex-col text-center ${
						resolutionType.type === "mobile" ? "" : "px-[15%]"
					}`}
				>
					<p className="font-bold text-3xl/[38px] mb-6">Who we are</p>
					<span
						className={`font-normal text-center text-[#858585] mb-6 ${
							resolutionType.type === "mobile" ? "text-sm" : "text-lg/[27px]"
						}`}
					>
						We are a sustainable living brand: A virtual community of music
						makers, designers, models, spoken word artists, entrepreneurs, and
						community leaders based here in the Bronx.
					</span>
					<span
						className={`font-normal text-center text-[#858585] mb-6 ${
							resolutionType.type === "mobile" ? "text-sm" : "text-lg/[27px]"
						}`}
					>
						Our inspiration includes the Cosmos, Mother Earth, the Black
						Goddess, and Black culture in the Americas. We lead with love: Love
						is the creative force that makes consciousness possible.
					</span>
					<span
						className={`font-normal text-center text-[#858585] mb-6 ${
							resolutionType.type === "mobile" ? "text-sm" : "text-lg/[27px]"
						}`}
					>
						When we tap into the energy of love, we know abundance, peace, and
						joy.
					</span>
					<p className="font-bold text-lg/[27px] text-center text-[#E6355C]">
						LOVE POWER IS REAL.
					</p>
				</div>
			</div>
			<div
				className={`w-full flex flex-col items-center bg-black  text-white ${
					resolutionType.type === "mobile" ? "p-8" : "py-16 px-64"
				}`}
			>
				<span className="font-bold text-5xl/[60px] mb-6">What we do</span>
				<span className="text-center font-normal text-xl/[30px] mb-8 px-[8.5rem]">
					We make and share original beats and dubs built on live and digital
					music. Influenced by our travels near and far, we layer our beats with
					the vocals of established and up-and-coming local musicians, from
					poets and spoken word artists to rappers and regular folks. As a
					collective, we comprise the Aeternal Lovers Sound System.
				</span>
				<div className="w-full px-48 flex gap-x-4 mt-8">
					<GradientButton
						onClick={() => navigate("/app/sound")}
						className="!w-1/2 !rounded-[12px]"
						iconFirst={true}
						title={
							<div className="flex justify-between gap-x-4 items-center">
								<div className="flex flex-col">
									<span className="font-bold text-[22px]/[32px]">
										Feel the Vibration
									</span>
									<span className="font-normal text-lg/[27px]">
										Check out a sample of our video and Music content.
									</span>
								</div>
								<svg
									width="34"
									height="34"
									viewBox="0 0 34 34"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20.4434 8.40076L29.0425 16.9999L20.4434 25.5991"
										stroke="white"
										stroke-width="2.125"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M4.95898 17H28.8015"
										stroke="white"
										stroke-width="2.125"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						}
						icon={
							<svg
								width="68"
								height="68"
								viewBox="0 0 68 68"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.30928 26.69C7.08261 26.69 6.82761 26.6616 6.60094 26.5766C5.49594 26.18 4.92928 24.9616 5.29761 23.8566C7.19594 18.53 10.4826 13.855 14.8743 10.3133C15.7809 9.57662 17.1126 9.71829 17.8493 10.625C18.5859 11.5316 18.4443 12.8633 17.5376 13.6283C13.7976 16.66 10.9359 20.6833 9.32094 25.2733C9.00928 26.1516 8.18761 26.69 7.30928 26.69Z"
									fill="white"
								/>
								<path
									d="M7.30935 45.56C6.43102 45.56 5.60935 45.0217 5.29768 44.1433C4.13602 40.8567 3.54102 37.4283 3.54102 34C3.54102 32.8383 4.50435 31.875 5.66602 31.875C6.82768 31.875 7.79102 32.8383 7.79102 34C7.79102 36.9467 8.30102 39.8933 9.32102 42.7267C9.71768 43.8317 9.12268 45.05 8.01768 45.4467C7.79102 45.5317 7.53602 45.56 7.30935 45.56Z"
									fill="white"
								/>
								<path
									d="M34.0007 64.4587C30.9974 64.4587 28.0224 64.0054 25.1324 63.127C23.999 62.787 23.3757 61.597 23.7157 60.4637C24.0557 59.3304 25.2457 58.707 26.379 59.047C28.844 59.812 31.4224 60.1804 34.0007 60.1804C48.4507 60.1804 60.209 48.422 60.209 33.972C60.209 32.4987 60.0674 30.9687 59.784 29.3537C59.5857 28.192 60.3507 27.087 61.5124 26.8887C62.6457 26.6904 63.779 27.4554 63.9774 28.617C64.3174 30.487 64.4874 32.2437 64.4874 34.0004C64.459 50.802 50.8024 64.4587 34.0007 64.4587Z"
									fill="white"
								/>
								<path
									d="M16.2063 58.0835C15.7246 58.0835 15.2713 57.9419 14.8746 57.6019C13.2596 56.2985 11.9563 55.0519 10.8513 53.7769C10.0863 52.8985 10.1996 51.5385 11.078 50.7735C11.9846 50.0085 13.3163 50.1219 14.0813 51.0002C15.0163 52.0769 16.1496 53.1535 17.538 54.2869C18.4446 55.0235 18.5863 56.3552 17.8496 57.2619C17.453 57.8002 16.8296 58.0835 16.2063 58.0835Z"
									fill="white"
								/>
								<path
									d="M57.3462 20.0883C56.6662 20.0883 56.0145 19.7766 55.5895 19.1533C50.7162 12.0416 42.6128 7.79163 33.9995 7.79163C31.4212 7.79163 28.8428 8.15996 26.3778 8.92496C25.2728 9.26496 24.0828 8.64163 23.7145 7.50829C23.3462 6.37496 23.9978 5.18496 25.1312 4.84496C28.0212 3.99496 30.9962 3.54163 33.9995 3.54163C44.0295 3.54163 53.4078 8.49996 59.1028 16.7733C59.7545 17.7366 59.5278 19.0683 58.5645 19.72C58.1962 19.975 57.7712 20.0883 57.3462 20.0883Z"
									fill="white"
								/>
								<path
									d="M45.419 18.4164C44.484 17.7081 42.784 16.9998 40.064 17.7364L31.0257 20.1731C28.419 20.9098 26.719 23.1198 26.719 25.8398V30.4864V37.7964C25.9823 37.5131 25.189 37.3431 24.339 37.3431C20.684 37.3431 17.709 40.3181 17.709 43.9731C17.709 47.6281 20.684 50.6031 24.339 50.6031C27.9657 50.6031 30.884 47.6848 30.9407 44.0864C30.9407 44.0581 30.969 44.0298 30.969 43.9731V32.1014L43.209 28.7581V34.7931C42.4723 34.5098 41.679 34.3398 40.829 34.3398C37.174 34.3398 34.199 37.3148 34.199 40.9698C34.199 44.6248 37.174 47.5998 40.829 47.5998C44.484 47.5998 47.459 44.6248 47.459 40.9698V25.9814V23.3748C47.459 21.1081 46.779 19.4364 45.419 18.4164ZM24.339 46.3531C23.0357 46.3531 21.959 45.2764 21.959 43.9731C21.959 42.6698 23.0357 41.5931 24.339 41.5931C25.6423 41.5931 26.719 42.6698 26.719 43.9731C26.719 45.2764 25.6423 46.3531 24.339 46.3531ZM40.829 43.3498C39.5257 43.3498 38.449 42.2731 38.449 40.9698C38.449 39.6664 39.5257 38.5898 40.829 38.5898C42.1323 38.5898 43.209 39.6664 43.209 40.9698C43.209 42.2731 42.1323 43.3498 40.829 43.3498Z"
									fill="white"
								/>
							</svg>
						}
					/>
					<GradientButton
						onClick={() => navigate("/app/community")}
						className="!w-1/2 !rounded-[12px]"
						iconFirst={true}
						title={
							<div className="flex justify-between gap-x-4 items-center">
								<div className="flex flex-col">
									<span className="font-bold text-[22px]/[32px]">
										Join Our Community
									</span>
									<span className="font-normal text-lg/[27px]">
										Enlist today, where individuals with similar interests
										unite.
									</span>
								</div>
								<svg
									width="34"
									height="34"
									viewBox="0 0 34 34"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20.4434 8.40076L29.0425 16.9999L20.4434 25.5991"
										stroke="white"
										stroke-width="2.125"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M4.95898 17H28.8015"
										stroke="white"
										stroke-width="2.125"
										stroke-miterlimit="10"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						}
						icon={
							<svg
								width="69"
								height="68"
								viewBox="0 0 69 68"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M52.8321 47.685L53.9371 56.6383C54.2204 58.99 51.6988 60.6333 49.6871 59.415L39.8838 53.5783C39.2038 53.1816 39.0338 52.3316 39.4021 51.6516C40.8188 49.045 41.5838 46.0983 41.5838 43.1516C41.5838 32.7816 32.6871 24.3383 21.7504 24.3383C19.5121 24.3383 17.3304 24.6783 15.2904 25.3583C14.2421 25.6983 13.2221 24.735 13.4771 23.6583C16.0554 13.345 25.9721 5.66663 37.8154 5.66663C51.6421 5.66663 62.8338 16.1216 62.8338 29.0133C62.8338 36.6633 58.8954 43.435 52.8321 47.685Z"
									fill="white"
								/>
								<path
									d="M37.3327 43.1517C37.3327 46.5234 36.086 49.64 33.9893 52.105C31.1843 55.505 26.736 57.6867 21.7493 57.6867L14.3543 62.0784C13.1077 62.8434 11.521 61.795 11.691 60.35L12.3993 54.7684C8.60268 52.1334 6.16602 47.9117 6.16602 43.1517C6.16602 38.165 8.82935 33.7734 12.9093 31.1667C15.431 29.5234 18.4627 28.5884 21.7493 28.5884C30.3627 28.5884 37.3327 35.105 37.3327 43.1517Z"
									fill="white"
								/>
							</svg>
						}
					/>
				</div>
			</div>
			<div className="w-full bg-[#FFF6F8]  py-16 px-48 flex justify-between">
				<div className="w-[592px] flex flex-col justify-between h-[1134px]">
					<img
						className="w-full max-h-[492.76px] grow object-cover object-right-bottom"
						src={aboutus2}
						alt=""
					/>
					<img
						className="w-full max-h-[628.24px] grow object-cover object-top"
						src={aboutus3}
						alt=""
					/>
				</div>
				<div className="w-[674px] flex flex-col">
					<span className="font-bold text-3xl/[38px] mb-6">Our Founders</span>
					<span className="font-normal text-lg/27px] mb-6">
						Our founders are Joseph Salvo and Sharon Lungrin. Joseph is a
						second-generation New Yorker of Italian heritage whose mother grew
						up on Washington Avenue, in the Tremont section of the Bronx, before
						the construction of the Cross Bronx Expressway. Sharon was born in
						Jamaica and migrated to East New York, Brooklyn in the early 1980s.
					</span>
					<span className="font-normal text-lg/27px] mb-8">
						Joseph and Sharon met in Venezuela in the mid-1990s, when Sharon was
						teaching English as a second language and Joseph was exploring
						classic European painting techniques in his studio and teaching at
						the University of the Andes in Merida.
					</span>
					<span className="font-normal text-lg/27px] mb-10">
						Joseph and Sharon's creative partnership started in their first
						conversation on the streets of Merida, when Joseph asked Sharon to
						sit for one of his Black Madonna paintings. A lifelong feminist and
						art lover, Sharon jumped at the opportunity. The pair soon learned
						of their affinity for music, having both grown up in cultures with
						rich traditions of sound.
					</span>
					<span className="font-normal text-lg/27px] mb-10">
						Inspired by the virtual dance halls of Sharon’s childhood in
						Jamaica, they dreamt of one day creating a Sound System to bring a
						new kind of music to the people. Despite the then prohibitive costs,
						Joseph and Sharon were determined to grow a community centered on
						digital music and visual art. They spent the early 2000s becoming
						versed in Pro Tools, Photoshop, Premiere Pro, silkscreening and
						digital recording. Back in New York City in 2009, they debuted their
						Sound System concept with the first Aeternal Lovers tracks, “Mystic
						Wish” and “Little Brakes Dub,” featuring Big Wayne and Ms. Smith on
						vocals.
					</span>
					<span className="font-normal text-lg/27px] mb-6">
						Eleven years later, Joseph and Sharon are busy cultivating Aeternal
						Lovers, a lifestyle brand bridging past, present and future through
						collaborative music and sustainable design.
					</span>
					<span className="font-normal text-lg/27px]">
						They are happy to call the Bronx home, forever inspired and
						invigorated by the dynamic community of artists, musicians, urban
						farmers, wellness practitioners, poets, and creatives here.
					</span>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;

import React, { useEffect, useState } from "react";
import {
  removeFromCart,
  updateCartItemQuantity,
} from "../../../../api/api_calls/shop";
import { useDispatch } from "react-redux";
import { fetchCart } from "../../../../redux/reducers/cart";

const BagItem = ({ product }) => {
  const dispatch = useDispatch();
  const initialQuantity = product?.qty;
  const [quantity, setQuantity] = useState(initialQuantity || 0);

  const updateQuantity = () => {
    const formData = {
      cartItemId: product?._id,
      qty: quantity,
    };

    updateCartItemQuantity(formData)
      .then((res) => dispatch(fetchCart()))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateQuantity();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [quantity]);

  const handleCrossButton = () => {
    const formData = {
      cartItemId: product?._id,
    };

    removeFromCart(formData)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => dispatch(fetchCart()));
  };
  return (
    <div className="p-[16px] rounded-[8px] flex items-center justify-between border-[1px] border-[#E4E4E4]">
      <div className="flex gap-[16px] items-center">
        <img
          src={
            product?.isCustomProduct
              ? product?.productImages
              : product?.productImages?.[0]
          }
          className="h-[109px] w-[109px] object-cover"
        />
        <div className="flex flex-col gap-[8px]">
          <h3 className="text-[#060709] font-semibold text-xl">
            {product?.productName}
          </h3>
          <p className="text-[#060709] font-medium text-lg">
            <span className="text-[#E6355C]">Size: {product?.size}</span> |
            Price: ${product?.price}
          </p>
          <div className="flex items-center py-[4px] border-[1px] w-fit rounded-[8px] border-[#060709] px-[16px] gap-[24px]">
            <button
              type="button"
              className={`${
                quantity === 1 ? "text-[#E4E4E4]" : "text-[#060709]"
              } cursor-pointer text-lg font-medium`}
              onClick={() => {
                if (quantity > 1) {
                  setQuantity(quantity - 1);
                  // updateQuantity(quantity - 1);
                }
              }}
            >
              -
            </button>
            <h3 className="text-[#060709] text-lg font-medium">{quantity}</h3>
            <button
              type="button"
              className="text-[#060709] cursor-pointer text-lg font-medium"
              onClick={() => {
                setQuantity(quantity + 1);
                // updateQuantity(quantity + 1);
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer"
        onClick={handleCrossButton}
      >
        <path
          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
          stroke="#060709"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.16992 14.83L14.8299 9.17001M14.8299 14.83L9.16992 9.17001"
          stroke="#060709"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default BagItem;

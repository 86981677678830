import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("AL_USER")) || {};

  if (userData?.accessToken) {
    return <Navigate to="/" />;
  } else return children;
};

export default PublicRoute;

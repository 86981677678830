import React, { useEffect, useRef, useState } from "react";
import { domToPng } from "modern-screenshot";

const DesignCreation = ({ selectedDesign, selectedGraphic }) => {
  const ref = useRef(null);
  const [designPosition, setDesignPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (selectedDesign) handleSelectDesign();
  }, [selectedDesign]);

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleSelectDesign = () => {
    const centerX =
      Number(selectedDesign?.coordinates?.[4]?.x) +
      Number(selectedDesign?.coordinates?.[4]?.width) / 2;
    const centerY =
      Number(selectedDesign?.coordinates?.[4]?.y) +
      Number(selectedDesign?.coordinates?.[4]?.height) / 2;
    const designWidth = 50;
    const designHeight = 50;
    const initialX = centerX - designWidth / 2;
    const initialY = centerY - designHeight / 2;

    setDesignPosition({ x: initialX, y: initialY });
  };

  const handleMouseMove = (event) => {
    if (dragging && selectedDesign) {
      const tshirtRect = event.currentTarget.getBoundingClientRect();
      const offsetX = event.clientX - tshirtRect.left;
      const offsetY = event.clientY - tshirtRect.top;

      //   const rectangle = getRectangleFromCoordinates(coordinates);
      const designWidth = 50;
      const designHeight = 50;
      const newX = Math.max(
        Number(selectedDesign?.coordinates?.[4]?.x),
        Math.min(
          offsetX,
          Number(selectedDesign?.coordinates?.[4]?.x) +
            Number(selectedDesign?.coordinates?.[4]?.width) -
            designWidth
        )
      );
      const newY = Math.max(
        Number(selectedDesign?.coordinates?.[4]?.y),
        Math.min(
          offsetY,
          Number(selectedDesign?.coordinates?.[4]?.y) +
            Number(selectedDesign?.coordinates?.[4]?.height) -
            designHeight
        )
      );

      setDesignPosition({ x: newX, y: newY });
    }
  };
  return (
    <>
      <div
        className="relative"
        onMouseMove={handleMouseMove}
        ref={ref}
        id="custom-design"
        crossOrigin="true"
      >
        {selectedDesign ? (
          <img
            src={selectedDesign?.productImages?.[0]}
            className="w-[622px] h-[624.53px] object-cover"
          />
        ) : <h3 className="font-semibold text-2xl">Select a category to customize your designs</h3>}

        {selectedDesign?.coordinates?.[4] && (
          <div
            className="rectangle-overlay"
            id="restricting-rectangle"
            style={{
              position: "absolute",
              left: Number(selectedDesign?.coordinates?.[4]?.x),
              top: Number(selectedDesign?.coordinates?.[4]?.y),
              width: Number(selectedDesign?.coordinates?.[4]?.width),
              height: Number(selectedDesign?.coordinates?.[4]?.height),
              border: "2px solid red",
              pointerEvents: "none",
            }}
          />
        )}
        {selectedGraphic && (
          <div
            className="design-preview"
            style={{
              position: "absolute",
              top: designPosition.y,
              left: designPosition.x,
            }}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
          >
            <img
              src={selectedGraphic?.images?.[0]}
              alt="Design"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DesignCreation;

import { React, useState, useEffect } from "react";
import styles from "./community.module.css";
import add_circle from "../../../assets/add-circle.svg";
import loadingSVG from "../../../skeletons/loading.svg";
import { db, usersRef, channelsRef } from "../../../firebase/firestore_config";
import {
	collection,
	getDocs,
	query,
	where,
	or,
	onSnapshot,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const Channels = ({
	channels,
	currChannel,
	setCurrChannel,
	saveDraft,
	openAddModal,
	fetchChannelMembers,
	setChannelMembersLoading,
}) => {
	// please keep object of channel wherever you go. use the space. don't waste time in just using the id
	// later on, if the use of details other than the id are nill, then change the use of object to the use of just the id everywhere
	const userState = useSelector((state) => state.user);
	const userData = userState?.userData; // there is no way we reach this part of the webapp without userState & userData...
	const { resolutionType } = useSelector((state) => state.resolution);

	// const [currChannel, setCurrChannel] = useState(null);

	// channels lifecycle
	// const fetchChannels = async () => {
	// 	// const channelQuery = query(
	// 	// 	channelsRef,
	// 	// 	or(
	// 	// 		where("users", "array-contains", userData._id),
	// 	// 		where("isPublic", "==", true)
	// 	// 	)
	// 	// );
	// 	// const channel_lifecycle = onSnapshot(channelQuery, (channelSnap) => {
	// 	// 	const tempChannels = [];
	// 	// 	channelSnap.forEach((doc) => {
	// 	// 		tempChannels.push({
	// 	// 			id: doc.id,
	// 	// 			fields: { ...doc.data() },
	// 	// 		});
	// 	// 	});
	// 	// 	setChannels([...tempChannels]);
	// 	// })
	// 	// const channelSnapshot = await getDocs(channelQuery);
	// 	// const tempChannels = [];
	// 	// channelSnapshot.forEach((doc) => {
	// 	// 	tempChannels.push({
	// 	// 		id: doc.id,
	// 	// 		fields: { ...doc.data() },
	// 	// 	});
	// 	// });
	// 	// setChannels([...tempChannels]);
	// };

	// useEffect(() => {
	// 	const channelQuery = query(
	// 		channelsRef,
	// 		or(
	// 			where("isPublic", "==", true),
	// 			where("users", "array-contains", userData._id)
	// 		)
	// 	);
	// 	const channel_lifecycle = onSnapshot(channelQuery, (channelSnap) => {
	// 		const tempChannels = [];
	// 		channelSnap.forEach((doc) => {
	// 			tempChannels.push({
	// 				id: doc.id,
	// 				fields: { ...doc.data() },
	// 			});
	// 		});
	// 		setChannels([...tempChannels]);

	// 		if (flag) {
	// 			console.log("FLAG FUNCTION, ", tempChannels);
	// 			changeChannel(tempChannels[0]);
	// 			setFlag(false);
	// 		}

	// 		setLoading(false);
	// 	});

	// 	return () => {
	// 		channel_lifecycle();
	// 	};
	// }, []);

	const changeChannel = (channel) => {
		// setChatLoading(true);
		saveDraft(currChannel, channel);
		setCurrChannel(channel);
		// fetchChat(channel);
		setChannelMembersLoading(true);
		fetchChannelMembers(channel).finally(() => {
			setTimeout(() => {
				setChannelMembersLoading(false);
			}, 200);
		});
	};

	return (
		<div
			className={` bg-[#F9F9F9] px-2 relative ${
				resolutionType.type === "mobile" ? "w-full h-[25vh]" : "w-64 h-full"
			}`}
		>
			<div>
				<div className={styles.channelTitle}>All Channels</div>
			</div>
			<div
				className={`${styles.channelList} text-base font-semibold text-center overflow-y-clip flex flex-col z-0`}
			>
				<div className="h-full overflow-y-auto">
					{channels?.map((item, index) => {
						return (
							<div
								key={index}
								className={`rounded-lg border-transparent my-3 p-1 cursor-pointer ${
									currChannel?.id === item.id
										? "bg-[#E6355C] text-white"
										: "bg-transparent text-[#858585] hover:bg-[#858585] hover:text-white"
								}  `}
								onClick={() => changeChannel(item)}
							>
								#{item.fields.title}
							</div>
						);
					})}
				</div>
				<div
					onClick={openAddModal}
					className="flex justify-center gap-x-1.5 rounded-lg cursor-pointer border-2 border-[#858585] text-[#858585] my-3 p-1"
				>
					<img alt="Add-Circle" src={add_circle} />
					Add Community Channel
				</div>
			</div>
			{/* {loading && (
				<div
					className="w-full h-full absolute flex justify-center items-center"
					style={{
						boxSizing: "border-box",
						paddingBottom: "0.5rem",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						top: "0",
						left: "0",
					}}
				>
					<CircularProgress color="inherit" />
				</div>
			)} */}
		</div>
	);
};

export default Channels;

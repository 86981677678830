import moment from "moment";
import React from "react";

const orderStatuses = ["Paid", "Transit", "Delivered", "Refunded", "Cancelled"];

const OrderDetail = ({ order }) => {
  return (
    <div className="flex flex-col p-4 rounded-lg border-[1px] border-[#E4E4E4]">
      <div className="flex items-center justify-between pb-4 border-b-[1px] border-b-[#E4E4E4]">
        <h3 className="text-[#060709] font-semibold text-2xl">Order Detail</h3>
        <div className="flex gap-10 font-medium text-base text-[#858585]">
          <p>Order No.: {order?.orderId}</p>
          <p>Order Date: {moment(order?.createdAt).format("L")}</p>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex gap-4 items-center">
          <img
            src={
              order?.orderItems?.[0]?.productImage ||
              order?.orderItems?.[0]?.productImages?.[0]
            }
            className="w-[76.51px] h-[76.26px] object-cover rounded-[4px]"
          />
          <div className="flex flex-col gap-2">
            <p className="font-medium text-lg text-[#858585]">
              {order?.orderItems?.[0]?.productDetails?.productName}{" "}
              {order?.orderItems?.length > 1
                ? `+ ${order?.orderItems?.length - 1} more`
                : ""}
            </p>
            <p className="font-medium text-lg text-[#858585]">
              <span className="text-[#E6355C]">
                Size: {order?.orderItems?.[0]?.size}
              </span>
              <span className="font-semibold mx-4">|</span>
              Price: $
              {(
                order?.orderAmount?.subTotal +
                order?.orderAmount?.shippingCharges -
                order?.orderAmount?.discount
              ).toFixed(2)}
            </p>
          </div>
        </div>
        <p className="text-base font-semibold text-[#42B126]">
          {orderStatuses[order?.orderStatus - 1]}
        </p>
      </div>
    </div>
  );
};

export default OrderDetail;

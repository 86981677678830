import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const blogContent = [
	{
		blogName: "SPEAK LOVE in the Pollinators Garden",
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20211009_134811_480x480.jpg?v=1634156230",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						className="mb-20"
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20211009_134811_480x480.jpg?v=1634156230"
						alt=""
					/>
				</p>
				<p>
					While the letter "A'" stands for communication through words,&nbsp;
					the heart represents the highest vibrational emotion, LOVE.
				</p>
				<p>
					In each moment, we have a choice to operate from a place of fear or
					love.
				</p>
				<p>
					Keep your eyes open for our Speak Love design. We hope it will
					reminded you to live from love.
				</p>
			</div>
		),
		blogCaption: `While the letter "A'" stands for communication through words, the 
heart represents the highest vibrational emotion, LOVE. In each moment, we have...`,
	},
	{
		blogName: "Aeternal Garden",
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/Sharon_the_the_Garden_480x480.jpg?v=1633556129",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/Sharon_the_the_Garden_480x480.jpg?v=1633556129"
						alt=""
						className="mb-20"
					/>
				</p>
				<p>
					This Pollinators Garden is part of the James Baldwin Outdoor Learning
					Center on the campus of DeWitt Clinton High School. JBOLC is hosting a
					Saturday Farmers Market like no other a few feet away from this garden
					until November 20th. Stop by to shop local for eco-friendly goods,
					build community and chase butterflies.&nbsp;
				</p>
			</div>
		),
		blogCaption: `This Pollinators Garden is part of the James Baldwin Outdoor Learning Center on the campus of DeWitt Clinton High School. JBOLC is hosting a Saturday Farmers Market like no other a few feet away from this garden until November 20th. Stop by to shop local for eco-friendly goods, build community and chase butterflies.`,
	},
	{
		blogName: "Aeternal Basil",
		blogCaption: `Miss Sheila and Mr. B are long time residents of Tracy Towers who came to the James Baldwin Outdoor Learning Center Farmers Market for the first time last Saturday.  They were delight to find a community garden, farmers market and community meeting place a short walk from their home.  I had a lovely conversation with Mr.B about growing up in South Carolina, his military service and his service to his community as a church leader and foster parent. But the highlight for me was picking fresh basil from the garden for Miss Sheila's fish dish.  The JBOLC herb garden is in bloom...`,
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113907_480x480.jpg?v=1632930062",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113907_480x480.jpg?v=1632930062"
						alt=""
						className="mb-20"
					/>
				</p>
				<p>
					Miss Sheila and Mr. B are long time residents of Tracy Towers who came
					to the James Baldwin Outdoor Learning Center Farmers Market for the
					first time last Saturday.&nbsp; They were delight to find a community
					garden, farmers market and community meeting place a short walk from
					their home.&nbsp; I had a lovely conversation with Mr.B about growing
					up in South Carolina, his military service and his service to his
					community as a church leader and foster parent. But the highlight for
					me was picking fresh basil from the garden for Miss Sheila's fish
					dish.&nbsp; The JBOLC herb garden is in bloom...
				</p>
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113814_480x480.jpg?v=1632930031"
						alt=""
						className="mt-20"
					/>
				</p>
			</div>
		),
	},
	{
		blogName: "Aeternal Mushrooms",
		blogCaption: `We have been immersed in Climate Week NYC activities  this week. In the park Monday morning, five minutes away from the last stop on the number #1 train, I came across this mushroom. This area of  Van Cortlandt Park was flooded by Hurricane Ida earlier this month. Fungi have been around for a billion years and they know a thing or two about partnership, adaptability, regeneration and design.`,
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/IMG_20210922_101710_552_480x480.jpg?v=1632506476",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<span>
						<img
							src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/IMG_20210922_101710_552_480x480.jpg?v=1632506476"
							alt=""
							width="504"
							height="504"
							className="mb-20"
						/>
					</span>
				</p>
				<p>
					<span>
						We have been immersed in Climate Week NYC activities&nbsp; this
						week. In the park Monday morning,&nbsp;five minutes away from the
						last stop on the number #1 train, I came across this mushroom. This
						area of&nbsp; Van Cortlandt Park was flooded by Hurricane Ida
						earlier this month. Fungi have been around for a billion years and
						they know a thing or two about partnership, adaptability,
						regeneration and design.
					</span>
				</p>
			</div>
		),
	},
];

const Blogs = () => {
	const { state } = useLocation();
	const [blogSelected, setBlogSelected] = useState(state?.selectedBlog || null);
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<div
			className={`flex py-16 px-[12.25%] gap-5 bg-white ${
				resolutionType.type === "mobile" ? "flex-col" : ""
			}`}
		>
			{!blogSelected ? (
				blogContent.map((blogDetails, ind) => (
					<div
						className="max-w-[345px] flex flex-col gap-4 cursor-pointer overflow-hidden"
						onClick={() => setBlogSelected(ind + 1)}
					>
						<img
							src={blogDetails?.blogImage}
							className="h-[381px] w-[345px] object-cover"
						/>
						<h3
							className={`font-semibold text-[20px] text-[#060709] ${
								resolutionType.type === "mobile" ? "w-full" : "  h-[60px]"
							}`}
						>
							{blogDetails?.blogName}
						</h3>
						<p
							className={`text-[#858585] font-normal text-base truncate-multiline ${
								resolutionType.type === "mobile" ? "w-full" : ""
							}`}
						>
							{blogDetails?.blogCaption}
						</p>
					</div>
				))
			) : (
				<div className="flex flex-col w-full">
					<div className="flex justify-start w-full">
						<ArrowBackIcon
							fontSize="large"
							className="cursor-pointer"
							onClick={() => setBlogSelected(null)}
						/>
					</div>
					<h3 className="text-center font-bold text-[#060709] text-2xl mb-20">
						{blogContent?.[blogSelected - 1]?.blogName}
					</h3>
					{blogContent?.[blogSelected - 1]?.blogHTML}
				</div>
			)}
		</div>
	);
};

export default Blogs;

import React from "react";
import music from "../../../assets/music_icon.png";
import community from "../../../assets/community_icon.png";
import calendar from "../../../assets/calendar_icon.png";
import musicInactive from "../../../assets/music_inactive.png";
import communityInactive from "../../../assets/community_inactive.png";
import calendarInactive from "../../../assets/calendar_inactive.png";
import classes from "./WebAppSidebar.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const sidebarOptions = [
	{
		name: "Music",
		path: "/app/sound",
		icon: music,
		inactiveIcon: musicInactive,
	},
	{
		name: "Community",
		path: "/app/community",
		icon: community,
		inactiveIcon: communityInactive,
	},
	{
		name: "Calendar",
		path: "/app/calendar",
		icon: calendar,
		inactiveIcon: calendarInactive,
	},
];

const WebAppSidebar = () => {
	const location = useLocation();
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<div
			className={`flex ${
				resolutionType.type === "mobile"
					? "justify-between items-center gap-4"
					: classes.sidebar
			}`}
		>
			{sidebarOptions.map((option) => (
				<NavLink
					key={option?.path}
					to={option?.path}
					className="flex flex-col items-center gap-[8px] cursor-pointer"
				>
					<img
						src={
							location.pathname.includes(option.path)
								? option?.icon
								: option?.inactiveIcon
						}
					/>
					<div className="flex flex-col items-center">
						<p className="text-white font-semibold text-sm">{option?.name}</p>
						{location.pathname.includes(option.path) && (
							<div className="h-[6px] w-[6px] rounded-[100%] bg-[#E6355C]"></div>
						)}
					</div>
				</NavLink>
			))}
		</div>
	);
};

export default WebAppSidebar;

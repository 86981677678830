import { React, useState } from "react";
import GradientButton from "../components/GradientButton/GradientButton";
import LooperGroup from "../assets/LooperGroup.png";
import CompanyLogo from "../assets/company_logo.png";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../api/api_calls/auth";
import { toast } from "react-toastify";
import { forgotPasswordSchema } from "../validations/auth";
import CustomInput from "../components/CustomInput/CustomInput";
import { handleApiError } from "../utils/helperFunctions";
import { useSelector } from "react-redux";

const Forgot = () => {
	const { loading } = useSelector((state) => state.loader);
	const [email, setEmail] = useState("");
	const [formError, setFormError] = useState({});

	const { resolutionType } = useSelector((state) => state.resolution);

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = { email };

		const { error } = forgotPasswordSchema.validate(formData);

		if (error) {
			setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
			return;
		} else setFormError({});

		forgotPassword(formData)
			.then((res) => {
				setEmail("");
				toast.success(res?.data?.message, { theme: "dark" });
			})
			.catch((error) => {
				handleApiError(error, setFormError);
			});
	};

	return (
		<div className="w-screen h-screen bg-[#FFF1F4] flex items-center justify-center">
			<div
				className={`flex flex-col md:flex-row ${
					resolutionType.type === "mobile" ? "mx-6" : "mx-[15%]"
				}`}
			>
				<form
					className={`py-16 md:w-full flex-col bg-[#FFFFFF] ${
						resolutionType.type === "mobile" ? "px-8" : "w-1/2 px-14"
					}`}
					onSubmit={handleSubmit}
				>
					<div className="font-bold text-3xl mb-1">Forgot Password?</div>
					<div className="font-normal text-base mb-5 pr-10">
						To reset your password, enter your email address below.
					</div>
					<div className="font-medium text-lg mb-2">Email Address</div>
					{/* <input
            className="w-full text-base font-normal rounded-lg py-4 px-2 mb-5 border border-zinc-200"
            type="text"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
					<CustomInput
						type="text"
						placeholder="Enter Email Address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={formError?.email}
					/>
					<GradientButton
						className="mt-5"
						type="submit"
						style={{ width: "100%", justifyContent: "center" }}
						title={"Reset Password"}
						loading={loading}
					/>
				</form>
			</div>
		</div>
	);
};

export default Forgot;

import React from "react";
import { useSelector } from "react-redux";
import DefaultPfp from "../../../../assets/company_logo_dark.png";

const CollabCard = ({ email, firstName, lastName, profilePicture, _id }) => {
	const { resolutionType } = useSelector((state) => state.resolution);
	return (
		<div
			key={_id}
			className={`flex flex-col rounded-sm p-4 gap-y-2 hover:bg-[#FFF1F4] ${
				resolutionType.type === "mobile" ? "w-full" : "w-[367px]"
			}`}
		>
			<img
				src={profilePicture ? profilePicture : DefaultPfp}
				className={`w-full rounded-[4px] ${
					profilePicture
						? "object-cover"
						: "object-contain border-2 border-[#E4E4E4]"
				} ${resolutionType.type === "mobile" ? "h-50vh" : "h-[290px]"}`}
			/>
			<span className="text-2xl font-semibold">
				{firstName} {lastName}
			</span>
		</div>
	);
};

export default CollabCard;

import React, { useEffect, useState } from "react";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import { addToCart, getCustomProducts } from "../../../api/api_calls/shop";
import DesignCreation from "./DesignCreation/DesignCreation";
import { domToPng } from "modern-screenshot";
import { fetchCart } from "../../../redux/reducers/cart";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import CustomModal from "../../../components/CustomModal/CustomModal";
import SizeChart from "../../../components/ModalComponents/SizeChart/SizeChart";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

const CustomDesign = () => {
	const [artTypes] = useOutletContext();
	const { resolutionType } = useSelector((state) => state.resolution);
	const { loading } = useSelector((state) => state.loader);
	const { userData } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [size, setSize] = useState("S");
	const [quantity, setQuantity] = useState(1);
	const [sizeModal, setSizeModal] = useState(false);
	const [customProducts, setCustomProducts] = useState([]);
	const [selectedDesign, setSelectedDesign] = useState(null);
	const [selectedGraphic, setSelectedGraphic] = useState(null);
	const [error, setError] = useState({});

	useEffect(() => {
		getCustomProducts()
			.then((res) => setCustomProducts(res?.data?.data))
			.catch((error) => console.log(error));
	}, []);

	useEffect(() => {
		if (error?.design || error?.graphic) {
			setTimeout(() => setError({}), 5000);
		}
	}, [error]);

	const handleCheckout = (adding) => {
		if (!userData?._id) {
			navigate("/login", { state: { from: window.location.pathname } });
			return;
		}
		if (!selectedDesign) {
			setError({ design: "Please choose a category first!" });
			return;
		} else if (!selectedGraphic) {
			setError({ graphic: "Please choose a design first!" });
			return;
		}

		const rectangle = document.getElementById("restricting-rectangle");
		rectangle.style.display = "none";

		domToPng(document.querySelector("#custom-design"), {
			fetch: {
				requestInit: {
					cache: "no-cache",
				},
			},
		})
			.then((dataUrl) => {
				rectangle.style.display = "";
				return fetch(dataUrl);
			})
			.then((res) => res.blob())
			.then((blob) => {
				const formData = new FormData();

				formData.append("productId", selectedDesign?._id);
				formData.append("productName", selectedDesign?.productName);
				formData.append("price", selectedDesign?.basePrice);
				formData.append("qty", quantity);
				formData.append("size", size);
				formData.append("productImage", blob);
				formData.append("isCustomProduct", true);

				return addToCart(formData);
			})
			.then((res) => {
				dispatch(fetchCart());

				if (!adding) navigate("/cart");
				else toast.success("The item has been added to the cart.");
			})
			.catch((error) => console.log(error));
	};

	return (
		<>
			<BreadCrumb />
			<div
				className={`w-full flex  bg-white ${
					resolutionType.type === "mobile" ? "flex-col" : "pl-[12.25%]"
				}`}
			>
				<div
					className={`  ${
						resolutionType.type === "mobile"
							? "w-full"
							: " w-[45%] py-[120px] pr-12"
					}`}
				>
					<h2 className="font-bold text-[30px] ">
						Select a design from our curated design, and we'll give you the
						finished product.
					</h2>
					<h3 className="my-4 font-semibold text-[20px] text-[#E6355C]">
						Uploading Instructions:
					</h3>
					<ul className="mt-4 ml-8 flex flex-col font-normal text-lg text-[#858585] list-disc gap-4">
						<li>
							Choose a product category for which you'd like to apply the
							design.
						</li>
						<li>
							Choose a design from our curated Design library that we've crafted
							specifically for you.
						</li>
						<li>
							Choose the size and quantity, proceed to checkout, and we'll bring
							joy right to your doorstep.
						</li>
					</ul>
					<div className=" border-b-[1px] mt-8 border-b-[#E4E4E4]"></div>
					<h3 className="font-semibold text-[20px] text-[#060709] mt-8">
						Select Category and Designs
					</h3>
					<div className="flex items-center gap-4 mt-8">
						<h3 className="font-semibold text-lg text-[#060709] ">Category</h3>
						{error?.design && (
							<div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
								<InfoIcon fontSize="inherit" color="inherit" />
								<h3 className="font-medium text-sm">{error?.design}</h3>
							</div>
						)}
					</div>
					<div className="flex gap-[10px] mt-2 w-full overflow-auto sleek-scrollbar">
						{customProducts?.map((product) => (
							<img
								src={product?.productImages?.[0]}
								className={`cursor-pointer rounded-lg w-[100px] h-[100px] object-cover ${
									selectedDesign?._id === product?._id
										? "border-[1px] border-[#E6355C]"
										: ""
								}`}
								onClick={(e) => setSelectedDesign({ ...product })}
							/>
						))}
					</div>
					<div className="flex items-center gap-4 mt-8">
						<h3 className="font-semibold text-lg text-[#060709] ">Design</h3>
						{error?.graphic && (
							<div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
								<InfoIcon fontSize="inherit" color="inherit" />
								<h3 className="font-medium text-sm">{error?.graphic}</h3>
							</div>
						)}
					</div>
					<div className="flex gap-[10px] mt-2 w-full overflow-auto sleek-scrollbar">
						{artTypes.map((artType) => (
							<img
								src={artType?.images?.[0]}
								className={`cursor-pointer rounded-lg w-[100px] h-[100px] object-cover ${
									selectedGraphic?._id === artType?._id
										? "border-[1px] border-[#E6355C]"
										: ""
								}`}
								onClick={(e) => setSelectedGraphic({ ...artType })}
							/>
						))}
					</div>
					<h3 className="mt-8 font-semibold text-lg text-[#060709]">Size</h3>
					<div className="flex gap-[24px] mt-2 items-end">
						{["S", "M", "L", "XL"]?.map((item) => (
							<div
								className={`${
									size === item ? "bg-[#060709] text-white" : ""
								} h-[50px] w-[50px] cursor-pointer text-[#858585] hover:bg-[#060709] hover:text-white rounded-[8px] border-[1px] border-[#858585] flex justify-center items-center`}
								onClick={() => setSize(item)}
							>
								{item}
							</div>
						))}
						{selectedDesign && (
							<p
								className="flex items-center font-medium text-lg text-[#6964B4] gap-[8px] cursor-pointer"
								onClick={() => setSizeModal(true)}
							>
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M21.4702 19.5V5.5C21.4702 3.5 20.4702 2.5 18.4702 2.5H14.4702C12.4702 2.5 11.4702 3.5 11.4702 5.5V19.5C11.4702 21.5 12.4702 22.5 14.4702 22.5H18.4702C20.4702 22.5 21.4702 21.5 21.4702 19.5Z"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M11.4702 6.5H16.4702"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M11.4702 18.5H15.4702"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M11.4702 14.45L16.4702 14.5"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M11.4702 10.5H14.4702"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M5.48978 2.5C3.85978 2.5 2.52979 3.83 2.52979 5.45V18.41C2.52979 18.86 2.71979 19.54 2.94979 19.93L3.76979 21.29C4.70979 22.86 6.25979 22.86 7.19979 21.29L8.01979 19.93C8.24979 19.54 8.43979 18.86 8.43979 18.41V5.45C8.43979 3.83 7.10978 2.5 5.48978 2.5Z"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
									<path
										d="M8.43979 7.5H2.52979"
										stroke="#6964B4"
										stroke-width="1.5"
										stroke-linecap="round"
									/>
								</svg>
								Size Guide
							</p>
						)}
					</div>
					<h3 className="mt-8 font-semibold text-lg text-[#060709]">
						Quantity
					</h3>

					<div className="flex mt-2 items-center py-[4px] border-[1px] w-fit rounded-[8px] border-[#060709] px-[16px] gap-[24px]">
						<button
							type="button"
							className={`${
								quantity === 1 ? "text-[#E4E4E4]" : "text-[#060709]"
							} cursor-pointer text-lg font-medium`}
							onClick={() => {
								if (quantity > 1) setQuantity(quantity - 1);
							}}
						>
							-
						</button>
						<h3 className="text-[#060709] text-lg font-medium">{quantity}</h3>
						<button
							type="button"
							className="text-[#060709] cursor-pointer text-lg font-medium"
							onClick={() => setQuantity(quantity + 1)}
						>
							+
						</button>
					</div>
					{/* <button className="custom-add-to-cart mt-8" onClick={() => handleCheckout(true)}>
              {loading ? <span className="text-[#E6355C] font-medium "><CircularProgress color="inherit" size={30} /></span> : <span
                style={{
                  background:
                    "linear-gradient(90deg, #E93359 0%, #6167BA 100%)",
                  "-webkit-text-fill-color": "transparent",
                  "-webkit-background-clip": "text",
                }}
              >
                Add To Cart
              </span>}
            </button> */}
					<GradientButton
						className="mt-8"
						title="Add To Cart"
						style={{ width: "100%" }}
						onClick={() => handleCheckout(true)}
						// loading={loading}
					/>
					<GradientButton
						className="mt-8"
						title="Checkout"
						style={{ width: "100%" }}
						onClick={() => handleCheckout(false)}
						// loading={loading}
					/>
				</div>
				<div
					className={` bg-[#E4E4E4] flex h-auto justify-center items-center ${
						resolutionType.type === "mobile" ? "" : "w-[55%]"
					}`}
				>
					<DesignCreation
						selectedDesign={selectedDesign}
						selectedGraphic={selectedGraphic}
					/>
				</div>
				<CustomModal
					title="Size Chart"
					caption=""
					open={sizeModal}
					onClose={() => setSizeModal(false)}
				>
					<SizeChart image={selectedDesign?.sizeChart} />
				</CustomModal>
			</div>
		</>
	);
};

export default CustomDesign;

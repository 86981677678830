import React from "react";
import classes from "./Sustainability.module.css";
import { useSelector } from "react-redux";

const Sustainability = () => {
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<div
			className={`bg-white py-16  ${classes.sustainability} ${
				resolutionType.type === "mobile" ? "px-4" : "px-[25%] "
			}`}
		>
			<div className="flex flex-col gap-10">
				<p style={{ textAlign: "center" }}>
					<span style={{ fontWeight: 400 }}>
						In our lives and work, we’ve witnessed the intersections of
						sustainability, climate change, social and racial injustice, and
						economic inequity. We may not be able to tackle it all, but we can
						take a step in the right direction&nbsp;with the help&nbsp;of music
						and art. Sustainable creativity fosters{" "}
					</span>
						<span style={{ fontWeight: 400 }}>belonging</span>
					<span style={{ fontWeight: 400 }}>
						. Belonging to the earth, belonging to our larger community, and
						connection to our families, living and dead.
					</span>
				</p>
				<p style={{ textAlign: "center" }}>
					<span style={{ fontWeight: 400 }}>
						For us, sustainability is about people and community. Whether we’re
						using art and music to teach English to recent immigrant students in
						Mott Haven or making music with passerby in front of the Bronx
						Native Market in Hunts Point, our approach has always been
						regenerative, collective, and grounded in community and family.
					</span>
				</p>
				<p style={{ textAlign: "center" }}>
					<span style={{ fontWeight: 400 }}>
						For our part, we seek to inspire cooperative, regenerative action
						through music and design. We created our music production concept,
						the Aeternal Lovers Sound System, to grow a virtual community of
						artists in the Bronx and beyond. Where our Sound System fosters
						community through music, our upcycled clothing items bridge past and
						future, carrying their own history and&nbsp;becoming a part of the
						wearer’s story for years to come.&nbsp;
					</span>
				</p>
				<p style={{ textAlign: "center" }}>
					<span style={{ fontWeight: 400 }}>
						All that said, sustainable living can feel elusive for a small
						brand, especially in a world of same-day delivery and single-use
						stuff. We are taking small, concrete steps to be the change we want
						to see. One such step is partnering with{" "}
					</span>
					<a
						href="https://www.repack.com/"
						aria-describedby="a11y-external-message"
					>
						<span style={{ fontWeight: 400 }}>RePack</span>
					</a>
					<span style={{ fontWeight: 400 }}>
            , the pioneer in sustainable shipping.{" "}
          </span>
          <br/>
          <br/>
          <br/>
          <span className="mt-10" style={{fontWeight: 400}}>
          Every Aeternal Lovers order
          is mailed in a RePack returnable packet made from{" "}
          </span>
					<span style={{ fontWeight: 400 }}>
						recycled polypropylene at no additional cost to you
					</span>
					<span style={{ fontWeight: 400 }}>
						. All we ask is that you return the empty, pre-paid RePack to your
						local postbox to continue the cycle.
					</span>
					<span style={{ fontWeight: 400 }}>
						We think that’s pretty cool. How about you?
					</span>
					<br />
					<span style={{ fontWeight: 400 }}></span>
				</p>
				<p style={{ textAlign: "center" }}>
					<span style={{ fontWeight: 400 }}>
						We invite you to explore our website,{" "}
					</span>
					<a
						href="https://www.instagram.com/aeternallovers/?hl=en"
						aria-describedby="a11y-external-message"
					>
						<span style={{ fontWeight: 400 }}>Instagram</span>
					</a>
					<span style={{ fontWeight: 400 }}>, </span>
					<a
						href="https://www.youtube.com/channel/UC8z22mAeUKOpIZ84BsPgcvQ"
						aria-describedby="a11y-external-message"
					>
						<span style={{ fontWeight: 400 }}>Youtube</span>
					</a>
					<span style={{ fontWeight: 400 }}> and </span>
					<a
						href="https://soundcloud.com/aeternallovers"
						aria-describedby="a11y-external-message"
					>
						<span style={{ fontWeight: 400 }}>SoundCloud</span>
					</a>
					<span style={{ fontWeight: 400 }}>
						, and to join us in collaborative creation.
					</span>
				</p>
			</div>
		</div>
	);
};

export default Sustainability;

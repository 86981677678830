import React, { useContext, useRef, useCallback, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
// import Ticker from "../../../../components/Ticker/Ticker";
import { PlayerContext } from "../Sound";
import { formatTime } from "../../../../utils/helperFunctions";

const TableMusic = ({
	labels,
	content,
	playType,
	playQuery,
	currPage,
	limit,
	fetchMore,
	moreLoad,
	load,
}) => {
	useEffect(() => {
		console.log("TABLE MUSIC", load);
	}, []);

	const { play, setPlay, setOpen, currentTrack, setCurrentTrack, musicEngine } =
		useContext(PlayerContext);

	const observer = useRef();

	const lastElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					fetchMore();
				}
			});
			if (node) observer.current.observe(node);
		},
		[fetchMore]
	);

	return (
		<>
			{load ? (
				<div className="w-full flex items-center justify-center">
					<CircularProgress />
				</div>
			) : (
				<TableContainer component="div">
					<Table stickyHeader>
						<TableHead>
							{labels?.map((label, index) => {
								return (
									<TableCell
										key={index}
										sx={{
											fontFamily: "Poppins",
											fontSize: "0.875rem",
											fontWeight: 600,
											border: "none",
										}}
									>
										{label}
									</TableCell>
								);
							})}
						</TableHead>
						{!content || content?.length === 0 ? (
							<span className="block ml-4 text-lg font-bold">No Music...</span>
						) : (
							<TableBody>
								<>
									<>
										{content?.map((item, index) => {
											// const isLastElement = index === content.length - 1;
											return (
												<TableRow
													key={index}
													// ref={isLastElement ? lastElementRef : null}
													sx={{
														".MuiTableCell-root": {
															fontFamily: "Poppins",
															fontSize: "0.875rem",
															fontWeight: 400,
															color: "#858585",
															borderBottom: "1px solid #E4E4E4",
														},
													}}
												>
													<TableCell>{index + 1}</TableCell>
													<TableCell>
														<div className="flex gap-x-4 items-center">
															<img
																src={
																	item?.isYoutube
																		? item?.youtube?.thumbnail
																		: item?.audio?.thumbnail
																}
																alt={"thumbnail"}
																style={{
																	borderRadius: "50%",
																	width: "1.75rem",
																	aspectRatio: "1",
																	objectFit: "cover",
																}}
															/>
															{item?.title}
														</div>
													</TableCell>
													<TableCell>{formatTime(item?.duration)}</TableCell>
													<TableCell>
														{item?.artist}
														{/* <Ticker
												text="WAOOOOOOOOOOOOOOOOOOOO"
												style={{
													fontSize: "inherit",
													fontWeight: "inherit",
													color: "inherit",
												}}
											/> */}
													</TableCell>
													<TableCell>
														<div
															className="flex gap-x-2 cursor-pointer" // onClick={() => {
															// 	setOpen(true);
															// 	setCurrentTrack(item);
															// 	// setPlay(true);
															// 	// if (!play) setPlay(true);
															// }}
															onClick={() => {
																// console.log(
																// 	"REQUEST FOR MUSIC ENGINE: ",
																// 	playType,
																// 	index
																// );
																musicEngine(playType, playQuery, index);
															}}
														>
															<svg
																width="21"
																height="20"
																viewBox="0 0 21 20"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M10.9697 0C5.44973 0 0.969727 4.48 0.969727 10C0.969727 15.52 5.44973 20 10.9697 20C16.4897 20 20.9697 15.52 20.9697 10C20.9697 4.48 16.4997 0 10.9697 0ZM13.9697 12.23L11.0697 13.9C10.7097 14.11 10.3097 14.21 9.91973 14.21C9.51973 14.21 9.12973 14.11 8.76973 13.9C8.04973 13.48 7.61973 12.74 7.61973 11.9V8.55C7.61973 7.72 8.04973 6.97 8.76973 6.55C9.48973 6.13 10.3497 6.13 11.0797 6.55L13.9797 8.22C14.6997 8.64 15.1297 9.38 15.1297 10.22C15.1297 11.06 14.6997 11.81 13.9697 12.23Z"
																	fill="url(#paint0_linear_2776_2467)"
																/>
																<defs>
																	<linearGradient
																		id="paint0_linear_2776_2467"
																		x1="0.969727"
																		y1="0"
																		x2="20.9697"
																		y2="0"
																		gradientUnits="userSpaceOnUse"
																	>
																		<stop stop-color="#E93359" />
																		<stop offset="1" stop-color="#6167BA" />
																	</linearGradient>
																</defs>
															</svg>
															<span
																className="block "
																style={{
																	fontWeight: "600",
																	fontSize: "1rem",
																	background:
																		"-webkit-linear-gradient(90deg, #e93359, #6167ba)",
																	WebkitBackgroundClip: "text",
																	WebkitTextFillColor: "transparent",
																}}
															>
																Play
															</span>
														</div>
													</TableCell>
												</TableRow>
											);
										})}
									</>
									<>
										{moreLoad ? (
											<TableRow ref={lastElementRef}>
												<TableCell colSpan={5}>
													<div className="w-full flex justify-center items-center">
														<CircularProgress />
													</div>
												</TableCell>
											</TableRow>
										) : (
											<></>
										)}
									</>
								</>
							</TableBody>
						)}
					</Table>
				</TableContainer>
			)}
		</>
	);
};

export default TableMusic;

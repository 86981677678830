import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCartItems } from "../../api/api_calls/shop";
import store from "../../redux/store";
import { userActions } from "./user";

export const fetchCart = createAsyncThunk("fetchCart", async () => {
  const res = await getCartItems();
  store.dispatch(
    userActions.storeUser(res?.data?.data?.userDetails)
  );
  return res?.data?.data;
});

const initialState = { cart: [] };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    emptyCart(state) {
      state.cart = [];

      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCart.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cart = action.payload;
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.isError = true;
    });
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;

import moment from "moment";
import React from "react";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EventDetails = ({ eventDetails }) => {
  const eventStartDate = new Date(eventDetails?.eventStartDate);
  const eventEndDate = new Date(eventDetails?.eventEndDate);

  return (
    <div className="bg-white p-4 flex flex-col">
      <h2 className="font-semibold text-[22px] text-[#E6355C]">
        {eventDetails?.eventName}
      </h2>
      <p className="mt-1 font-normal text-base text-[#858585] border-b-[1px] border-b-[#E4E4E4] pb-4">
        {`${days[eventStartDate?.getDay()]}, ${
          months[eventStartDate?.getMonth()]
        } ${eventStartDate?.getDate()}, ${moment(eventStartDate).format(
          "LT"
        )} - ${moment(eventEndDate).format("LT")}`}
      </p>
      <label className="text-[#060709] mt-4 font-semibold text-base">
        Event Detail
      </label>
      <p className="font-normal text-base text-[#858585] mt-2">
        {eventDetails?.eventDetails}
      </p>
      <label className="text-[#060709] mt-4 font-semibold text-base">
        Event Venue
      </label>
      <p className="font-normal text-base text-[#858585] mt-2">
        {eventDetails?.eventAddress}
      </p>
    </div>
  );
};

export default EventDetails;

import {
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const stripePromise = loadStripe(
	process.env.REACT_APP_TYPE === "preprod"
		? process.env.REACT_APP_STRIPE_PREPROD
		: process.env.REACT_APP_STRIPE_TEST
);

// console.log(
// 	`${process.env.REACT_APP_TYPE === "preprod" ? "PREPROD " : "NOT PREPROD "} ${
// 		process.env.REACT_APP_TYPE === "preprod"
// 			? process.env.REACT_APP_STRIPE_PREPROD
// 			: process.env.REACT_APP_STRIPE_TEST
// 	}`
// );

const CheckoutForm = ({ orderData, paymentIntent, selectedAddress }) => {
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.loader);

	const [stripeValid, setStripeValid] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		orderData.orderDeliveryAddress = `${selectedAddress?.address}, ${selectedAddress?.city}, ${selectedAddress?.state} ${selectedAddress?.zipCode},${selectedAddress?.country}`;

		dispatch(loaderActions.toggleLoading(true));
		stripe
			.confirmPayment({
				elements,
				confirmParams: {
					// Return URL where the customer should be redirected after the PaymentIntent is confirmed.
					return_url: `${
						process.env.NODE_ENV === "development"
							? process.env.REACT_APP_STRIPE_SUCCESS_URL_DEV
							: process.env.REACT_APP_TYPE === "preprod"
							? process.env.REACT_APP_STRIPE_SUCCESS_URL_PREPROD
							: process.env.REACT_APP_STRIPE_SUCCESS_URL_STAGING
					}/paymentSuccess?paymentId=${paymentIntent}&orderDetails=${JSON.stringify(
						orderData
					)}`,
				},
			})
			.then(function (result) {
				if (result.error) {
					// Inform the customer that there was an error.
					navigate("/paymentFailed");
				}
			})
			.finally(() => dispatch(loaderActions.toggleLoading(false)));
	};

	return (
		<div className="flex bg-white w-full">
			<form className="flex w-full flex-col gap-6" onSubmit={handleSubmit}>
				<PaymentElement
					onChange={(e) => {
						if (e?.complete) setStripeValid(true);
						else setStripeValid(false);
					}}
				/>
				<GradientButton
					type="submit"
					disable={!stripeValid}
					title="Pay Now"
					style={{ width: "100%", background: !stripeValid ? "gray" : "" }}
					loading={loading}
				/>
			</form>
		</div>
	);
};

export default CheckoutForm;

import React, { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import GradientButton from "../../GradientButton/GradientButton";
import { editName } from "../../../api/api_calls/user";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../redux/reducers/user";
import { nameSchema } from "../../../validations/modal";
import { db, usersRef } from "../../../firebase/firestore_config";
import { doc, updateDoc } from "firebase/firestore";

const ChangeName = ({ onClose }) => {
	const { loading } = useSelector((state) => state?.loader);
	const { userData } = useSelector((state) => state?.user);

	const dispatch = useDispatch();

	const [firstName, setFirstName] = useState(userData?.firstName || "");
	const [lastName, setLastName] = useState(userData?.lastName || "");
	const [formError, setFormError] = useState({});

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			firstName,
			lastName,
		};

		const { error } = nameSchema.validate(data);

		if (error) {
			setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
			return;
		}

		editName(data)
			.then((res) => {
				dispatch(userActions.storeUser(res?.data?.data));
				onClose();
				const userDocRef = doc(usersRef, userData?._id);
				return updateDoc(userDocRef, {
					firstName: res?.data?.data?.firstName,
					lastName: res?.data?.data?.lastName,
				});
			})
			.then(() => console.log("SUCCESS"))
			.catch((err) => console.log(err));
	};

	return (
		<form
			className="bg-white py-[24px] px-[16px] flex flex-col gap-[16px]"
			onSubmit={handleSubmit}
		>
			<div className="flex flex-col gap-[8px]">
				<label className="font-medium text-black text-base">
					Enter First Name
				</label>
				<CustomInput
					placeholder="Enter your first name"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					error={formError?.firstName}
				/>
			</div>
			<div className="flex flex-col gap-[8px]">
				<label className="font-medium text-black text-base">
					Enter Last Name
				</label>
				<CustomInput
					placeholder="Enter your last name"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					error={formError?.lastName}
				/>
			</div>
			<GradientButton type="submit" title="Save" loading={loading} />
		</form>
	);
};

export default ChangeName;

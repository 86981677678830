import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, Outlet } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";

const MyProfile = () => {
  const [selectedTab, setSelectedTab] = useState(
    window.location.pathname.split("/")?.at(-1)
  );

  return (
    <>
      <BreadCrumb />
      <div className="px-[12.25%] bg-white py-[64px] flex flex-col gap-6">
        <div className="pb-[24px] border-b-[1px] border-b-[#E4E4E4]">
          <h3 className="text-black font-bold text-2xl">My Account</h3>
        </div>
        <div className="flex gap-[39px] border-b-[1px] border-b-[#E4E4E4]">
          <div className="flex flex-col">
            <Link
              to="/profile"
              className={`text-base font-medium px-2 ${
                selectedTab === "profile" ? " text-[#E6355C]" : " text-black"
              }`}
              onClick={() => setSelectedTab("profile")}
            >
              Profile
            </Link>
            {selectedTab === "profile" && (
              <motion.div
                layoutId="active-profile-tab"
                className="active-tab-indicator"
              />
            )}
          </div>
          <div className="flex flex-col">
            <Link
              to="/profile/addresses"
              className={`text-base font-medium px-2 ${
                selectedTab === "addresses" ? " text-[#E6355C]" : " text-black"
              }`}
              onClick={() => setSelectedTab("addresses")}
            >
              Addresses
            </Link>
            {selectedTab === "addresses" && (
              <motion.div
                layoutId="active-profile-tab"
                className="active-tab-indicator"
              />
            )}
          </div>
          <div className="flex flex-col">
            <Link
              to="/profile/orders"
              className={`text-base font-medium px-2 ${
                selectedTab === "orders" ? " text-[#E6355C]" : " text-black"
              }`}
              onClick={() => setSelectedTab("orders")}
            >
              Orders
            </Link>
            {selectedTab === "orders" && (
              <motion.div
                layoutId="active-profile-tab"
                className="active-tab-indicator"
              />
            )}
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default MyProfile;

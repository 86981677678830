import React from "react";
import classes from "./UploadMusicCard.module.css";
import girlListeningMusic from "../../../../assets/girl_listening_music.png";
import spiral from "../../../../assets/LooperGroup.png";
import companyLogo from "../../../../assets/company_logo.png";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UploadMusicCard = ({ setCollabReqModal }) => {
	const navigate = useNavigate();
	const userState = useSelector((state) => state.user);
	const userData = userState?.userData;
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<div
			className={classes.card}
			style={{
				...(resolutionType.type === "mobile"
					? {
							flexShrink: "0",
							width: "calc(100vw - 2rem)",
							height: "400px",
					  }
					: {
							minHeight: "268px",
							height: "50%",
					  }),
			}}
		>
			<div className="flex flex-col z-20 sticky">
				<img width="26px" src={companyLogo} className="sticky" />
				<p className="font-semibold pt-[12px] text-white text-2xl">
					You create Music?
				</p>
				<h2 className="font-bold text-white text-[32px] 2xl:text-[48px] mb-[18px]">
					Create with us
				</h2>
				<GradientButton
					title={
						userData?.isCollaborator || userData?.collabReqExist
							? "Upload your Music"
							: "Become a Collaborator?"
					}
					style={{ background: "white", color: "#E6355C" }}
					onClick={() => {
						// console.log("USER STATE: ", userState);
						if (
							Object.keys(userState?.userData).length === 0 &&
							userState?.userData?.constructor === Object
						)
							navigate("/login", { state: { from: window.location.pathname } });
						else setCollabReqModal(true);
					}}
				/>
			</div>
			<img
				className="absolute right-[0%] z-10 bottom-[-22%]"
				width={"304px"}
				src={girlListeningMusic}
			/>
			<img width={"100%"} src={spiral} className="absolute top-0 left-0" />
		</div>
	);
};

export default UploadMusicCard;

import React, { useEffect, useState } from "react";
import { getAllOrders } from "../../../../api/api_calls/user";
import OrderDetail from "./OrderDetail/OrderDetail";

const OrderHistory = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(0);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const fetchAllOrders = () => {
    getAllOrders(page, filter, limit)
      .then((res) => setOrders([...res?.data?.data]))
      .catch((error) => console.log(error));
  };

  return <div className="flex flex-col gap-6 max-h-[65vh] overflow-auto sleek-scrollbar">
    {orders.map(order => <OrderDetail order={order} />)}
  </div>;
};

export default OrderHistory;

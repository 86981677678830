import { Backdrop, Modal } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import DeleteIcon from "@mui/icons-material/Delete";
import GradientButton from "../../GradientButton/GradientButton";

const ConfirmDeleteModal = ({
  title,
  caption,
  open,
  onClose,
  children,
  handleYes,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="z-[3000] w-[30rem] flex flex-col"
      >
        <div className="bg-white text-center flex flex-col py-8 px-4">
          <div className="m-auto bg-[#FFF1F4] text-[44px] h-[80px] text-[#E6355C] w-[80px] flex rounded-[100%] items-center justify-center">
            <DeleteIcon fontSize="inherit" color="inherit" />
          </div>
          <h3 className="mt-6 font-semibold text-[20px] text-black">
            Are you sure you want to delete?
          </h3>
          <p className="text-[#858585] font-medium text-lg">
            This action cannot be undone.
          </p>
          <div className="m-auto mt-4 items-center flex gap-4">
            <button
              className="rounded-button !font-medium text-xl h-fit !w-max !rounded-[160px] !py-2 !px-6"
              onClick={onClose}
            >
              No
            </button>
            <GradientButton
              title="Yes"
              onClick={() => {
                handleYes();
                onClose();
              }}
            />
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};

export default ConfirmDeleteModal;

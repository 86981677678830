import { Box, Card, CardHeader, Skeleton } from "@mui/material";

export const ProductsSkeleton = () => {
  return (
    <>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
      <div>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={345}
          height={381}
        />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </div>
    </>
  );
};

export const ProductDetailsSkeleton = () => {
  return (
    <>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
      <Card sx={{ m: 2, outline: "none", border: "none", boxShadow: "none" }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
    </>
  );
};

export const SearchedProductsSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    <div className="flex gap-4 items-center ">
      <Skeleton variant="rounded" width="40px" height={50} />
      <Skeleton variant="text" width={"70%"} height={20} sx={{fontSize: "1rem"}} />
    </div>
    </div>
  );
};

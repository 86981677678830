import React, { useEffect, useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import GradientButton from "../../GradientButton/GradientButton";
import { useSelector } from "react-redux";
import {
  DateTimeRangePicker,
  LocalizationProvider,
  SingleInputTimeRangeField,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { requestEvent } from "../../../api/api_calls/calendar";
import { eventSchema } from "../../../validations/modal";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";

const AddBookingEvent = ({ onClose }) => {
  const { loading } = useSelector((state) => state.loader);
  const [formData, setFormData] = useState({
    eventDate: moment(),
    startTime: moment(),
    endTime: moment(),
  });
  const [formError, setFormError] = useState({});
  const [pastError, setPastError] = useState("");

  useEffect(() => {
    if (pastError) setTimeout(() => setPastError(""), [5000]);
  }, [pastError]);

  const handleFieldChange = (e) => {
    setFormData((curr) => ({ ...curr, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formError?.eventTime || formError?.eventDate) return;

    const eventStartDate = new Date(
      formData?.eventDate?.year(),
      formData?.eventDate?.month(),
      formData?.eventDate?.date(),
      formData?.startTime?.hour(),
      formData?.startTime?.minute()
    );
    const eventEndDate = new Date(
      formData?.eventDate?.year(),
      formData?.eventDate?.month(),
      formData?.eventDate?.date(),
      formData?.endTime?.hour(),
      formData?.endTime?.minute()
    );

    if (eventStartDate.getTime() < new Date().getTime()) {
      setPastError("You cannot request an event in the past");
      return;
    }

    if (eventStartDate.getTime() >= eventEndDate.getTime()) {
      setPastError("Please choose valid duration for your event");
      return;
    }

    const data = {
      eventName: formData?.eventName,
      eventAddress: formData?.eventAddress,
      eventDetails: formData?.eventDetails,
      eventStartDate: eventStartDate.toISOString(),
      eventEndDate: eventEndDate.toISOString(),
    };

    const { error } = eventSchema.validate(data);

    if (error) {
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    }

    requestEvent(data)
      .then((res) => {
        toast.success("Your Event has been sent for approval.");
        onClose();
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <form
      className="bg-white max-h-[80vh] overflow-auto py-[24px] px-[16px] flex flex-col gap-[16px]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Event Name</label>
        <CustomInput
          name="eventName"
          placeholder="Enter Event Name"
          value={formData?.eventName}
          onChange={handleFieldChange}
          error={formError?.eventName}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Event Detail</label>
        <textarea
          className={`h-[112px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2 ${
            formError?.eventDetails ? "border-red-400" : "border-[#E4E4E4]"
          }`}
          name="eventDetails"
          placeholder="Enter Event Details"
          value={formData?.eventDetails}
          onChange={handleFieldChange}
        />
        {formError?.eventDetails && (
          <p className="text-red-400 mt-2 font-normal text-sm">
            {formError?.eventDetails}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">
          Event Address
        </label>
        <CustomInput
          name="eventAddress"
          placeholder="Enter Address"
          value={formData?.eventAddress}
          onChange={handleFieldChange}
          error={formError?.eventAddress}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Event Date</label>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={formData?.eventDate}
            onChange={(e, validation) => {
              setFormData((formData) => ({ ...formData, eventDate: e }));

              if (!e?._isValid || validation?.validationError) {
                setFormError((curr) => ({
                  ...curr,
                  eventDate:
                    "Please choose valid date and start, end time for the event",
                }));
              } else {
                setFormError((curr) => ({ ...curr, eventDate: null }));
              }
            }}
            sx={{
              ".MuiOutlinedInput-input": {
                fontFamily: "Poppins",
              },
              ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                height: "58px",
                borderRadius: "8px",
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Event Time</label>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {/* <SingleInputTimeRangeField
            value={[formData?.startTime, formData?.endTime]}
            onChange={(e, validation) => {
              setFormData((formData) => ({
                ...formData,
                startTime: e?.[0],
                endTime: e?.[1],
              }));

              if (
                validation?.validationError?.[0] ||
                validation?.validationError?.[1]
              ) {
                setFormError((curr) => ({
                  ...curr,
                  eventTime:
                    "Please choose valid date and start, end time for the event",
                }));
              } else {
                setFormError((curr) => ({ ...curr, eventTime: null }));
              }
            }}
            InputProps={{
              sx: { borderRadius: "8px", outline: "none", height: "58px" },
            }}
          /> */}
          {/* <DateTimeRangePicker /> */}
          <div className="w-full flex gap-4">
            <div className="w-1/2 flex flex-col gap-2">
              <TimePicker
                value={formData?.startTime}
                onChange={(e, validation) => {
                  setFormData((formData) => ({
                    ...formData,
                    startTime: e,
                  }));
                  if (validation?.validationError) {
                    setFormError((curr) => ({
                      ...curr,
                      eventTime:
                        "Please choose valid date and start, end time for the event",
                    }));
                  } else {
                    setFormError((curr) => ({ ...curr, eventTime: null }));
                  }
                }}
                InputProps={{
                  sx: { borderRadius: "8px", outline: "none", height: "58px" },
                }}
              />
            </div>
            <div className="w-1/2 flex flex-col gap-2">
              <TimePicker
                value={formData?.endTime}
                onChange={(e, validation) => {
                  setFormData((formData) => ({
                    ...formData,
                    endTime: e,
                  }));
                  if (validation?.validationError) {
                    setFormError((curr) => ({
                      ...curr,
                      eventTime:
                        "Please choose valid date and start, end time for the event",
                    }));
                  } else {
                    setFormError((curr) => ({ ...curr, eventTime: null }));
                  }
                }}
                InputProps={{
                  sx: { borderRadius: "8px", outline: "none", height: "58px" },
                }}
              />
            </div>
          </div>
        </LocalizationProvider>
      </div>
      {(formError?.eventTime || formError?.eventDate) && (
        <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
          <InfoIcon fontSize="inherit" color="inherit" />
          <h3 className="font-medium text-sm">
            {formError?.eventTime || formError?.eventDate}
          </h3>
        </div>
      )}
      {pastError && (
        <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
          <InfoIcon fontSize="inherit" color="inherit" />
          <h3 className="font-medium text-sm">{pastError}</h3>
        </div>
      )}
      <p className="text-[#E6355C] font-normal text-sm">
        Note: Prior to publishing this event, our team will internally review
        the event details and we will contact you.
      </p>

      <GradientButton loading={loading} title="Send Request" type="submit" />
    </form>
  );
};

export default AddBookingEvent;

import axios from "axios";
let { REACT_APP_YOUTUBE_API } = process.env;

export const handleApiError = (err, setError) => {
	// console.log("ERROR RECEIVED: ", err);
	const error = JSON.parse(err?.message || {});
	// console.log("SETTING ERROR: ", { [error?.key]: error?.message });
	setError({ [error?.key]: error?.message });
};

export const formatTimestamp = (serverTimestamp) => {
	if (serverTimestamp) {
		const today = new Date();
		const date = serverTimestamp.toDate();

		const isToday =
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear();

		const isYesterday =
			date.getDate() === today.getDate() - 1 &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear();

		let finalString = "";

		if (isToday || isYesterday) {
			const options = {
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			};
			const timeString = date.toLocaleTimeString("en-US", options);
			finalString = `${isToday ? "Today" : "Yesterday"} at ${timeString}`;
		} else {
			const options = {
				weekday: "short",
				month: "long",
				day: "numeric",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			};
			finalString = date.toLocaleString("en-US", options);
		}

		return finalString;
	}
};

export const dateEqual = (date1, date2) => {
	if (!date1 || !date2) return false;
	const Date1 = date1.toDate();
	const Date2 = date2.toDate();

	// Extract year, month, and date from the Date objects
	const year1 = Date1.getFullYear();
	const month1 = Date1.getMonth();
	const day1 = Date1.getDate();

	const year2 = Date2.getFullYear();
	const month2 = Date2.getMonth();
	const day2 = Date2.getDate();

	if (year1 === year2 && month1 === month2 && day1 === day2) {
		return true;
	} else {
		return false;
	}

	// Compare year, month, and date
	// return year1 === year2 && month1 === month2 && day1 === day2;
};

export const printDateChat = (Date) => {
	if (!Date) return "";
	const date = Date.toDate();
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const formatTime = (time) => {
	if (time && !isNaN(time)) {
		const minutes = Math.floor(time / 60);
		const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(time % 60);
		const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formatMinutes}:${formatSeconds}`;
	}
	return "00:00";
};

export const isValidYouTubeLink = (url) => {
	// const regex =
	// 	/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|[^/]+\/.+\/|v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&][^?&]*)*$/;
	// const match = url.match(regex);
	// return match ? match[1] : null;

	if (!url.includes("youtu")) return false;

	if (url.includes(".be")) {
		const temp = url.split("/");
		const tempp = temp[temp.length - 1];
		const temppp = tempp.split("?");
		return temppp[0].length > 0;
	} else if (!url.includes("v=")) {
		return false;
	}

	return true;
};

export const extractYouTubeVideoId = (url) => {
	// Regular expression to match YouTube video ID in various URL formats, including mobile
	const regex =
		/^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

	// Match the URL against the regular expression
	const match = url.match(regex);

	// Extract the video ID from the matched groups
	if (match && match[1]) {
		return match[1];
	} else {
		// Return null if no match is found
		return null;
	}
};

export const getYouTubeVideoDuration = async (videoId) => {
	// console.log("YOUTUBE_API", REACT_APP_YOUTUBE_API);
	const apiKey = REACT_APP_YOUTUBE_API; // Replace with your YouTube API key
	// const videoId = videoUrl.split("v=")[1].split("&")[0]; // Extract video ID from URL

	const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;

	try {
		const response = await axios.get(apiUrl);
		const duration = response.data.items[0].contentDetails.duration;
		// console.log("Video Duration:", duration);
		return duration;
	} catch (error) {
		// console.error("Error fetching video duration:", error);
		throw error;
	}
};

export const getYouTubeThumbnailFile = async (videoId) => {
	const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`;

	try {
		const response = await fetch(thumbnailUrl);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const blob = await response.blob();
		const file = new File([blob], `${videoId}_thumbnail.jpg`, {
			type: "image/jpeg",
		});
		return file;
	} catch (error) {
		// console.error("Error fetching thumbnail:", error);
		throw error;
	}
};

// Usage
// const videoUrl = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
// getYouTubeVideoDuration(videoUrl).then((duration) => {
// 	if (duration) {
// 		console.log("Duration:", duration);
// 	} else {
// 		console.log("Failed to fetch duration.");
// 	}
// });

export const parseYouTubeDuration = (duration) => {
	if (!duration) return 0;
	const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
	const hours = (parseInt(match[1]) || 0) * 3600;
	const minutes = (parseInt(match[2]) || 0) * 60;
	const seconds = parseInt(match[3]) || 0;

	// Total duration in seconds
	const totalSeconds = hours + minutes + seconds;
	return totalSeconds;
};

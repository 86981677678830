import React from "react";

const PaymentFailed = () => {
  return (
    <div class="flex bg-white h-[60vh] justify-center">
      <div class="flex flex-col m-auto">
        <div class="message-box _success _failed">
          <i class="fa fa-times-circle" aria-hidden="true"></i>
          <h2> Your payment failed </h2>
          <p> Try again later </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;

import { React, useRef, useState } from "react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import styles from "./UploadMusic.module.css";
import {
	initialiseMultipart,
	finaliseMultipart,
} from "../../../../api/api_calls/music";
import {
	CircularProgress,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	extractYouTubeVideoId,
	isValidYouTubeLink,
	getYouTubeVideoDuration,
	getYouTubeThumbnailFile,
	parseYouTubeDuration,
} from "../../../../utils/helperFunctions";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import { toast } from "react-toastify";

const UploadMusic = ({ setCollabReqModal, setUploadedModal, setRender }) => {
	const audioInputRef = useRef();
	const imgInputRef = useRef();
	const videoInputRef = useRef();

	const [title, setTitle] = useState("");
	const [titleError, setTitleError] = useState(0);
	const [desc, setDesc] = useState("");
	const [descError, setDescError] = useState(false);
	const [playTime, setPlayTime] = useState(0);
	const [videoTime, setVideoTime] = useState(0);

	const [ytType, setytType] = useState(false);
	const [ytURL, setYTURL] = useState("");
	const [ytError, setYTError] = useState("");

	const [draggingAudio, setDraggingAudio] = useState(false);
	const [draggingImg, setDraggingImg] = useState(false);
	const [draggingVid, setDraggingVid] = useState(false);

	const [imgFile, setImgFile] = useState(null);
	const [imgError, setImgError] = useState("");
	const [imgLocal, setImgLocal] = useState("");
	const [audioFile, setAudioFile] = useState(null);
	const [audioError, setAudioError] = useState("");
	const [audioLocal, setAudioLocal] = useState("");

	const [vidBool, setVidBool] = useState(false);
	const [vidFile, setVidFile] = useState(null);
	const [vidError, setVidError] = useState("");
	const [vidLocal, setVidLocal] = useState("");
	const [videoThumbnailFile, setVideoThumbnailFile] = useState(null);
	const [videoThumbnail, setVideoThumbnail] = useState("");
	const [chunksUploaded, setChunksUploaded] = useState(0);
	const [uploadVideo, setUploadVideo] = useState(false);

	const [submitLoad, setSubmitLoad] = useState(false);

	const youtubeValid = () => {};

	// const generateVideoThumbnail = (file) => {
	// 	const reader = new FileReader();
	// 	reader.onload = function (event) {
	// 		console.log("Foo1");
	// 		const blobUrl = event.target.result;
	// 		console.log("Foo2", blobUrl);
	// 		const video = document.createElement("video");
	// 		console.log("Foo3", video);

	// 		video.addEventListener("loadedmetadata", function () {
	// 			// Get the duration of the video
	// 			const duration = video.duration;
	// 			console.log("Foo4", duration);

	// 			// Generate a random time within the duration
	// 			const randomTime = Math.random() * duration;
	// 			console.log("Foo5", randomTime);

	// 			video.currentTime = randomTime;
	// 		});

	// 		video.addEventListener("seeked", function () {
	// 			console.log("Foo6");
	// 			const canvas = document.createElement("canvas");
	// 			canvas.width = video.videoWidth;
	// 			canvas.height = video.videoHeight;
	// 			canvas
	// 				.getContext("2d")
	// 				.drawImage(video, 0, 0, canvas.width, canvas.height);

	// 			console.log("Foo7: ", canvas);

	// 			// Convert canvas image to blob
	// 			canvas.toBlob((blob) => {
	// 				const thumbnailFile = new File([blob], "thumbnail.png", {
	// 					type: "image/png",
	// 				});
	// 				const thumbnailDataUrl = URL.createObjectURL(thumbnailFile);

	// 				// Set the thumbnail file to state
	// 				setVideoThumbnailFile(thumbnailFile);
	// 				setVideoThumbnail(thumbnailDataUrl);
	// 				setVidError("");
	// 			}, "image/png");
	// 		});

	// 		video.src = blobUrl;
	// 	};

	// 	reader.readAsDataURL(file);
	// };

	const getPlayTime = (file) => {
		// won't need this if i simply set duration state when loading audio file in DOM

		const reader = new FileReader();
		reader.onload = function (event) {
			const blobUrl = event.target.result;
			const au = document.createElement("audio");

			au.addEventListener("loadedmetadata", function () {
				const duration = au.duration;
				setPlayTime(duration);
			});

			au.src = blobUrl;
		};

		reader.readAsDataURL(file);
	};

	// const getVideoPlayTime = (file) => {
	// 	console.log("file size: ", file.size);
	// 	// debugger;
	// 	const reader = new FileReader();
	// 	reader.onload = function (event) {
	// 		const blobUrl = event.target.result;
	// 		const video = document.createElement("video");

	// 		video.addEventListener("loadedmetadata", function () {
	// 			const duration = video.duration;
	// 			console.log("VIDEO DURATION: ", duration);
	// 			setVideoTime(duration);
	// 			debugger;
	// 		});

	// 		video.src = blobUrl;
	// 	};

	// 	reader.readAsDataURL(file);
	// };

	const getVideoPlayTime = (file) => {
		console.log("file size: ", file.size);

		// const reader = new FileReader();
		// reader.onload = function (event) {
		// 	const blobUrl = URL.createObjectURL(new Blob([event.target.result]));
		// 	const video = document.createElement("video");

		// 	video.addEventListener("loadedmetadata", function () {
		// 		try {
		// 			const duration = video.duration;
		// 			console.log("VIDEO DURATION: ", duration);
		// 			setVideoTime(duration);
		// 		} catch (error) {
		// 			console.error("Error retrieving video duration: ", error);
		// 		}
		// 	});

		// 	video.addEventListener("error", function () {
		// 		console.error("Failed to load video metadata.");
		// 	});

		// 	video.src = blobUrl;
		// };

		// reader.readAsDataURL(file);
		const fileReader = new FileReader();
		const video = document.createElement("video");

		fileReader.onload = function (event) {
			video.src = URL.createObjectURL(new Blob([event.target.result]));
			video.addEventListener("loadedmetadata", function () {
				const duration = video.duration;
				setVideoTime(duration);
				// document.getElementById(
				// 	"duration"
				// ).textContent = `Duration: ${formatDuration(duration)}`;
				const randomTime = Math.random() * duration;
				video.currentTime = randomTime;

				// URL.revokeObjectURL(video.src);
			});

			video.addEventListener("seeked", function () {
				console.log("Foo6");
				const canvas = document.createElement("canvas");
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				canvas
					.getContext("2d")
					.drawImage(video, 0, 0, canvas.width, canvas.height);

				console.log("Foo7: ", canvas);

				// Convert canvas image to blob
				canvas.toBlob((blob) => {
					const thumbnailFile = new File([blob], "thumbnail.png", {
						type: "image/png",
					});
					const thumbnailDataUrl = URL.createObjectURL(thumbnailFile);

					// Set the thumbnail file to state
					setVideoThumbnailFile(thumbnailFile);
					setVideoThumbnail(thumbnailDataUrl);
					setVidError("");
				}, "image/png");

				URL.revokeObjectURL(video.src);
			});
		};

		fileReader.readAsArrayBuffer(file);
	};

	async function uploadPart(fileChunk, presignedUrl, partNo) {
		const uploadResponse = await fetch(presignedUrl, {
			method: "PUT",
			body: fileChunk,
		});
		setChunksUploaded((curr) => curr + 1);
		return {
			ETag: uploadResponse.headers.get("ETag") || "",
			PartNumber: partNo,
		};
	}

	const submitValidation = () => {
		// console.log("SUBMIT VALIDATION");
		let temp = true;
		if (title === "") {
			setTitleError(1);
			setTimeout(() => {
				setTitleError(0);
			}, 3000);
			temp = false;
		} else if (title.length > 100) {
			setTitleError(2);
			setTimeout(() => {
				setTitleError(0);
			}, 3000);
			temp = false;
		}
		if (desc === "") {
			setDescError(1);
			setTimeout(() => {
				setDescError(0);
			}, 3000);
			temp = false;
		} else if (desc.length > 200) {
			setDescError(2);
			setTimeout(() => {
				setDescError(0);
			}, 3000);
			temp = false;
		}
		if (!ytType) {
			if (!playTime) {
				// console.log("SET AUDIO ERROR TRUE");
				setAudioError("Mandatory field");
				setTimeout(() => {
					setAudioError("");
				}, 3000);
				temp = false;
			}
			if (!imgFile) {
				setImgError("Mandatory field.");
				setTimeout(() => {
					setImgError("");
				}, 3000);
				temp = false;
			}
			if (vidBool) {
				if (!videoThumbnailFile) {
					setVidError("Mandatory field");
					setTimeout(() => {
						setVidError("");
					}, 3000);
					temp = false;
				} else {
					// if (vidFile.size / 1048576 < 6) {
					// 	setVidError("Minimum size of 6MB required");
					// 	setTimeout(() => {
					// 		setVidError(0);
					// 	}, 3000);
					// 	temp = false;
					// }
					// if (!videoTime) {
					// 	setVidError(2);
					// 	setTimeout(() => {
					// 		setVidError(0);
					// 	}, 3000);
					// 	temp = false;
					// }
				}
			}
		} else {
			if (ytURL === "") {
				setYTError("Mandatory Field");
				setTimeout(() => {
					setYTError("");
				}, 3000);
				temp = false;
			} else if (!isValidYouTubeLink(ytURL)) {
				setYTError("Invalid link. Please enter a valid YouTube link");
				setTimeout(() => {
					setYTError("");
				}, 3000);
				temp = false;
			}
		}
		return temp;
	};

	const submitFunction = async () => {
		const temp = submitValidation();
		if (!temp) return;
		setSubmitLoad(true);

		const newFileName = vidFile?.name?.replace(/\s+/g, "");
		const renamedFile = vidFile
			? new File([vidFile], newFileName, {
					type: vidFile?.type,
			  })
			: null;

		// parts for multipart
		let parts = 0,
			chunkSize = 0,
			vidSize = 0;
		if (renamedFile) {
			vidSize = renamedFile?.size;
			parts = Math.min(Math.ceil(renamedFile.size / 8000000), 20);
			chunkSize = renamedFile.size / parts;
		}

		let intialized = null;

		try {
			// initialize multipart
			if (vidFile) {
				intialized = await initialiseMultipart({
					name: renamedFile?.name,
					parts,
					videoSize: vidSize,
				});
			}
			// const intialized = vidFile
			// 	? await initialiseMultipart({
			// 			name: renamedFile?.name,
			// 			parts,
			// 			videoSize: vidSize,
			// 	  })
			// 	: null;

			// finalize multipart
			// populate formData with details other than the video multipart
			const formData = new FormData();
			formData.append("title", title);
			formData.append("description", desc);
			formData.append("isYoutube", ytType ? "true" : "false");
			if (ytType) {
				formData.append("isVideoExist", "false");
				formData.append("youtubeUrl", ytURL);
				const tempID = extractYouTubeVideoId(ytURL);
				formData.append("youtubeId", tempID);
				const playTemp = await getYouTubeVideoDuration(tempID);
				formData.append("playTime", parseYouTubeDuration(playTemp));
				// const tempFile = await getYouTubeThumbnailFile(tempID);
				formData.append("youtubeThumbnail", null);
			} else {
				formData.append("musicThumbnail", imgFile);
				formData.append("playTime", playTime);
				formData.append("music", audioFile);
				if (renamedFile) {
					formData.append("isVideoExist", "true");
					formData.append("videoThumbnail", videoThumbnailFile);
					formData.append("name", renamedFile?.name);
				} else {
					formData.append("isVideoExist", "false");
					formData.append("youtubeId", "false");
				}
			}

			// link chunks with provided URLs and upload to finalizeMultipart
			const uploadsArray = [];
			if (intialized) {
				if (renamedFile) {
					for (let i = 0; i < parts; i++) {
						const start = i * chunkSize;
						const end = Math.min(start + chunkSize, renamedFile?.size);
						const blob = renamedFile?.slice(start, end);
						uploadsArray.push(
							uploadPart(blob, intialized?.data?.parts?.[i]?.signedUrl, i + 1)
						);
					}
				}
			}

			let uploadResponses = await Promise.all(uploadsArray);
			if (intialized) {
				// console.log("UPLOAD RESPONSES", uploadResponses);
				formData.append("fileId", intialized?.data?.fileId);
				formData.append("fileKey", intialized?.data?.fileKey);

				formData.append("parts", JSON.stringify(uploadResponses));
			}

			// for (let entry of formData.entries()) {
			// 	console.log(entry[0] + ": " + entry[1]);
			// }
			// console.log(
			// 	"----------------------------------------------------------------------------"
			// );
			// debugger;

			await finaliseMultipart(formData);
			setUploadedModal(true);
			setRender(true);
		} catch (error) {
			toast.error(error?.message);
		} finally {
			setSubmitLoad(false);
			setCollabReqModal(false);
		}
	};

	const handleDragOver = (e, type) => {
		e.preventDefault();
		if (type === "audioFile") setDraggingAudio(true);
		else if (type === "imgFile") setDraggingImg(true);
		else if (type === "videoFile") setDraggingVid(true);
	};

	const handleDragLeave = (type) => {
		if (type === "audioFile") setDraggingAudio(false);
		else if (type === "imgFile") setDraggingImg(false);
		else if (type === "videoFile") setDraggingVid(false);
	};

	const handleDrop = (e, type) => {
		e.preventDefault();

		if (type === "audioFile") setDraggingAudio(false);
		else if (type === "imgFile") setDraggingImg(false);
		else if (type === "videoFile") setDraggingVid(false);
		const file = e.dataTransfer.files[0];
		handleFileUpload(file, type);
	};

	const handleFileUpload = (file, fileType) => {
		// console.log(`TRYING TO Upload ${fileType} file:`, file);

		if (fileType === "videoFile" && !file.type.includes("video/")) {
			setVidError("Please upload a video file");
			setTimeout(() => {
				setVidError("");
			}, 5000);
			return;
		} else if (fileType === "audioFile" && !file.type.includes("audio/")) {
			setAudioError("Please upload an audio file");
			setTimeout(() => {
				setAudioError("");
			}, 5000);
		} else if (fileType === "imgFile" && !file.type.includes("image/")) {
			setImgError("Please upload an image file");
			setTimeout(() => {
				setImgError("");
			}, 5000);
		} else {
			// Handle file upload logic here
			// console.log("OBJECT FILE: ", file);
			const temp = URL.createObjectURL(file);
			// console.log(`OBJECT URL: `, temp);

			// Determine which state variable to update based on fileType
			if (fileType === "audioFile") {
				getPlayTime(file);
				setAudioFile(file);
				setAudioLocal(temp);
				setAudioError("");
			} else if (fileType === "videoFile") {
				getVideoPlayTime(file);
				// generateVideoThumbnail(file);
				setVidFile(file);
				setVidLocal(temp);
			} else if (fileType === "imgFile") {
				setImgFile(file);
				setImgLocal(temp);
				setImgError("");
			}
		}
		// if (
		// 	(file.type.includes("video/") && fileType === "videoFile") ||
		// 	(file.type.includes("image/") && fileType === "imgFile") ||
		// 	(file.type.includes("audio/") && fileType === "audioFile")
		// ) {
		// 	// Handle file upload logic here
		// 	// console.log("OBJECT FILE: ", file);
		// 	const temp = URL.createObjectURL(file);
		// 	// console.log(`OBJECT URL: `, temp);

		// 	// Determine which state variable to update based on fileType
		// 	if (fileType === "audioFile") {
		// 		getPlayTime(file);
		// 		setAudioFile(file);
		// 		setAudioLocal(temp);
		// 		setAudioError(false);
		// 	} else if (fileType === "videoFile") {
		// 		getVideoPlayTime(file);
		// 		generateVideoThumbnail(file);
		// 		setVidFile(file);
		// 		setVidLocal(temp);
		// 	} else if (fileType === "imgFile") {
		// 		setImgFile(file);
		// 		setImgLocal(temp);
		// 		setImgError(false);
		// 	}
		// }
	};

	return (
		<div className="flex flex-col gap-y-4">
			<div className="flex flex-col gap-y-2">
				<span className="block text-base font-medium">
					Title{titleError !== 0 ? "*" : ""}
				</span>
				<input
					placeholder="Enter the title of this track"
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
					}}
					className={`w-full rounded-lg border border-[#E4E4E4] focus:border-[black] p-2 ${
						titleError !== 0 && styles.error
					}`}
				/>
				{titleError !== 0 && (
					<span
						className="block text-xs font-medium"
						style={{ color: "#ff0037" }}
					>
						{titleError === 1 && "Mandatory field"}
						{titleError === 2 && "Title may only be up to 100 characters"}
					</span>
				)}
				{/* <CustomInput value={title} onChange={(e) => setTitle(e.target.value)} /> */}
			</div>

			<div className="flex flex-col gap-y-2">
				<span className="block text-base font-medium">
					Description{descError !== 0 ? "*" : ""}
				</span>
				<textArea
					rows={4}
					placeholder="Type here"
					maxLength={1000}
					className={`${styles.textArea} ${descError && styles.error}`}
					value={desc}
					onChange={(e) => setDesc(e.target.value)}
				/>
				{descError !== 0 && (
					<span
						className="block text-xs font-medium"
						style={{ color: "#ff0037" }}
					>
						{descError === 1 && "Mandatory field"}
						{descError === 2 && "Description may only be up to 200 characters"}
					</span>
				)}
				{/* <CustomInput value={desc} onChange={(e) => setDesc(e.target.value)} /> */}
			</div>

			<div className="w-full flex justify-center">
				<ToggleButtonGroup
					exclusive
					value={ytType}
					onChange={(e, newVal) => setytType(newVal)}
				>
					<ToggleButton value={false}>Local Media</ToggleButton>
					<ToggleButton value={true}>YouTube</ToggleButton>
				</ToggleButtonGroup>
			</div>

			{ytType ? (
				<>
					YouTube URL
					<input
						placeholder="Enter YouTube URL"
						value={ytURL}
						onChange={(e) => setYTURL(e.target.value)}
						className={`w-full rounded-lg border border-[#E4E4E4] focus:border-[black] p-2 ${
							ytError && styles.error
						}`}
					/>
					{ytError !== "" && (
						<span
							className="block text-xs font-medium"
							style={{ color: "#ff0037" }}
						>
							{ytError}
						</span>
					)}
				</>
			) : (
				<>
					<div className="flex gap-x-2">
						<div
							className={`${styles.file_uploader} ${
								draggingAudio ? styles.dragging : ""
							}`}
							onDragOver={(e) => handleDragOver(e, "audioFile")}
							onDragLeave={() => handleDragLeave("audioFile")}
							onDrop={(e) => handleDrop(e, "audioFile")}
							onClick={() => audioInputRef.current.click()}
						>
							<input
								type="file"
								accept=".mp3, .wav"
								style={{ display: "none" }}
								ref={audioInputRef}
								onChange={(e) => {
									handleFileUpload(e.target.files[0], "audioFile");
									e.target.value = "";
								}}
							/>
							<div className="h-full w-full flex flex-col justify-center items-center">
								<svg
									width="25"
									height="24"
									viewBox="0 0 25 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
										fill="#E6355C"
									/>
									<path
										d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
										fill="#E6355C"
									/>
								</svg>

								<p className="text-xs font-medium text-center">
									{audioFile
										? `${audioFile.name} | ${playTime}`
										: draggingAudio
										? "Drop Here"
										: `Drag or Upload your Music${
												audioError !== "" ? "*" : ""
										  }`}
								</p>
							</div>
							{audioError !== "" && (
								<span
									className="block text-xs font-medium"
									style={{ color: "#ff0037" }}
								>
									{audioError}
								</span>
							)}
						</div>
						<div
							className={`${styles.file_uploader} ${
								draggingImg ? styles.dragging : ""
							}`}
							onDragOver={(e) => handleDragOver(e, "imgFile")}
							onDragLeave={() => handleDragLeave("imgFile")}
							onDrop={(e) => handleDrop(e, "imgFile")}
							onClick={() => imgInputRef.current.click()}
						>
							<input
								type="file"
								accept=".jpg, .jpeg, .png"
								style={{ display: "none" }}
								ref={imgInputRef}
								onChange={(e) => {
									handleFileUpload(e.target.files[0], "imgFile");
									e.target.value = "";
								}}
							/>
							<div className="h-full w-full flex flex-col justify-center items-center">
								{imgFile ? (
									<div className="h-full w-full flex justify-center items-center p-4">
										<img
											src={imgLocal}
											style={{
												maxWidth: "100%",
												height: "100%",
												objectFit: "contain",
											}}
										/>
									</div>
								) : (
									<>
										<svg
											width="25"
											height="24"
											viewBox="0 0 25 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
												fill="#E6355C"
											/>
											<path
												d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
												fill="#E6355C"
											/>
										</svg>

										<p className="text-xs font-medium text-center">
											{draggingImg
												? "Drop Here"
												: `Drag or Upload your Thumbnail Image${
														imgError !== "" ? "*" : ""
												  }`}
										</p>
									</>
								)}
							</div>
							{imgError !== "" && (
								<span
									className="block text-xs font-medium"
									style={{ color: "#ff0037" }}
								>
									{imgError}
								</span>
							)}
						</div>
					</div>
					<div className="w-full flex gap-x-2 items-center">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ cursor: "pointer" }}
							onClick={() => setVidBool((curr) => !curr)}
						>
							{vidBool ? (
								<>
									<rect
										x="0.75"
										y="0.75"
										width="14.5"
										height="14.5"
										rx="1.25"
										fill="#E6355C"
										stroke="#E6355C"
										stroke-width="1.5"
									/>
									<path
										d="M3 7.86364L5.86364 10.7273L13.5 5"
										stroke="white"
										stroke-width="1.90909"
										stroke-linecap="round"
									/>
								</>
							) : (
								<>
									<rect
										x="0.75"
										y="0.75"
										width="14.5"
										height="14.5"
										rx="1.25"
										stroke="#060709"
										stroke-width="1.5"
									/>
								</>
							)}
						</svg>
						<p className="text-base font-medium">
							Want to Upload a video with this track?
						</p>
					</div>
					{vidBool ? (
						<div
							className={`${styles.file_uploader} ${
								draggingVid ? styles.dragging : ""
							}`}
							onDragOver={(e) => handleDragOver(e, "videoFile")}
							onDragLeave={() => handleDragLeave("videoFile")}
							onDrop={(e) => handleDrop(e, "videoFile")}
							onClick={() => videoInputRef.current.click()}
						>
							<input
								type="file"
								accept=".mp4, .avi, .mov"
								style={{ display: "none" }}
								ref={videoInputRef}
								onChange={(e) => {
									handleFileUpload(e.target.files[0], "videoFile");
									e.target.value = "";
								}}
							/>
							<div className="h-full w-full flex flex-col justify-center items-center">
								{vidFile ? (
									<div className="w-full h-full flex flex-col gap-y-2 p-4 justify-center items-center">
										{videoThumbnail ? (
											<img
												src={videoThumbnail}
												style={{
													maxHeight: "100%",
													objectFit: "contain",
												}}
											/>
										) : (
											<CircularProgress />
										)}
										<p className="text-xs font-medium text-center">
											{vidFile.name} | {videoTime}
										</p>
									</div>
								) : (
									<>
										<svg
											width="25"
											height="24"
											viewBox="0 0 25 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
												fill="#E6355C"
											/>
											<path
												d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
												fill="#E6355C"
											/>
										</svg>
										<p className="text-xs font-medium text-center">
											{draggingVid
												? "Drop Here"
												: `Drag or Upload your Video${
														vidError !== "" ? "*" : ""
												  }`}
										</p>
									</>
								)}
							</div>
							{vidError !== 0 && (
								<span
									className="block text-xs font-medium"
									style={{ color: "#ff0037" }}
								>
									{vidError}
								</span>
							)}
						</div>
					) : (
						<></>
					)}
				</>
			)}
			<GradientButton
				title="Upload Music"
				onClick={submitFunction}
				loading={submitLoad}
			/>
		</div>
	);
};

export default UploadMusic;

import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import UniqueDesignBanner from "./UniqueDesignBanner/UniqueDesignBanner";
import ProductsSection from "./ProductsSection/ProductsSection";
import { getAllProducts } from "../../../api/api_calls/shop";
import { useLocation, useOutletContext } from "react-router-dom";
import { Drawer } from "@mui/material";
import AddToCartDrawer from "./AddToCartDrawer/AddToCartDrawer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Shop = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const { resolutionType } = useSelector((state) => state.resolution);
	const [artTypes] = useOutletContext();

	// console.log(artTypes, "artTypes");

	const [products, setProducts] = useState(null);
	const [open, setOpen] = useState(false);
	const [productDetails, setProductDetails] = useState({});
	const [sorting, setSorting] = useState("newest");
	const [artName, setArtName] = useState("");

	const fetchAllProducts = () => {
		getAllProducts(state?.artType || artTypes?.[0]?._id, sorting)
			.then((res) => setProducts(res?.data?.data))
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		fetchAllProducts();
		// .finally(() => dispatch(loaderActions.toggleLoading(false)));
	}, [state, sorting, artTypes]);

	return (
		<div className="flex flex-col bg-white">
			<div
				className={`flex border-b-[1px] border-[#E4E4E4]  items-center justify-between ${
					resolutionType.type === "mobile"
						? "flex-col py-4 px-4"
						: "px-[12.25%] py-[24px]"
				}`}
			>
				<h3 className="font-medium text-lg text-black">
					{state?.artName || artTypes?.[0]?.artTypeName}
				</h3>
				<div
					className={`flex flex-col gap-[7px] ${
						resolutionType.type === "mobile" && "w-full"
					}`}
				>
					<h3 className="font-medium text-lg text-black">Sort By</h3>
					<Select
						id="demo-select-small"
						// value={age}
						sx={{
							width: resolutionType.type === "mobile" ? "100%" : "247px",
							height: "43px",
							backgroundColor: "#E4E4E4",
							color: "#060709",
							fontWeight: "400",
							fontSize: "18px",
							border: "none",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
							"&:hover .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
						}}
						value={sorting}
						// onChange={handleChange}
						onChange={(e) => setSorting(e.target.value)}
					>
						<MenuItem value="a-z">A-Z</MenuItem>
						<MenuItem value="z-a">Z-A</MenuItem>
						<MenuItem value="newest">Latest first</MenuItem>
						<MenuItem value="oldest">Oldest first</MenuItem>
						<MenuItem value="price-low-high">Price: Low to High</MenuItem>
						<MenuItem value="price-high-low">Price: High to Low</MenuItem>
					</Select>
				</div>
			</div>
			{products?.length === 0 ? (
				<div className="px-[12.25%] py-[120px] text-center">
					<h3 className="font-semibold text-xl text-[##858585]">
						Products of this category are not available at the moment
					</h3>
				</div>
			) : (
				<ProductsSection
					products={products}
					openDrawer={() => setOpen(true)}
					setProduct={(data) => setProductDetails(data)}
				/>
			)}
			<UniqueDesignBanner />
			<Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
				<AddToCartDrawer
					product={productDetails}
					close={() => setOpen(false)}
				/>
			</Drawer>
		</div>
	);
};

export default Shop;

import React, { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import GradientButton from "../../GradientButton/GradientButton";
import { changePasswordSchema } from "../../../validations/modal";
import { changePassword } from "../../../api/api_calls/user";
import { useSelector } from "react-redux";

const ChangePassword = ({ onClose }) => {
  const {loading} = useSelector(state => state?.loader)

  const [currPassword, setCurrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formError, setFormError] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const { error } = changePasswordSchema.validate({
      currPassword,
      newPassword,
    });

    if (error) {
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    }

    if (newPassword !== confirmPassword) {
      setFormError({
        newPassword: "Passwords do not match!",
        confirmPassword: "Passwords do not match!",
      });
      return;
    }

    changePassword({ oldPassword: currPassword, newPassword })
      .then((res) => onClose())
      .catch((error) => console.log(error));
  };

  return (
    <form
      className="bg-white py-[24px] px-[16px] flex flex-col gap-[16px]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-[8px]">
          <label className="font-medium text-black text-base">
            Current Password
          </label>
          <CustomInput
            type="password"
            placeholder="Current Password"
            value={currPassword}
            onChange={(e) => setCurrPassword(e.target.value)}
            error={formError?.currPassword}
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <label className="font-medium text-black text-base">
            New Password
          </label>
          <CustomInput
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={formError?.newPassword}
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <label className="font-medium text-black text-base">
            Confirm New Password
          </label>
          <CustomInput
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={formError?.confirmPassword}
          />
        </div>
      </div>
      <GradientButton type="submit" title="Save" loading={loading} />
    </form>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import TopSellers from "./TopSellers/TopSellers";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart, getProductDetails } from "../../../api/api_calls/shop";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ImageList,
  ImageListItem,
  Rating,
} from "@mui/material";
import GradientButton from "../../../components/GradientButton/GradientButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomModal from "../../../components/CustomModal/CustomModal";
import WriteReview from "../../../components/ModalComponents/WriteReview/WriteReview";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../../../redux/reducers/cart";
import SizeChart from "../../../components/ModalComponents/SizeChart/SizeChart";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { ProductDetailsSkeleton } from "../../../skeletons/skeleton";
import InfoIcon from "@mui/icons-material/Info";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import moment from "moment/moment";

// pageInfo?.productDetails?.productImages?

const ProductDetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  const { userData } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const { resolutionType } = useSelector((state) => state.resolution);

  const [pageInfo, setPageInfo] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState("");
  const [sizeModal, setSizeModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [reviews, setReviews] = useState({});

  useEffect(() => {
    if (sizeError) setTimeout(() => setSizeError(false), 5000);
  }, [sizeError]);

  useEffect(() => {
    getProductDetails(state?.productId)
      .then((res) => {
        setPageInfo({ ...res?.data?.data });
        setReviews({
          totalNumberOfReviews:
            res?.data?.data?.productDetails?.totalNumberOfReviews,
          reviews: res?.data?.data?.productDetails?.reviews,
          averageRating: res?.data?.data?.productDetails?.averageRating,
        });
      })
      .catch((err) => console.log(err));
  }, [state]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!userData?._id) {
      navigate("/login");
      return;
    }

    if (!size) {
      setSizeError(true);
      return;
    }

    const formData = new FormData();

    formData.append("productId", pageInfo?.productDetails?._id);
    formData.append("productName", pageInfo?.productDetails?.productName);
    formData.append("price", pageInfo?.productDetails?.basePrice);
    formData.append("qty", quantity);
    formData.append("size", size);

    addToCart(formData)
      .then(() => {
        dispatch(fetchCart());
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <BreadCrumb />
      <div
        className={`flex flex-col bg-white ${
          resolutionType.type === "mobile"
            ? "py-16 px-4"
            : "px-[12.25%] py-[64px]"
        }`}
      >
        <div
          className={`flex gap-[40px] justify-between ${
            resolutionType.type === "mobile" ? "flex-col" : ""
          }`}
        >
          <div
            className={`flex flex-col ${
              resolutionType.type === "mobile"
                ? "w-full"
                : "w-[680px]  gap-[10px]"
            }`}
          >
            <img
              src={pageInfo?.productDetails?.productImages?.[0]}
              className={`object-cover unloaded-img rounded-lg ${
                resolutionType.type === "mobile"
                  ? "w-full h-[50vh]"
                  : "h-[680px] w-[680px] "
              }`}
            />
            {resolutionType.type !== "mobile" && (
              <div className="flex gap-2 overflow-auto sleek-scrollbar">
                {pageInfo?.productDetails?.productVideo && (
                  <video
                    height={340}
                    width={336}
                    src={pageInfo?.productDetails?.productVideo}
                    autoPlay
                  />
                )}
                {pageInfo?.productDetails?.productImages.map((image, ind) => {
                  if (ind > 0)
                    return (
                      <img
                        src={image}
                        className="h-[340px] unloaded-img w-[336px] object-cover rounded-lg"
                      />
                    );
                })}
              </div>
            )}
          </div>
          <div
            className={`flex flex-col ${
              resolutionType.type === "mobile" ? "w-full" : "w-[40%]"
            }`}
          >
            {loader?.loading ? (
              <ProductDetailsSkeleton />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] pb-[20px]">
                    <h2 className="text-[#060709] font-semibold text-[30px]">
                      {pageInfo?.productDetails?.productName}
                    </h2>
                    <h2 className="text-[#E6355C] font-bold text-[30px]">
                      ${pageInfo?.productDetails?.basePrice}
                    </h2>
                  </div>
                  <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
                    <div className="flex items-center gap-4">
                      <h2 className="text-[#060709] font-medium text-[22px]">
                        Size
                      </h2>
                      {sizeError && (
                        <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
                          <InfoIcon fontSize="inherit" color="inherit" />
                          <h3 className="font-medium text-sm">
                            Please select any of the avaliable sizes first!
                          </h3>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-[24px] items-end">
                      {["S", "M", "L", "XL"]?.map((item) => (
                        <div
                          className={`${
                            size === item ? "bg-[#060709] text-white" : ""
                          } h-[50px] w-[50px] cursor-pointer text-[#858585] hover:bg-[#060709] hover:text-white rounded-[8px] border-[1px] border-[#858585] flex justify-center items-center`}
                          onClick={() => setSize(item)}
                        >
                          {item}
                        </div>
                      ))}
                      <p
                        className="flex items-center font-medium text-lg text-[#6964B4] gap-[8px] cursor-pointer"
                        onClick={() => setSizeModal(true)}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.4702 19.5V5.5C21.4702 3.5 20.4702 2.5 18.4702 2.5H14.4702C12.4702 2.5 11.4702 3.5 11.4702 5.5V19.5C11.4702 21.5 12.4702 22.5 14.4702 22.5H18.4702C20.4702 22.5 21.4702 21.5 21.4702 19.5Z"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11.4702 6.5H16.4702"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11.4702 18.5H15.4702"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11.4702 14.45L16.4702 14.5"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M11.4702 10.5H14.4702"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M5.48978 2.5C3.85978 2.5 2.52979 3.83 2.52979 5.45V18.41C2.52979 18.86 2.71979 19.54 2.94979 19.93L3.76979 21.29C4.70979 22.86 6.25979 22.86 7.19979 21.29L8.01979 19.93C8.24979 19.54 8.43979 18.86 8.43979 18.41V5.45C8.43979 3.83 7.10978 2.5 5.48978 2.5Z"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M8.43979 7.5H2.52979"
                            stroke="#6964B4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                        Size Guide
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
                    <h2 className="text-[#060709] font-medium text-[22px]">
                      Quantity
                    </h2>
                    <div className="flex items-center py-[4px] border-[1px] w-fit rounded-[8px] border-[#060709] px-[16px] gap-[24px]">
                      <button
                        type="button"
                        className={`${
                          quantity === 1 ? "text-[#E4E4E4]" : "text-[#060709]"
                        } cursor-pointer text-lg font-medium`}
                        onClick={() => {
                          if (quantity > 1) setQuantity(quantity - 1);
                        }}
                      >
                        -
                      </button>
                      <h3 className="text-[#060709] text-lg font-medium">
                        {quantity}
                      </h3>
                      <button
                        type="button"
                        className="text-[#060709] cursor-pointer text-lg font-medium"
                        onClick={() => setQuantity(quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[16px] border-b-[1px] border-[#E4E4E4] py-[20px]">
                    <GradientButton
                      type="submit"
                      title="Add to Cart"
                      style={{ width: "100%" }}
                    />
                  </div>
                </form>
                {/* <div className="flex flex-col gap-[16px] py-[32px]">
                  <p className="text-[#060709] font-medium text-[22px]">
                    Share
                  </p>
                  <div className="flex gap-[32px]">
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_107_2934)">
                        <rect
                          width="34.2857"
                          height="34.2857"
                          rx="17.1429"
                          fill="#858585"
                        />
                        <path
                          d="M23.8156 22.0982L24.5756 17.1428H19.8212V13.9286C19.8212 12.5725 20.4841 11.25 22.6136 11.25H24.7765V7.03123C24.7765 7.03123 22.8145 6.69641 20.9395 6.69641C17.022 6.69641 14.464 9.0703 14.464 13.3661V17.1428H10.1113V22.0982H14.464V34.0781C15.3379 34.2154 16.2319 34.2857 17.1426 34.2857C18.0533 34.2857 18.9473 34.2154 19.8212 34.0781V22.0982H23.8156Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_107_2934">
                          <rect
                            width="34.2857"
                            height="34.2857"
                            rx="17.1429"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.286133"
                        width="34.2857"
                        height="34.2857"
                        rx="17.1429"
                        fill="#858585"
                      />
                      <path
                        d="M14.2918 26.1857C21.8946 26.1857 26.0518 19.8857 26.0518 14.4257C26.0518 14.2457 26.0518 14.0657 26.0432 13.8943C26.8489 13.3114 27.5518 12.5828 28.1089 11.7514C27.3718 12.0771 26.5746 12.3 25.7346 12.4028C26.5918 11.8886 27.2432 11.0828 27.5518 10.1143C26.7546 10.5857 25.8718 10.9286 24.9289 11.1171C24.1746 10.3114 23.1032 9.81427 21.9118 9.81427C19.6318 9.81427 17.7803 11.6657 17.7803 13.9457C17.7803 14.2714 17.8146 14.5886 17.8918 14.8886C14.4546 14.7171 11.4118 13.0714 9.37175 10.5686C9.02032 11.1771 8.81461 11.8886 8.81461 12.6428C8.81461 14.0743 9.54318 15.3428 10.6575 16.08C9.98032 16.0628 9.34604 15.8743 8.7889 15.5657C8.7889 15.5828 8.7889 15.6 8.7889 15.6171C8.7889 17.6228 10.2118 19.2857 12.106 19.6714C11.7632 19.7657 11.3946 19.8171 11.0175 19.8171C10.7518 19.8171 10.4946 19.7914 10.2375 19.74C10.7603 21.3857 12.286 22.5771 14.0946 22.6114C12.6803 23.7171 10.8975 24.3771 8.96032 24.3771C8.62604 24.3771 8.30032 24.36 7.97461 24.3171C9.78318 25.5 11.9603 26.1857 14.2918 26.1857Z"
                        fill="white"
                      />
                    </svg>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.571289"
                        width="34.2857"
                        height="34.2857"
                        rx="17.1429"
                        fill="#858585"
                      />
                      <path
                        d="M17.7092 6.85712C12.0227 6.85712 7.42773 11.4605 7.42773 17.1386C7.42773 21.4966 10.1356 25.2199 13.9605 26.7177C13.8674 25.9054 13.7913 24.653 13.9943 23.7644C14.1805 22.9605 15.196 18.6533 15.196 18.6533C15.196 18.6533 14.8913 18.0356 14.8913 17.1301C14.8913 15.7 15.7206 14.6338 16.753 14.6338C17.6331 14.6338 18.0562 15.2939 18.0562 16.0808C18.0562 16.9609 17.4977 18.281 17.2015 19.508C16.9561 20.5319 17.7177 21.3697 18.7247 21.3697C20.5525 21.3697 21.9572 19.4403 21.9572 16.6647C21.9572 14.2022 20.1886 12.4844 17.6584 12.4844C14.7306 12.4844 13.0127 14.6761 13.0127 16.944C13.0127 17.824 13.3512 18.7718 13.7743 19.288C13.859 19.3895 13.8674 19.4826 13.842 19.5841C13.7659 19.9057 13.5882 20.6081 13.5543 20.7519C13.512 20.9381 13.402 20.9804 13.2074 20.8873C11.938 20.278 11.1426 18.3995 11.1426 16.8932C11.1426 13.6522 13.4951 10.6735 17.9377 10.6735C21.5003 10.6735 24.2758 13.2122 24.2758 16.6139C24.2758 20.1596 22.0418 23.0113 18.9447 23.0113C17.9038 23.0113 16.9222 22.4697 16.5922 21.8266C16.5922 21.8266 16.076 23.7898 15.9491 24.2722C15.7206 25.1692 15.0944 26.2862 14.6713 26.9716C15.636 27.2678 16.6514 27.4285 17.7177 27.4285C23.3958 27.4285 27.9992 22.8252 27.9992 17.1471C27.9907 11.4605 23.3873 6.85712 17.7092 6.85712Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div> */}
                <div className="flex flex-col gap-[32px]">
                  <Accordion sx={{ boxShadow: "none", border: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="inherit" />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        fontWeight: 500,
                        fontSize: "22px",
                        color: "#060709",
                        padding: "0px",
                      }}
                    >
                      Product Description
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        color: "#858585",
                        fontWeight: 400,
                        fontSize: "18px",
                        padding: "0px",
                      }}
                    >
                      {pageInfo?.productDetails?.productDescription}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="inherit" />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        fontWeight: 500,
                        fontSize: "22px",
                        color: "#060709",
                        padding: "0px",
                      }}
                    >
                      Reviews
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        color: "#858585",
                        fontWeight: 400,
                        fontSize: "18px",
                        padding: "0px",
                      }}
                    >
                      <div className="flex flex-col gap-[16px] max-h-[500px] overflow-auto hidden-scroll">
                        <div className="flex items-center justify-between bg-[#FFF1F4] rounded-[8px] py-[22px] px-[16px]">
                          <div className="flex gap-[8px] items-center">
                            <Rating
                              name="read-only"
                              icon={<StarRoundedIcon fontSize="inherit" />}
                              emptyIcon={
                                <StarOutlineRoundedIcon fontSize="inherit" />
                              }
                              precision={0.5}
                              value={reviews?.averageRating || 0}
                              readOnly
                            />
                            <p className="text-lg font-normal text-[#060709]">
                              {reviews?.totalNumberOfReviews || 0} Reviews
                            </p>
                          </div>
                          <GradientButton
                            title="Write Review"
                            onClick={() => setReviewModal(true)}
                          />
                        </div>
                        {reviews?.reviews?.map((review) => (
                          <div className="flex flex-col gap-[16px] py-[20px] border-b-[1px] border-[#E4E4E4]">
                            <Rating
                              icon={<StarRoundedIcon fontSize="inherit" />}
                              emptyIcon={
                                <StarOutlineRoundedIcon fontSize="inherit" />
                              }
                              precision={0.5}
                              value={review?.rating}
                              readOnly
                            />
                            <p className="text-lg font-normal text-[#060709]">
                              {review?.firstName} on{" "}
                              {moment(review?.createdAt)?.format("LL")}
                            </p>
                            <h3 className="text-[#060709] w-full text-wrap font-semibold text-lg">
                              {review?.title}
                            </h3>
                            <p
                              className="text-[#858585] w-full break font-normal text-lg"
                              style={{ wordWrap: "break-word" }}
                            >
                              {review?.body}
                            </p>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            )}
          </div>
        </div>

        <CustomModal
          title="Size Chart"
          caption=""
          open={sizeModal}
          onClose={() => setSizeModal(false)}
        >
          <SizeChart image={pageInfo?.productDetails?.sizeChart} />
        </CustomModal>
        <CustomModal
          title="Add Your Review"
          caption="Here you can add your reviews"
          open={reviewModal}
          onClose={() => setReviewModal(false)}
        >
          <WriteReview
            product={state?.productId}
            onClose={() => setReviewModal(false)}
            setReviews={setReviews}
          />
        </CustomModal>

        <TopSellers products={pageInfo?.topSeller} />
      </div>
    </>
  );
};

export default ProductDetails;

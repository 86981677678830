import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUploading: false,
  progress: 0,
};

const musicUploadSlice = createSlice({
  name: "musicUpload",
  initialState,
  reducers: {
    startUploading(state, action) {
      state.isUploading = true;

      return state;
    },
    updateProgress(state, action) {
      state.progress = action.payload;

      return state;
    },
    stopUploading(state, action) {
      state.isUploading = false;
      state.progress = 0;

      return state;
    },
  },
});

export const musicUploadActions = musicUploadSlice.actions;

export default musicUploadSlice.reducer;

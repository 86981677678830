const Joi = require("joi");

export const channelSchema = Joi.object({
	title: Joi.string()
		.pattern(/^(?=.*\S)[a-zA-Z0-9\s\S]+$/)
		.max(30)
		.required()
		.messages({
			"string.empty": "Please enter a channel name",
			"string.pattern.base":
				"Invalid name. The name must contain at least one non-space character.",
			"string.max": "Channel name must be less than 30 characters long",
		}),
});

import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white px-[12.25%] py-[120px] text-center flex flex-col gap-8">
      <h1 className="font-bold text-[32px]">Privacy Policy</h1>
      <div className="flex flex-col">
        <p className="font-normal text-[18px]">
          Aeternal Lovers offers to ship orders with help of the returnable and
          reusable packaging service RePack (a brand of the Finnish company Plan
          B from Outer Space Ltd.). This service includes the delivery of the
          order in a reusable packaging (the Packaging) and the delivery of a
          reward for returning the Packaging to RePack. To deliver the reward,
          Aeternal Lovers shares the customer’s email address with RePack.
          RePack provides a personalized web link directing at an online
          marketplace where the customer can select a personal shopping voucher
          or similar offering. RePack provides the delivery of the reward web
          link and of the shopping voucher as a processor on behalf of Aeternal
          Lovers. Legal basis for the reward communication is fulfillment of a
          contract as the reward is part of the services by Aeternal Lovers.
          RePack is obliged on data protection by a data processing agreement in
          accordance with EU General Data Protection Regulation (GDPR).
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

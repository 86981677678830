import React from "react";
import classes from "./GradientButton.module.css";
import { Avatar, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
const GradientButton = ({
  avatar,
  alt,
  title,
  type,
  icon,
  disable,
  iconFirst,
  profilePicture,
  style,
  className,
  onClick,
  loading,
  naming
}) => {
  const {userData} = useSelector(state => state?.user)
  return (
    <button
      type={type}
      className={`${disable? "cursor-not-allowed" : ""} ${classes.button} ${className}`}
      style={style}
      onClick={onClick}
      disabled={loading || disable}
    >
      {alt && (
        <Avatar
          src={userData?.profilePicture}
          sx={{ height: "28px", width: "28px", fontSize: "14px" }}
        >
          {alt}
        </Avatar>
      )}
      {loading ? (
        <CircularProgress color="inherit" size={30} />
      ) : iconFirst ? (
        <>
          {icon}
          {title}
        </>
      ) : (
        <>
          {title}
          {icon}
        </>
      )}
    </button>
  );
};
export default GradientButton;

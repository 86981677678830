import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("AL_USER")) || {};

  if (!userData?.accessToken)
    return <Navigate to="/login" state={{ from: window.location.pathname }} />;
  else return children;
};

export default PrivateRoute;

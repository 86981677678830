import axios from "axios";
import { API } from "./apiConstants";

import { API_URL } from "./config";
import _ from "lodash";
import store from "../../redux/store";
import { loaderActions } from "../../redux/reducers/loader";
import { userActions } from "../../redux/reducers/user";
const fetchClient = () => {
  let instance = axios.create();

  instance.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem("AL_USER"));
    if (user?.hasOwnProperty("accessToken")) {
      config.headers.authorization = user["accessToken"];
    }
    store.dispatch(loaderActions.toggleLoading(true));
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      store.dispatch(loaderActions.toggleLoading(false));
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        store.dispatch(userActions.removeUser())
        localStorage.removeItem("AL_USER")
      }
      store.dispatch(loaderActions.toggleLoading(false));
      return Promise.reject(JSON.stringify(error?.response?.data?.message));
    }
  );

  return instance;
};
export default fetchClient();

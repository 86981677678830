import { React, useState } from "react";
import GradientButton from "../components/GradientButton/GradientButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../api/api_calls/auth";
import { toast } from "react-toastify";
import { resetPasswordSchema } from "../validations/auth";
import CustomInput from "../components/CustomInput/CustomInput";
import { handleApiError } from "../utils/helperFunctions";
import { useSelector } from "react-redux";

const Forgot = () => {
  const { loading } = useSelector((state) => state.loader);

  const [newPass, setNew] = useState("");
  const [confirm, setConfirm] = useState("");
  const [formError, setFormError] = useState({});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      newPassword: newPass,
      otp: searchParams.get("otp"),
      id: searchParams.get("id"),
    };

    const { error } = resetPasswordSchema.validate(formData);

    if (error) {
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    } else setFormError({});

    if (newPass !== confirm) {
      setFormError({
        newPassword: "Passwords do not match!",
        confirmPassword: "Passwords do not match!",
      });
      return;
    } else setFormError({});

    resetPassword(formData)
      .then((res) => {
        toast.success(res?.data?.message);
        navigate("/login");
      })
      .catch((error) => {
        handleApiError(error, setFormError);
      });
  };

  return (
    <div className="w-full h-full bg-[#FFF1F4] flex items-center justify-center">
      <div className="flex flex-col md:flex-row mx-[15%] py-[5%]">
        <form
          className="w-1/2 md:w-full px-12 py-16 flex-col bg-[#FFFFFF]"
          onSubmit={handleSubmit}
        >
          <div className="font-bold text-3xl mb-1">Forgot Password?</div>
          <div className="font-normal text-base mb-5 pr-10">
            Enter your new password and confirm it below.
          </div>
          <div className="font-medium text-lg mb-2">New Password</div>
          {/* <input
            className="w-full text-base font-normal rounded-lg py-4 px-2 mb-5 border border-zinc-200"
            type="password"
            placeholder="Enter New Password"
            value={newPass}
            onChange={(e) => setNew(e.target.value)}
          /> */}
          <CustomInput
            type="password"
            placeholder="Enter New Password"
            value={newPass}
            onChange={(e) => setNew(e.target.value)}
            error={formError?.newPassword}
          />
          <div className="font-medium text-lg mt-5 mb-2">Confirm Password</div>
          {/* <input
            className="w-full text-base font-normal rounded-lg py-4 px-2 mb-5 border border-zinc-200"
            type="password"
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          /> */}
          <CustomInput
            type="password"
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            error={formError?.confirmPassword}
          />
          <GradientButton
            className="mt-5"
            type="submit"
            style={{ width: "100%", justifyContent: "center" }}
            title={"Submit"}
            loading={loading}
          />
        </form>
      </div>
    </div>
  );
};

export default Forgot;

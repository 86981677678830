import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Scheduler } from "devextreme-react";
import "./Calendar.css";
import GradientButton from "../../../components/GradientButton/GradientButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import AddBookingEvent from "../../../components/ModalComponents/AddBookingEvent/AddBookingEvent";
import { getAllEvents } from "../../../api/api_calls/calendar";
import { Resource } from "devextreme-react/cjs/scheduler";
import { useLocation } from "react-router-dom";
import EventDetails from "../../../components/ModalComponents/EventDetails/EventDetails";
import { useSelector } from "react-redux";
import resolution from "../../../redux/reducers/resolution";

// import { employees, data } from './data.js';
// import DataCell from './DataCell.js';
// import ResourceCell from './ResourceCell.js';

const resourceData = [
	{
		id: 1,
		color: "#E6355C",
	},
];

const currentDate = new Date();
const views = ["month", "week"];

const editingState = {
	allowAdding: false,
	allowDeleting: false,
	allowUpdating: false,
	allowResizing: false,
};

const Calendar = () => {
	const { state } = useLocation();
	const { resolutionType } = useSelector((state) => state.resolution);
	const { userData } = useSelector((state) => state?.user);

	const [addEvent, setAddEvent] = useState(state?.modalOpen || false);
	const [events, setEvents] = useState([]);
	const [eventDetailsModal, setEventDetailsModal] = useState(false);
	const [eventDetails, setEventDetails] = useState(null);

	const transformData = (dataset) => {
		const transformedData = dataset.map((data) => {
			return {
				...data,
				text: `${data?.eventName} (${data?.eventArtist?.firstName} ${data?.eventArtist?.lastName})`,
				startDate: new Date(data?.eventStartDate),
				endDate: new Date(data?.eventEndDate),
				colorId: 1,
			};
		});

		setEvents([...transformedData]);
	};

	useEffect(() => {
		getAllEvents()
			.then((res) => transformData(res?.data?.data))
			.catch((error) => console.log(error));
	}, []);

	const Appointment = ({ data }) => {
		return (
			<div
				style={{
					borderRadius: "8px",
					display: "flex",
					alignItems: "center",
					height: "100%",
				}}
			>
				<h3
					className="font-bold text-[14px] text-wrap m-auto text-white"
					style={{ fontFamily: "Poppins" }}
				>
					{data?.appointmentData?.eventName}
				</h3>
			</div>
		);
	};

	return (
		<div className="flex flex-col bg-[#F9F9F9] p-6 h-full gap-7 overflow-auto">
			<div
				className={`flex items-center justify-between ${
					resolutionType.type === "mobile" ? "flex-col gap-y-4" : ""
				}`}
			>
				<div className="flex flex-col gap-2">
					<h3 className="font-semibold text-[20px]">
						Good Information Calendar
					</h3>
					<p className="text-base font-normal">
						Check out these opportunities to create more, connect more, and love
						more here in the Bronx.
						<br /> We hope to see you there!
					</p>
				</div>
				{userData?._id && (
					<GradientButton title="Add Event" onClick={() => setAddEvent(true)} />
				)}
			</div>
			<Scheduler
				dataSource={events}
				editing={editingState}
				appointmentComponent={Appointment}
				views={views}
				defaultCurrentView="month"
				defaultCurrentDate={currentDate}
				height={650}
				showAllDayPanel={false}
				onAppointmentFormOpening={(e) => {
					e.cancel = true;
					// console.log(e);
					if (e?.appointmentData?._id) {
						setEventDetailsModal(true);
						setEventDetails({ ...e?.appointmentData });
					} else setAddEvent(true);
				}}
				adaptivityEnabled={resolutionType.type === "mobile"}
			>
				<Resource
					dataSource={resourceData}
					fieldExpr="colorId"
					useColorAsDefault={true}
				/>
			</Scheduler>
			<CustomModal
				title="Book a Band"
				caption="Here you can Book a band for event."
				open={addEvent}
				onClose={() => setAddEvent(false)}
			>
				<AddBookingEvent />
			</CustomModal>
			<CustomModal
				title="Event Detail"
				caption="Here you can view event details"
				open={eventDetailsModal}
				onClose={() => setEventDetailsModal(false)}
			>
				<EventDetails eventDetails={eventDetails} />
			</CustomModal>
		</div>
	);
};

export default Calendar;

import React from "react";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import EastIcon from "@mui/icons-material/East";
import designGirl from "../../../../assets/design_style_girl.png";
import companyLogo from "../../../../assets/company_logo.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// border-bottom: 1px solid #E4E4E4

const UniqueDesignBanner = () => {
	const navigate = useNavigate();
	const { resolutionType } = useSelector((state) => state.resolution);
	return (
		<div
			className={`${
				resolutionType.type === "mobile" ? "" : "py-[64px] px-[12.25%]"
			}`}
		>
			<div
				className={`flex w-full items-center bg-[#FFF1F4]  rounded-[12px] ${
					resolutionType.type === "mobile"
						? "py-16 px-4"
						: "gap-[11%] px-[56px] overflow-hidden h-[284px]"
				}`}
			>
				<div className="flex flex-col">
					<h2 className="text-[30px] font-semibold text-black">
						Have an <span className="text-[#E6355C]">Idea</span> for a
						one-of-a-kind look?
					</h2>
					<p className="text-xl text-black font-normal mb-[24px]">
						Let's create a custom piece together using items already found in
						your wardrobe.
					</p>
					<GradientButton
						title="Select your Design"
						icon={<EastIcon />}
						onClick={() => navigate("/customDesign")}
					/>
				</div>
				{resolutionType.type === "mobile" ? null : (
					<>
						<div className="relative">
							<img
								src={companyLogo}
								className="absolute z-20 w-[40px] top-[60%] left-[42%] "
							/>
							<img
								src={designGirl}
								style={{ maxWidth: "fit-content" }}
								className="sticky z-10"
							/>
							<div className="bg-[#faccd5] rounded-[100%] h-[258px] w-[258px] left-[14%] top-[15%] absolute"></div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default UniqueDesignBanner;

import Joi from "joi";

export const reviewSchema = Joi.object({
	title: Joi.string().max(20).required().label("Review Title"),
	body: Joi.string().max(150).optional().label("Review Body"),
	rating: Joi.number().min(0.5).max(5).required().label("Rating"),
	productId: Joi.string().required(),
});

export const addressSchema = Joi.object({
	fullName: Joi.string().max(20).required().label("Full Name"),
	mobileNumber: Joi.string().required().label("Mobile Number"),
	address: Joi.string().max(100).required().label("Address"),
	country: Joi.string().required().label("Country"),
	city: Joi.string()
		.required()
		.messages({ "string.empty": "Please enter valid zipcode" }),
	state: Joi.string()
		.required()
		.messages({ "string.empty": "Please enter valid zipcode" }),
	zipCode: Joi.number().required().label("Zipcode"),
	isDefault: Joi.boolean().required(),
	addressId: Joi.string().optional(),
});

export const eventSchema = Joi.object({
	eventName: Joi.string().max(30).required().label("Event Name"),
	eventAddress: Joi.string().max(200).required().label("Event Address"),
	eventDetails: Joi.string().max(600).required().label("Event Details"),
	eventStartDate: Joi.string().required().label("Event Start Date"),
	eventEndDate: Joi.string().required().label("Event End Date"),
});

export const nameSchema = Joi.object({
	firstName: Joi.string().max(30).required().label("First Name"),
	lastName: Joi.string().max(30).required().label("Last Name"),
});

export const changePasswordSchema = Joi.object({
	currPassword: Joi.string()
		.min(8)
		.regex(/[A-Z]/, "upper-case")
		.regex(/[a-z]/, "lower-case")
		.regex(/[^\w]/, "special character")
		.regex(/[0-9]/, "number")
		.label("Current Password")
		.messages({
			"string.empty": "Current Password cannot be empty",
			"string.min":
				"Current Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
			"string.pattern.name":
				"Current Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
		}),
	newPassword: Joi.string()
		.min(8)
		.regex(/[A-Z]/, "upper-case")
		.regex(/[a-z]/, "lower-case")
		.regex(/[^\w]/, "special character")
		.regex(/[0-9]/, "number")
		.label("New Password")
		.messages({
			"string.empty": "New Password cannot be empty",
			"string.min":
				"New Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
			"string.pattern.name":
				"New Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
		}),
});

// const validateUSPhoneNumber = (value, helpers) => {
// 	const usPhoneNumberPattern = /^\+1\d{10}$/;
// 	if (!usPhoneNumberPattern.test(value)) {
// 		return helpers.error("any.invalid");
// 	}
// 	return value; // Return the value if valid
// };

export const contactUsValidation = Joi.object({
	firstName: Joi.string().max(30).required().label("First Name"),
	lastName: Joi.string().max(30).required().label("Last Name"),
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
		.label("Email"),
	phone: Joi.string()
		.pattern(/^\d{11}$/)
		.required()
		.label("Phone Number")
		.messages({
			"string.pattern.base": `"Phone Number" must be a valid 10-digit US phone number.`,
		}),
	message: Joi.string().max(300).required().label("Message"),
});

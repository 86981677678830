import { React, useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import WebsiteNavbar from "../WebsiteNavbar/WebsiteNavbar";
import ShopDropdown from "../Shop Dropdown/ShopDropdown";
import Banner from "../Banner/Banner";
import Footer from "../Footer";
import { get_all_arts_type } from "../../../api/api_calls/shop";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "../../../redux/reducers/cart";
import { useGetCartQuery } from "../../../redux/apiSlice";

const WebsiteHome = () => {
	const dispatch = useDispatch();
	const { userData } = useSelector((state) => state.user);
	const location = useLocation()
	
	useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname]);

	const [showDrop, setShowDrop] = useState(false);
	const [artTypes, setArtTypes] = useState([]);
	const [landingPageProducts, setLandingPageProducts] = useState({
		topSellers: [],
		products: [],
	});
	// const [skip, setSkip] = useState(true);

	// const { data, error, isLoading } = useGetCartQuery(undefined, {
	//   skip,
	// });

	// console.log(userData, data, isLoading);

	// if (userData) setSkip(false);

	// useEffect(() => {}, []);

	// useEffect(() => {
	//   if (userData?._id) setSkip(false);
	//   else setSkip(true);
	// }, [userData]);

	useEffect(() => {
		get_all_arts_type()
			.then((res) => {
				setArtTypes(res?.data?.data?.artsData);
				setLandingPageProducts({
					topSellers: res?.data?.data?.topSeller,
					products: res?.data?.data?.product,
				});
			})
			.catch((err) => console.log(err));
	}, []);

	return (
		<div className="flex flex-col bg-[#060709] overflow-x-hidden">
			<WebsiteNavbar setShowDrop={setShowDrop} arts={artTypes} />
			<AnimatePresence>
				{showDrop && (
					<>
						<div
							className="fixed top-0 left-0 w-screen h-screen bg-transparent z-12"
							onClick={() => setShowDrop((curr) => !curr)}
						/>
						<ShopDropdown
							arts={artTypes}
							closeDropdown={() => setShowDrop(false)}
						/>
					</>
				)}
			</AnimatePresence>
			<Banner />
			<Outlet context={[artTypes, landingPageProducts]} />
			<Footer artTypes={artTypes} />
		</div>
	);
};

export default WebsiteHome;

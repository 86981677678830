import React, { useState } from "react";
import { delay, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ProductsSkeleton } from "../../../../skeletons/skeleton";
import { useSelector } from "react-redux";

// const products = [
//   {
//     id: 1,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 2,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 3,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 4,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
// ];

const boxVariant = {
	hidden: {},
	visible: {
		transition: {
			// when: "beforeChildren", //use this instead of delay
			delay: 0.5,
			staggerChildren: 0.05, //apply stagger on the parent tag
		},
	},
};

const listVariant = {
	hidden: {
		scale: 0.5, //move out of the site
		opacity: 0,
	},
	visible: {
		scale: 1, // bring it back to nrmal
		opacity: 1,
	},
};

const ProductsSection = ({
	products,
	noCTA,
	openDrawer,
	setProduct,
	className,
}) => {
	const navigate = useNavigate();

	const loader = useSelector((state) => state.loader);
	const [productHovered, setProductHovered] = useState("");

	return (
		<motion.ul
			className={`${
				className
					? className
					: "py-[120px] px-[12.25%] flex flex-wrap gap-y-[52px] gap-x-[18px]"
			}`}
			variants={boxVariant}
			animate="visible"
			initial="hidden"
		>
			{loader?.loading ? (
				<ProductsSkeleton />
			) : (
				products?.map((product) => (
					<motion.li
						key={product?._id}
						value={product._id}
						className="flex flex-col gap-[16px] cursor-pointer"
						variants={listVariant}
						onMouseEnter={(e) => setProductHovered(product._id)}
						onMouseLeave={() => setProductHovered("")}
						onClick={() => {
							navigate("/product-details", {
								state: { productId: product?._id },
							});
						}}
					>
						<motion.div
							animate={
								productHovered === product?._id
									? { boxShadow: "0px 4px 20px 0px #4E659F59" }
									: { boxShadow: "none" }
							}
							className="h-[381px] rounded-[8px] w-[345px] overflow-hidden"
						>
							<motion.img
								src={product?.productImages[0]}
								animate={productHovered === product?._id ? { scale: 1.1 } : {}}
								className="h-full w-full object-cover unloaded-img"
							/>
						</motion.div>
						<div className="flex justify-between items-center">
							<div className="flex flex-col gap-[16px]">
								<label
									title={product?.productName}
									className={`${
										productHovered === product?._id ? "underline" : ""
									} text-xl font-medium text-[#060709] elipsis max-w-[210px]`}
								>
									{product?.productName}
								</label>
								<p className="text-xl font-bold text-[#060709]">
									${product?.basePrice}
								</p>
							</div>

							{!noCTA && (
								<button
									className="flex py-[4px] font-medium text-sm px-[8px] rounded-[4px] gap-[4px] items-center bg-[#E6355C] text-white"
									onClick={(e) => {
										e.stopPropagation();
										setProduct(product);
										openDrawer();
									}}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19.9602 8.96002C19.2902 8.22002 18.2802 7.79002 16.8802 7.64002V6.88002C16.8802 5.51002 16.3002 4.19002 15.2802 3.27002C14.2502 2.33002 12.9102 1.89002 11.5202 2.02002C9.13023 2.25002 7.12023 4.56002 7.12023 7.06002V7.64002C5.72023 7.79002 4.71023 8.22002 4.04023 8.96002C3.07023 10.04 3.10023 11.48 3.21023 12.48L3.91023 18.05C4.12023 20 4.91023 22 9.21023 22H14.7902C19.0902 22 19.8802 20 20.0902 18.06L20.7902 12.47C20.9002 11.48 20.9202 10.04 19.9602 8.96002ZM11.6602 3.41002C12.6602 3.32002 13.6102 3.63002 14.3502 4.30002C15.0802 4.96002 15.4902 5.90002 15.4902 6.88002V7.58002H8.51023V7.06002C8.51023 5.28002 9.98023 3.57002 11.6602 3.41002ZM8.42023 13.15H8.41023C7.86023 13.15 7.41023 12.7 7.41023 12.15C7.41023 11.6 7.86023 11.15 8.41023 11.15C8.97023 11.15 9.42023 11.6 9.42023 12.15C9.42023 12.7 8.97023 13.15 8.42023 13.15ZM15.4202 13.15H15.4102C14.8602 13.15 14.4102 12.7 14.4102 12.15C14.4102 11.6 14.8602 11.15 15.4102 11.15C15.9702 11.15 16.4202 11.6 16.4202 12.15C16.4202 12.7 15.9702 13.15 15.4202 13.15Z"
											fill="white"
										/>
									</svg>
									Add to Cart
								</button>
							)}
						</div>
					</motion.li>
				))
			)}

			{/* {loader?.loading ? "skeleton active" : "skeleton inactive"} */}
		</motion.ul>
	);
};

export default ProductsSection;

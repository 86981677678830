import React, { useEffect, useState } from "react";
import TopSellers from "../ProductDetails/TopSellers/TopSellers";
import { useSelector } from "react-redux";
import BagItem from "./BagItem/BagItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { useNavigate } from "react-router-dom";
import {
  checkPromoCode,
  createPaymentIntent,
} from "../../../api/api_calls/shop";
import { stripePromise } from "../Payment/stripePayment";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";

const Cart = () => {
  const cartState = useSelector((state) => state.cart);
  const { resolutionType } = useSelector((state) => state.resolution);
  const { loading } = useSelector((state) => state.loader);
  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(null);
  const [couponError, setCouponError] = useState(false);

  useEffect(() => {
    if (discount && Number(discount?.minimumPrice) > calculateCartPrice()) {
      setDiscount(null);
    }
  }, [cartState]);

  const calculateCartPrice = () => {
    const cartPrice =
      cartState.cart?.cartItems?.[0]?.cartItems?.reduce(
        (accumulator, cartItem) =>
          accumulator + cartItem?.qty * cartItem?.price,
        0
      ) || 0;

    return cartPrice?.toFixed(2);
  };

  const finalAmount = () => {
    let amount = Number(calculateCartPrice());

    if (amount < 200) amount += 20;
    if (discount) amount -= discount?.discount;

    return amount?.toFixed(2);
  };

  const handleCouponCheck = () => {
    // const coupon = stripePromise.coupons.retrieve(couponCode);
    if (!couponCode) {
      setCouponError("Please enter a code first!");
      return;
    }
    checkPromoCode(couponCode)
      .then((res) => {
        if (Number(res?.data?.data?.minimumPrice) <= calculateCartPrice()) {
          setDiscount({ ...res?.data?.data });
          setCouponCode("");
          setCouponError("");
        } else {
          toast.error("Promo code is not applicable!");
        }
      })
      .catch((err) => {
        setCouponError(err?.message);
        setDiscount(null);
      });
  };

  const handleCheckout = () => {
    const orderItems = [];
    cartState.cart?.cartItems?.[0]?.cartItems?.forEach((cartItem) =>
      orderItems.push({
        productId: cartItem?.productId,
        size: cartItem?.size,
        qty: cartItem?.qty,
        count: cartItem?.qty,
        productImage:
          typeof cartItem?.productImages === "string"
            ? cartItem?.productImages
            : "",
      })
    );

    const formData = {
      orderItems,
      orderAmountTax: 10,
      shippingCharges: calculateCartPrice() < 200 ? 20 : 0,
      promoCode: discount?.promoCode || "",
      discount: discount?.discount || null,
    };

    createPaymentIntent(formData)
      .then((res) => {
        formData.orderAmount = calculateCartPrice();

        // if (formData.discount) formData.orderAmount = formData.orderAmount -
        navigate("/payment", {
          state: { paymentIntent: res?.data?.data, orderData: formData },
        });
      })
      .catch((error) => {
        // setCouponError(error?.message);
        setDiscount(null);
      });
  };

  return (
    <>
      <BreadCrumb />
      <div
        className={`bg-white flex flex-col ${
          resolutionType.type === "mobile" ? " py-16 px-8 mb-8" : "px-[12.25%]"
        }`}
      >
        <div
          className={` flex  ${
            resolutionType.type === "mobile"
              ? "flex-col mb-8"
              : "py-[64px] gap-[48px]"
          }`}
        >
          <div
            className={`flex flex-col gap-[24px] max-h-[40rem] overflow-auto hidden-scroll ${
              resolutionType.type === "mobile" ? "w-full" : "w-[60%] "
            }`}
          >
            <div className="pb-[24px] font-bold text-black text-2xl border-b-[1px] border-[#E4E4E4]">
              Your Shopping Bag (
              {cartState?.cart?.cartItems?.[0]?.cartItems?.length || 0} items)
            </div>
            <p className="font-semibold text-lg text-black">In your Bag</p>
            {cartState.cart?.cartItems?.[0]?.cartItems?.map((item) => (
              <BagItem key={item._id} product={item} />
            ))}
          </div>
          {cartState.cart?.cartItems?.[0]?.cartItems?.length && (
            <div
              className={`flex gap-6 flex-col ${
                resolutionType.type === "mobile" ? "w-full" : "w-[40%] "
              }`}
            >
              <div className="pb-[24px] font-bold text-black text-2xl border-b-[1px] border-[#E4E4E4]">
                Summary
              </div>
              <div className="flex gap-6">
                <CustomInput
                  placeholder="Have a Promo Code?"
                  value={couponCode}
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                    setCouponError("");
                  }}
                />
                <button className="rounded-button" onClick={handleCouponCheck}>
                  <span
                    style={{
                      background:
                        "linear-gradient(90deg, #E93359 0%, #6167BA 100%)",
                      "-webkit-text-fill-color": "transparent",
                      "-webkit-background-clip": "text",
                    }}
                  >
                    Submit
                  </span>
                </button>
              </div>
              {couponError && (
                <div className="flex gap-2 items-center text-[#E6355C] bg-[#FFF1F4] py-1 text-[18px] px-4 rounded-lg">
                  <InfoIcon fontSize="inherit" color="inherit" />
                  <h3 className="font-medium text-sm">{couponError}</h3>
                </div>
              )}
              <div className="flex items-center justify-between">
                <h2 className="text-black font-semibold text-lg">Subtotal</h2>
                <p className="font-semibold text-base text-[#858585]">
                  ${calculateCartPrice()}
                </p>
              </div>
              {discount && (
                <div className="flex items-center justify-between">
                  <h2 className="text-black font-semibold text-lg">Discount</h2>
                  <p className="font-semibold text-base text-[#858585]">
                    -${discount?.discount}
                  </p>
                </div>
              )}
              {cartState.cart?.cartItems?.[0]?.cartItems?.length && (
                <div className="flex items-center justify-between">
                  <h2 className="text-black font-semibold text-lg">Shipping</h2>
                  <p className="font-semibold text-base text-[#858585]">
                    {calculateCartPrice() < 200 ? "$20" : "$0"}
                  </p>
                </div>
              )}
              {cartState.cart?.cartItems?.[0]?.cartItems?.length && (
                <div className="flex items-center justify-between">
                  <h2 className="text-black font-semibold text-lg">
                    Final Amount
                  </h2>
                  <p className="font-semibold text-base text-[#858585]">
                    ${finalAmount()}
                  </p>
                </div>
              )}
              {/* <div className="flex items-center justify-between">
							<h2 className="text-black font-semibold text-lg">Taxes</h2>
							<p className="font-semibold text-base text-[#858585]">TBD</p>
						</div> */}
              {/* <div className="bg-[#E4E4E4] p-1 text-center text-black font-semibold text-base">
              Taxes and shipping calculated at checkout
            </div> */}
              <GradientButton
                loading={loading}
                title="Proceed to Checkout"
                style={{ width: "100%", padding: "16px 24px" }}
                onClick={handleCheckout}
              />
            </div>
          )}
        </div>
        <TopSellers products={cartState?.cart?.topSeller} />
      </div>
    </>
  );
};

export default Cart;

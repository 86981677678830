import { API } from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const addAddress = (formData) => {
  return wrappedFetch(API.ADD_ADDRESS, "POST", formData);
};

export const deleteAddress = (formData) => {
  return wrappedFetch(API.DELETE_ADDRESS, "POST", formData);
};

export const editAddress = (formData) => {
  return wrappedFetch(API.EDIT_ADDRESS, "POST", formData);
};

export const editName = (formData) => {
  return wrappedFetch(API.CHANGE_NAME, "POST", formData);
};

export const changePassword = (formData) => {
  return wrappedFetch(API.CHANGE_PASSWORD, "POST", formData);
};

export const changeProfilePicture = (formData) => {
  return wrappedFetch(API.CHANGE_PROFILE_PICTURE, "POST", formData);
};

export const getAllOrders = (page, filter, pageSize) => {
  return wrappedGet(
    `${API.GET_ALL_ORDERS}?page=${page}&filter=${filter}&pageSize=${pageSize}`
  );
};

import { React, useState, useEffect, useContext, useCallback } from "react";
import looper from "../../../../assets/ArtistLooperGroup.png";
import { Skeleton } from "@mui/material";
import TableMusic from "../TableMusic/TableMusic";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { musicByArtist } from "../../../../api/api_calls/music";
import { PlayerContext } from "../Sound";
import { Avatar } from "@mui/material";

const MusicArtist = () => {
	const navigate = useNavigate();
	const { open } = useContext(PlayerContext);
	const location = useLocation();
	const [load, setLoad] = useState(false);
	const [details, setDetails] = useState(null);
	const [list, setList] = useState(null);
	const [currPage, setCurrPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [moreLoad, setMoreLoad] = useState(false);
	const [debounce, setDebounce] = useState(false);

	const { id } = useParams();

	const fetchMore = () => {
		if (currPage + 1 > totalPages || debounce) {
			return;
		}

		setDebounce(true);

		const newPage = currPage + 1;

		musicByArtist({ artist: id, page: newPage, limit: 10 })
			.then((res) => {
				setCurrPage(res?.data?.currentPage);
				setTotalCount(res?.data?.totalCount);
				setTotalPages(res?.data?.totalPages);

				if (res?.data?.currentPage === res?.data?.totalPages)
					setMoreLoad(false);

				const fN =
					res?.data.length > 0
						? res?.data?.data[0]?.artist?.firstName
						: location.state.firstName;
				const lN =
					res?.data.length > 0
						? res?.data?.data[0]?.artist?.lastName
						: location.state.lastName;

				const tempArr = res?.data?.data[0]?.music?.map((item, index) => {
					let tempObj = {
						id: item?._id,
						title: item?.title,
						artist: `${fN} ${lN}`,
						isYoutube: item?.isYoutube,
						duration: item?.playTime,
					};

					if (item?.isYoutube) {
						tempObj["youtube"] = {
							url: item?.youtubeUrl,
							thumbnail: item?.thumbnailImages?.youtubeThumbnail,
						};
					} else {
						tempObj["audio"] = {
							src: item?.musicUrl,
							thumbnail: item?.thumbnailImages?.musicThumbnail,
						};
						if ("videoUrl" in item) {
							tempObj["video"] = {
								src: item?.videoUrl,
								thumbnail: item?.thumbnailImages?.videoThumbnail,
							};
						}
					}
					return tempObj;
				});

				setList([...list, ...tempArr]);
			})
			.finally(() => setDebounce(false));
	};

	useEffect(() => {
		setLoad(true);
		fetchDetails(id)
			.then((res) => {
				// console.log("LOCATION: ", res?.data?.data, location.state);

				if (res?.data?.currentPage < res?.data?.totalPages) setMoreLoad(true);

				if (res?.data?.data?.length === 0 && !location.state)
					navigate("/app/sound");
				else {
					const fN =
						res?.data.length > 0
							? res?.data?.data[0]?.artist?.firstName
							: location.state.firstName;
					const lN =
						res?.data.length > 0
							? res?.data?.data[0]?.artist?.lastName
							: location.state.lastName;

					setDetails({
						firstName: fN,
						lastName: lN,
						pfp:
							res?.data.length > 0
								? res?.data?.data[0]?.artist?.profilePictures[0]
								: location.state.pfp,
					});

					const tempArr = res?.data?.data[0]?.music?.map((item, index) => {
						let tempObj = {
							id: item?._id,
							title: item?.title,
							artist: `${fN} ${lN}`,
							isYoutube: item?.isYoutube,
							duration: item?.playTime,
						};

						if (item?.isYoutube) {
							tempObj["youtube"] = {
								url: item?.youtubeUrl,
								thumbnail: item?.thumbnailImages?.youtubeThumbnail,
							};
						} else {
							tempObj["audio"] = {
								src: item?.musicUrl,
								thumbnail: item?.thumbnailImages?.musicThumbnail,
							};
							if ("videoUrl" in item) {
								tempObj["video"] = {
									src: item?.videoUrl,
									thumbnail: item?.thumbnailImages?.videoThumbnail,
								};
							}
						}
						return tempObj;
					});

					setList(tempArr);
					setCurrPage(res?.data?.currentPage);
					setTotalCount(res?.data?.totalCount);
					setTotalPages(res?.data?.totalPages);
				}
			})
			// .catch(() => {
			// 	console.log("LOCATION: ", location.state);
			// 	setDetails({
			// 		firstName: location.state.firstName,
			// 		lastName: location.state.lastName,
			// 		pfp: location.state.pfp,
			// 	});
			// })
			.finally(() => {
				setLoad(false);
			});
	}, []);

	const fetchDetails = async (id) => {
		return musicByArtist({ artist: id })
			.then((res) => {
				// console.log("RES: ", res);
				return res;
			})
			.catch((err) => console.error(err));
	};

	// const fetchMore = useCallback(() => {
	// 	if (currPage < totalPages) {
	// 		console.log("NEED TO FETCH", currPage, totalPages);
	// 	} else {
	// 		console.log("NO NEED TO FETCH", currPage, totalPages);
	// 	}
	// }, [currPage, totalPages]);

	return (
		<div
			className="grow flex flex-col"
			style={{ height: [open ? "calc(100% - 4.75rem)" : "100%"] }}
		>
			<div
				className="h-auto w-full p-4"
				style={{
					background: `url(${looper}) 100% 0%/40rem no-repeat, #E6355C`,
				}}
			>
				<div className="flex h-[9.875rem]">
					{load ? (
						<Skeleton
							variant="circular"
							sx={{
								bgcolor: "#CF254B",
								height: "100%",
								aspectRatio: "1",
								marginRight: "2rem",
							}}
						/>
					) : (
						<div
							style={{
								height: "100%",
								aspectRatio: "1",
								borderRadius: "50%",
								backgroundColor: "#bdbdbd",
								marginRight: "2rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Avatar
								className="w-full"
								src={details?.pfp}
								alt={`${details?.firstName[0]} ${details?.lastName[0]}`}
								sx={{ fontSize: "16px", width: "100%", height: "100%" }}
							>
								{details?.firstName[0]}
								{details?.lastName[0]}
							</Avatar>
							{/* {details?.pfp ? (
								<img
									style={{ width: "100%", height: "100%", objectFit: "cover" }}
									src={details?.pfp}
								/>
							) : (
								<>{`${details?.firstName[0]} ${details?.lastName[0]}`}</>
							)} */}
						</div>
					)}
					{/* {details ? (
						<img />
					) : (
						<Skeleton
							variant="circular"
							sx={{
								bgcolor: "#CF254B",
								height: "100%",
								aspectRatio: "1",
								marginRight: "2rem",
							}}
						/>
					)} */}
					<div className="flex flex-col gap-y-[0.5rem] justify-center">
						{load ? (
							<>
								<Skeleton
									variant="rounded"
									sx={{ bgcolor: "#CF254B", width: "15rem", height: "1.75rem" }}
								/>
							</>
						) : (
							<>
								<span className="font-bold text-2xl text-white">{`${details?.firstName} ${details?.lastName}`}</span>
							</>
						)}
						{/* {details ? (
							<>
								<div></div>
								<div></div>
							</>
						) : (
							<>
								<Skeleton
									variant="rounded"
									sx={{ bgcolor: "#CF254B", width: "10rem", height: "1.75rem" }}
								/>
								<Skeleton
									variant="rounded"
									sx={{
										bgcolor: "#CF254B",
										width: "4.5rem",
										height: "1.75rem",
									}}
								/>
							</>
						)} */}
					</div>
				</div>
			</div>
			<div className="h-0 grow p-4 flex flex-col">
				<span className="block text-xl font-bold">Latest Music</span>
				<div className="w-full h-0 grow my-2 flex">
					<TableMusic
						{...{
							labels: ["#", "Title", "Play Time", "Artist/Creator", "Action"],
							content: list,
							playQuery: id,
							playType: "artist",
							currPage,
							limit: 15,
							fetchMore,
							totalPages,
							moreLoad,
							load,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default MusicArtist;

import React from "react";
import classes from "./BookBandCard.module.css";
import companyLogo from "../../../../assets/company_logo.png";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BookBandCard = () => {
	const navigate = useNavigate();
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<div
			className={classes.card}
			style={{
				...(resolutionType.type === "mobile"
					? {
							flexShrink: "0",
							width: "calc(100vw - 2rem)",
							height: "400px",
					  }
					: {
							minHeight: "268px",
							height: "50%",
					  }),
			}}
		>
			<img width="26px" src={companyLogo} className="sticky" />
			<div className="flex flex-col gap-[16px]">
				<h3 className="text-white font-bold text-3xl">
					Elevate your event with Aeternal Lovers
				</h3>

				<GradientButton
					title="Book a Band"
					style={{ background: "white", color: "#E6355C" }}
					onClick={() =>
						navigate("/app/calendar", { state: { modalOpen: true } })
					}
				/>
			</div>
		</div>
	);
};

export default BookBandCard;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API, stagingUrl } from "../api/api_setting/apiConstants";

export const getTokenFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("AL_USER"))?.accessToken;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: stagingUrl,
    prepareHeaders: (header, { endpoint }) => {
      header.set("Accept", "application/json");
      header.set("platform", "web");
      header.set("Access-Control-Allow-Origin", "*");
      // if (endpoint === "userLogin") {
      //   header.set("content-Type", "application/x-www-form-urlencoded");
      // }
      // if (endpoint === "getDataSetSourceFile") {
      //   header.set("content-Type", "application/octet-stream");
      // }
      try {
        const authtoken = JSON.parse(
          localStorage.getItem("AL_USER")
        )?.accessToken;
        if (authtoken) {
          header.set("Authorization", authtoken);
          //   header.set("token", authtoken);
        }
      } catch (error) {
        console.log("error : ", error);
      }
      return header;
    },
  }),
  endpoints: (builder) => ({
    getCart: builder.query({
      query: () => API.GET_CART_ITEMS,
    }),
  }),
});

export const { useGetCartQuery } = apiSlice;

import { Rating } from "@mui/material";
import React, { useState } from "react";
import { writeReview } from "../../../api/api_calls/shop";
import CustomInput from "../../CustomInput/CustomInput";
import GradientButton from "../../GradientButton/GradientButton";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { reviewSchema } from "../../../validations/modal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const WriteReview = ({ product, onClose, setReviews }) => {
  const { loading } = useSelector((state) => state?.loader);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [rating, setRating] = useState(0);
  const [formError, setFormError] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      title,
      body,
      rating,
      productId: product,
    };

    const { error } = reviewSchema.validate(formData);

    if (error) {
      setFormError({ [error?.details[0]?.context?.key]: error?.message });
      return;
    }

    formData.createdAt = new Date().toISOString();

    writeReview(formData)
      .then((res) => {
        toast.success(res?.data?.message);
        setReviews({
          totalNumberOfReviews: res?.data?.data?.totalNumberOfReviews,
          reviews: res?.data?.data?.reviews,
          averageRating: res?.data?.data?.averageRating,
        });
        onClose();
      })
      .catch((err) => toast.error(err?.message));
  };

  return (
    <form
      className="bg-white py-[24px] px-[16px] flex flex-col gap-[16px]"
      onSubmit={handleSubmit}
    >
      <Rating
        name="half-rating"
        size="large"
        value={rating}
        icon={<StarRoundedIcon fontSize="inherit" />}
        emptyIcon={<StarOutlineRoundedIcon fontSize="inherit" />}
        precision={0.5}
        onChange={(e) => setRating(e.target.value)}
      />
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">
          Add Review Title
        </label>
        <CustomInput
          placeholder="Enter Review title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={formError?.title}
        />
      </div>
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">Add Review</label>
        {/* <CustomInput
          placeholder="Enter Review"
          value={body}
          onChange={(e) => setBody(e.target.value)}
        /> */}
        <textarea
          placeholder="Enter Review"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          className={`w-full py-[16px] px-[8px] rounded-[8px] border-[1px] h-[150px] resize-none ${
            formError?.body ? "border-red-400" : "border-[#E4E4E4]"
          } outline-none`}
        />
        {formError?.body && (
          <p className="text-red-400 font-normal text-sm">{formError?.body}</p>
        )}
      </div>
      <GradientButton type="submit" title="Add" loading={loading} />
    </form>
  );
};

export default WriteReview;

import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import ChangeName from "../../../../components/ModalComponents/ChangeName/ChangeName";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import ChangePassword from "../../../../components/ModalComponents/ChangePassword/ChangePassword";
import ChangePicture from "../../../../components/ModalComponents/ChangePicture/ChangePicture";
import { changeProfilePicture } from "../../../../api/api_calls/user";
import { userActions } from "../../../../redux/reducers/user";
import { db, usersRef } from "../../../../firebase/firestore_config"; //useful for setDoc
import { doc, updateDoc } from "firebase/firestore";

const Profile = () => {
  const { userData } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state?.loader);
  const dispatch = useDispatch();

  const [changeNameModal, setChangeNameModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    userData?.profilePicture || ""
  );
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePictureModal, setChangePictureModal] = useState(false);
  const [pictureFile, setPictureFile] = useState(null);

  useEffect(() => {
    setProfilePicture(userData?.profilePicture);
  }, [userData]);

  const handleImageChange = (e) => {
    setPictureFile(e.target.files?.["0"]);
    setProfilePicture(URL.createObjectURL(e.target.files?.["0"]));

    e.target.value = "";
  };

  // ---------------to be uncommented--------------------
  const handleProfilePictureChange = () => {
    const formData = new FormData();

    formData.append("profileImage", pictureFile);

    changeProfilePicture(formData)
      .then((res) => {
        setPictureFile(null);
        dispatch(userActions.storeUser(res?.data?.data));
        const userDocRef = doc(usersRef, userData?._id);
        return updateDoc(userDocRef, {
          pfp: res?.data?.data?.profilePicture,
        });
      })
      .then(() => {
        
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-6 border-[1px] border-[#E4E4E4] w-fit rounded-lg">
        <div className="relative">
          <Avatar src={profilePicture} sx={{ width: 86, height: 86 }} />
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute bottom-[-10px] left-[30px] cursor-pointer"
            onClick={() =>
              document.getElementById("change-profile-picture-input").click()
            }
          >
            <circle cx="13.5" cy="13.5" r="13.5" fill="#E6355C" />
            <path
              d="M19.125 19.75H7.875C7.61875 19.75 7.40625 19.5375 7.40625 19.2812C7.40625 19.025 7.61875 18.8125 7.875 18.8125H19.125C19.3813 18.8125 19.5938 19.025 19.5938 19.2812C19.5938 19.5375 19.3813 19.75 19.125 19.75Z"
              fill="white"
            />
            <path
              d="M17.8876 8.17504C16.6751 6.96254 15.4876 6.93129 14.2439 8.17504L13.4876 8.93129C13.4251 8.99379 13.4001 9.09379 13.4251 9.18129C13.9001 10.8375 15.2251 12.1625 16.8814 12.6375C16.9064 12.6438 16.9314 12.65 16.9564 12.65C17.0251 12.65 17.0876 12.625 17.1376 12.575L17.8876 11.8188C18.5064 11.2063 18.8064 10.6125 18.8064 10.0125C18.8126 9.39379 18.5126 8.79379 17.8876 8.17504Z"
              fill="white"
            />
            <path
              d="M15.7562 13.2062C15.575 13.1187 15.4 13.0312 15.2312 12.9313C15.0937 12.85 14.9625 12.7625 14.8312 12.6687C14.725 12.6 14.6 12.5 14.4812 12.4C14.4687 12.3937 14.425 12.3563 14.375 12.3063C14.1687 12.1312 13.9375 11.9062 13.7312 11.6562C13.7125 11.6437 13.6812 11.6 13.6375 11.5437C13.575 11.4687 13.4687 11.3437 13.375 11.2C13.3 11.1062 13.2125 10.9687 13.1312 10.8312C13.0312 10.6625 12.9437 10.4937 12.8562 10.3187C12.843 10.2904 12.8302 10.2621 12.8177 10.2341C12.7255 10.0258 12.4538 9.96486 12.2927 10.126L8.71246 13.7062C8.63121 13.7875 8.55621 13.9437 8.53746 14.05L8.19996 16.4437C8.13746 16.8687 8.25621 17.2687 8.51871 17.5375C8.74371 17.7562 9.0562 17.875 9.39371 17.875C9.4687 17.875 9.54371 17.8687 9.61871 17.8562L12.0187 17.5188C12.1312 17.5 12.2875 17.425 12.3625 17.3437L15.9383 13.7679C16.1005 13.6057 16.0393 13.3273 15.8283 13.2373C15.8045 13.2271 15.7805 13.2168 15.7562 13.2062Z"
              fill="white"
            />
          </svg>
          <input
            type="file"
            className="hidden"
            id="change-profile-picture-input"
            onChange={handleImageChange}
          />
        </div>
        <div className="flex flex-col ">
          <h3 className="text-lg text-black font-semibold flex items-center gap-2">
            {userData?.firstName} {userData?.lastName}{" "}
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer"
              onClick={() => setChangeNameModal(true)}
            >
              <path
                d="M13.125 13.75H1.875C1.61875 13.75 1.40625 13.5375 1.40625 13.2812C1.40625 13.025 1.61875 12.8125 1.875 12.8125H13.125C13.3813 12.8125 13.5938 13.025 13.5938 13.2812C13.5938 13.5375 13.3813 13.75 13.125 13.75Z"
                fill="#E6355C"
              />
              <path
                d="M11.8876 2.17504C10.6751 0.962537 9.48761 0.931287 8.24386 2.17504L7.48761 2.93129C7.42511 2.99379 7.40011 3.09379 7.42511 3.18129C7.90011 4.83754 9.22511 6.16254 10.8814 6.63754C10.9064 6.64379 10.9314 6.65004 10.9564 6.65004C11.0251 6.65004 11.0876 6.62504 11.1376 6.57504L11.8876 5.81879C12.5064 5.20629 12.8064 4.61254 12.8064 4.01254C12.8126 3.39379 12.5126 2.79379 11.8876 2.17504Z"
                fill="#E6355C"
              />
              <path
                d="M9.7562 7.20625C9.57495 7.11875 9.39996 7.03125 9.2312 6.93125C9.09371 6.85 8.96245 6.7625 8.8312 6.66875C8.72496 6.6 8.59995 6.5 8.4812 6.4C8.4687 6.39375 8.42495 6.35625 8.37495 6.30625C8.1687 6.13125 7.93745 5.90625 7.7312 5.65625C7.71245 5.64375 7.68121 5.6 7.63745 5.54375C7.57495 5.46875 7.46871 5.34375 7.37496 5.2C7.29995 5.10625 7.21245 4.96875 7.1312 4.83125C7.0312 4.6625 6.94371 4.49375 6.8562 4.31875C6.84296 4.29038 6.83015 4.26215 6.81773 4.23408C6.7255 4.02576 6.45385 3.96486 6.29275 4.12596L2.71246 7.70625C2.63121 7.7875 2.55621 7.94375 2.53746 8.05L2.19996 10.4437C2.13746 10.8687 2.25621 11.2687 2.51871 11.5375C2.74371 11.7562 3.0562 11.875 3.39371 11.875C3.4687 11.875 3.54371 11.8687 3.61871 11.8562L6.0187 11.5188C6.13121 11.5 6.28746 11.425 6.36246 11.3437L9.93828 7.76793C10.1005 7.60571 10.0393 7.32729 9.82829 7.23726C9.80454 7.22712 9.78053 7.21679 9.7562 7.20625Z"
                fill="#E6355C"
              />
            </svg>
          </h3>
          <p className="text-base font-normal text-[#858585]">
            {userData?.email}
          </p>
        </div>
        <div className="flex gap-[8px] items-center">
          <ReactCountryFlag
            countryCode={"US"}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            // title={this.state.countryVal ? this.state.countryVal.value : ""}
          />
          <p className="text-base font-normal text-[#858585]">United States</p>
        </div>
        {/* ---------------------to be uncommented------------------ */}
        <CustomModal
          title="Edit Full Name"
          caption="Here you can edit your full name"
          open={changeNameModal}
          onClose={() => setChangeNameModal(false)}
        >
          <ChangeName />
        </CustomModal>
        <CustomModal
          title="Change Password"
          caption="Here you can change your password"
          open={changePasswordModal}
          onClose={() => setChangePasswordModal(false)}
        >
          <ChangePassword />
        </CustomModal>
      </div>
      <div className="flex items-center gap-4">
        <GradientButton
          title="Change Password"
          onClick={() => setChangePasswordModal(true)}
        />
        {pictureFile && (
          <GradientButton
            title="Change Picture"
            onClick={handleProfilePictureChange}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default Profile;

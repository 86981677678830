import React from "react";

const CustomInput = ({
  placeholder,
  type,
  value,
  disabled,
  name,
  onChange,
  error,
  style,
}) => {
  return (
    <>
      <input
        style={style}
        type={type}
        className={`w-full py-[16px] px-[8px] rounded-[8px] border-[1px] ${
          disabled ? "cursor-not-allowed" : ""
        }  ${error ? "border-red-400" : "border-[#E4E4E4]"} outline-none`}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
      />
      {error && (
        <p className="text-red-400 mt-2 font-normal text-sm">{error}</p>
      )}
    </>
  );
};

export default CustomInput;

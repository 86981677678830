import { API } from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const collabReq = (data) => {
	return wrappedFetch(API.COLLAB_REQ, "POST", data);
};

export const initialiseMultipart = (data) => {
	return wrappedFetch(API.INITIALISE_MULTIPART, "POST", data);
};

export const finaliseMultipart = (data) => {
	// Log the FormData contents
	// console.log("FormData contents:");
	// for (let [key, value] of data.entries()) {
	// 	console.log(`${key}: ${value}`);
	// }

	// Call the wrappedFetch function

	return wrappedFetch(API.FINALISE_MULTIPART, "POST", data, {
		"Content-Type": "multipart/form-data",
	});
};

// export const musicByID = (data) => {
// 	return wrappedGet(API.MUSIC_BY_ID, "GET", data);
// };

export const latestMusic = (data) => {
	return wrappedGet(API.LATEST_MUSIC, "GET", data);
};

export const musicByArtist = (data) => {
	return wrappedGet(API.MUSIC_BY_ARTIST, "GET", data);
};

export const musicByAll = (data) => {
	return wrappedGet(API.MUSIC_BY_ALL, "GET", data);
};

export const searchMusic = (data) => {
	return wrappedGet(API.SEARCH_MUSIC, "GET", data);
};
